import React, {useContext, useEffect, useState} from 'react';

import {AppContext} from '../../reducers/AppContext';
import {useParams} from 'react-router';
import {ScheduleService} from '../../services/schedule/schedule.service';
import {ScheduleItem} from '../../interfaces/ISchedule';
import TranslatedString from '../../components/general/TranslatedString';
import MixPage from '../../components/templates/MixPage';
import firebase from "firebase";

const VirtualBoothPage: React.FC = () => {
    const {eventProps} = useContext(AppContext);

    const {boothId, eventId} = useParams<{ [id: string]: string }>();
    const [stream, setStream] = useState<ScheduleItem>(null as any);

    const fetchAndSetStream = async () => {
        const _stream = await ScheduleService.getScheduleItem(eventId, boothId);
        setStream(_stream as any);
    }

    useEffect(() => {
        firebase.analytics().logEvent(`${eventId}_VISIT_BOTH_${boothId}`);
        fetchAndSetStream();
    }, [boothId]);


    if (!eventProps || !stream) {
        return (
            <p>
                <TranslatedString tid="general_loading"/>
            </p>
        )
    }

    return (
        <MixPage pageClass="exhibitors-page" contentClass="booth__wrapper">
            <iframe
                title="Live studio"
                allow="camera;microphone"
                height="100%" width="100%"
                frameBorder="0"
                src={stream.studioLink}
            />
        </MixPage>
    );
};

export default VirtualBoothPage;
