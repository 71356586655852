import { IonInput } from '@ionic/react';
import React from 'react';
import { EventProps } from '../../interfaces/IEvent';
import EventRow from './EventRow';
import Heading from '../general/Heading';
import MixButton from '../general/MixButton';
import ErrorMessage from './ErrorMessage';

import TranslatedString from '../general/TranslatedString';
import BackButton from '../general/BackButton';

interface Props {
  eventProps: EventProps
  email: string
  setEmail: React.Dispatch<React.SetStateAction<string>>
  password: string
  setPassword: React.Dispatch<React.SetStateAction<string>>
  loginError: number
}

const EnterAdminPrompt: React.FC<Props> = ({ eventProps, email, setEmail, password, setPassword, loginError }) => {
  return (
    <div className="enter-nip">
      <EventRow eventProps={eventProps} />
      <div className="event-logo" style={eventProps?.eventColor ? { borderColor: eventProps.eventColor } : {}}>
        {eventProps?.eventLogo != null &&
          (
            <img src={eventProps?.eventLogo}
              style={eventProps?.eventColor ? { borderColor: eventProps.eventColor } : {}}
              alt="" />
          )
        }

      </div>
      <div className="ui__group">
        <div className="login__join-form">
          <BackButton />
          <Heading level={3}>
            <TranslatedString tid="login_join-now" />
          </Heading>
          <IonInput className="ui__input nip-input"
            placeholder="Email"
            type="text"
            value={email}
            onIonChange={(e) => setEmail(e.detail.value!)}
          />
          <IonInput className="ui__input nip-input"
            placeholder="Password"
            type="password"
            value={password}
            onIonChange={(e) => setPassword(e.detail.value!)} />
          <MixButton submit>
            <TranslatedString tid="login_login-btn" />
          </MixButton>
          <ErrorMessage loginError={loginError} />
          <div className="ion-text-center ion-padding-bottom">
            <a href="tel:15142774544"
              style={{ color: eventProps?.eventColor }}>
              <TranslatedString tid="login_dont-have-ticket" />
            </a>
          </div>
          <div className="ion-text-center ion-padding-bottom">
            <a href="https://cosior.com/soutien-technique/"
              style={{ color: eventProps?.eventColor }}
              target="_blank" rel="noopener noreferrer">
              <TranslatedString tid="login_technical-difficulties" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnterAdminPrompt;
