import React, { useContext } from 'react';
import { AppContext } from '../../reducers/AppContext';
import TranslatedString from "../general/TranslatedString";

const StreamHasEnded: React.FC = () => {
    const color = useContext(AppContext).eventProps?.eventColor;

    return (
        <div className="fc__stream-has-ended">
            <div className="wrapper">
                <div className="info">
                    <div className="col remaining-time" style={color ? { color: color } : {}}>
                        <div className="text unsellectable">
                            <TranslatedString tid='event-schedule_ended_title' />
                        </div>
                        <div className="time">
                            <TranslatedString tid='event-schedule_ended_subtitle' />
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg" />
        </div>
    );
}

export default StreamHasEnded;
