import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ExhibitorsList from '../../components/exhibitors/ExhibitorsList';
import Heading from '../../components/general/Heading';
import MixText from '../../components/general/MixText';
import TranslatedString from '../../components/general/TranslatedString';
import LayoutPage from '../../components/templates/LayoutPage';
import UIContent from '../../components/UI/UIContent';
import UIGroup from '../../components/UI/UIGroup';
import { Exhibitor } from '../../interfaces/IExhibitor';
import { AppContext } from '../../reducers/AppContext';
import { LocalizationContext } from '../../reducers/LocalizationContext';
import { ExhibitorService } from '../../services/exhibitor/exhibitor.service';

const EventHomePage: React.FC = () => {
    const history = useHistory();
    const { eventProps, eventId, userMeetings, exhibitorMeetings, user } = useContext(AppContext);

    const [exhibitorList, setExhibitorList] = useState<Exhibitor[]>(null as any);
    const [goldExhibitors, setGoldExhibitors] = useState<Exhibitor[]>();
    const [silverExhibitors, setSilverExhibitors] = useState<Exhibitor[]>();
    const [bronzeExhibitors, setBronzeExhibitors] = useState<Exhibitor[]>();
    const [otherExhibitors, setOtherExhibitors] = useState<Exhibitor[]>();
    const [partners, setPartners] = useState<Exhibitor[]>();

    useEffect(() => {
        if (eventId) {
            ExhibitorService.getExhibitors(eventId).then(setExhibitorList)
        }
    }, [eventId]);

    useEffect(() => {
        if (exhibitorList) {
            setGoldExhibitors(exhibitorList?.filter(exhibitor => exhibitor.exhibitorType === 'gold').sort((a, b) => a.name < b.name ? -1 : 1));
            setSilverExhibitors(exhibitorList?.filter(exhibitor => exhibitor.exhibitorType === 'silver').sort((a, b) => a.name < b.name ? -1 : 1));
            setBronzeExhibitors(exhibitorList?.filter(exhibitor => exhibitor.exhibitorType === 'bronze').sort((a, b) => a.name < b.name ? -1 : 1));
            setPartners(exhibitorList?.filter(exhibitor => exhibitor.exhibitorType === 'partner').sort((a, b) => a.name < b.name ? -1 : 1));
            setOtherExhibitors(exhibitorList?.filter(exhibitor => exhibitor.exhibitorType !== 'partner' && exhibitor.exhibitorType !== 'bronze' && exhibitor.exhibitorType !== 'silver' && exhibitor.exhibitorType !== 'gold').sort((a, b) => a.name < b.name ? -1 : 1));
        }
    }, [exhibitorList]);
    const { dictionary } = useContext(LocalizationContext);

    const [exhibitors, setExhibitors] = useState<Exhibitor[]>([]);
    const defaultDate = dayjs('10/8/2020', 'MM/D/YYYY').locale('fr-ca').format('dddd, DD MMM YYYY');
    const [activeDate, setActiveDate] = useState(defaultDate);

    // useEffect(() => {
    //     if (user) {
    //         updateAppointments();
    //     }
    // }, [user]);

    useEffect(() => {
        if (userMeetings) {
            userMeetings.forEach((meeting) => {
                ExhibitorService.getExhibitor(eventId, meeting.exhibitorId).then(a => setExhibitors((b) => {
                    if (a) {
                        b.push(a)
                    }
                    return b;
                }))
            })
        }
    }, [userMeetings]);

    useEffect(() => {
        if (eventProps) {
            setActiveDate(dayjs(eventProps.eventDates[0]).locale('fr-ca').format('dddd, DD MMM YYYY'))
        }
    }, [eventProps]);

    const showExhibitor = (exhibitorId: string) => {
        return exhibitors.findIndex((a) => a.id === exhibitorId);
    }

    // const metaComponent = eventProps?.bannerImg?.url ? (
    //     <div className="">
    //         <img src={eventProps.bannerImg.url} alt="" />
    //     </div>
    // ) : undefined;

    const actionsComponent = (
        <UIContent>
            <Heading level={2}>
                <TranslatedString tid="general_exhibitors" />
            </Heading>
            <div className="ion-margin-top">
                <ExhibitorsList
                    narrow
                    goldExhibitors={goldExhibitors}
                    silverExhibitors={silverExhibitors}
                    bronzeExhibitors={bronzeExhibitors}
                    partners={partners}
                    otherExhibitors={otherExhibitors} />
            </div>
        </UIContent>
    );

    const [activeTab, setActiveTab] = useState(0);
    const tabs: JSX.Element[] = [
        (
            <UIGroup>
                <MixText className="home-description">
                    <TranslatedString tid="home_my-show-description" />
                </MixText>
                <div className="my-meeting-table">
                    {
                        userMeetings && userMeetings.length ? userMeetings
                            .sort((a, b) => a.time < b.time ? -1 : 1)
                            .map((meeting, index) => (
                                <div className="meeting-card" onClick={() => history.push(`/my/${eventId}/exhibitors/${meeting.exhibitorId}/appointment/${meeting.id}`)} key={index}>
                                    <div className="time">
                                        {dayjs(meeting.time, 'DDMMYYYY-HHmm').format('HH:mm')}
                                    </div>
                                    <div className="name">
                                        <TranslatedString tid="meeting-schedule_meeting-with" /> {exhibitors[showExhibitor(meeting.exhibitorId)]?.name}
                                    </div>
                                    {/* <img className="btn-close" onClick={() => cancelMeeting()} src={CloseIcon} alt="Close" /> */}
                                </div>
                            )) : <div className="ui__group">
                                <MixText centered>
                                    <TranslatedString tid="general_nothing" />
                                </MixText>
                            </div>
                    }
                    {
                        exhibitorMeetings && exhibitorMeetings.length > 0 &&
                        <div className="my-meeting-table">
                            <Heading level={3} className="ion-padding-top">
                                <TranslatedString tid="home_exhibitor-meetings" />
                            </Heading>
                            <hr />
                            {
                                exhibitorMeetings
                                    .sort((a, b) => a.time < b.time ? -1 : 1)
                                    .map((meeting, index) => (
                                        <div className="meeting-card" onClick={() => history.push(`/my/${eventId}/exhibitors/${meeting.exhibitorId}/appointment/${meeting.id}`)} key={index}>
                                            <div className="time">
                                                {dayjs(meeting.time, 'DDMMYYYY-HHmm').format('HH:mm')}
                                            </div>
                                            <div className="name">
                                                <TranslatedString tid="meeting-schedule_meeting-with" /> {meeting.userName}
                                            </div>
                                        </div>
                                    ))
                            }
                        </div>
                    }
                </div>
            </UIGroup>
        ),
        (
            <UIGroup>
                <MixText>
                    {
                        eventProps?.eventPDF?.url ?
                            <UIGroup>
                                <embed src={eventProps.eventPDF.url} />
                            </UIGroup>
                            :
                            <UIGroup>
                                <MixText centered>
                                    <TranslatedString tid="general_nothing" />
                                </MixText>
                            </UIGroup>
                    }
                </MixText>
            </UIGroup>
        )
    ];

    return (
        <LayoutPage
            columns={2}
            contentClass="home__wrapper"

            gridClass="home__grid"

            actionsComponent={actionsComponent}
            noMeta
        >
            <UIContent>
                <Heading>
                    {dictionary['schedule-home_title']}
                </Heading>
                <div className="ion-margin-top">
                    <div className="fc__tabs">
                        <div className="tab-list">
                            <div className={`tab ${activeTab === 0 ? "active" : ""}`} onClick={() => setActiveTab(0)}>
                                <TranslatedString tid='schedule-home_my_schedule' />
                            </div>
                            <div className={`tab ${activeTab === 1 ? "active" : ""}`} onClick={() => setActiveTab(1)}>
                                <TranslatedString tid='schedule-home_event_pdf' />
                            </div>
                        </div>
                        <div className="tab-content">
                            {tabs[activeTab]}
                        </div>
                    </div>
                </div>
            </UIContent>
        </LayoutPage>
    );
}

export default EventHomePage;