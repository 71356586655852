import { DatabaseService } from "../database/database.service"
import { DB_COLLECTIONS } from "../../configs/constants"
import { EventProps } from "../../interfaces/IEvent"

const getEventsList = async () => {
  return DatabaseService.getCol<EventProps>(DB_COLLECTIONS.getEventsCol(), (qry) => qry.where('tempClient', "==", "lepoint"))
}
const getEventProps = async (eventId: string) => {
  return DatabaseService.getDoc<EventProps>(DB_COLLECTIONS.getEventPath(eventId))
}

const cacheEventId = async (eventId: string) => {
  await localStorage.setItem("eventId", eventId);
}

const getEventId = async () => {
  return await localStorage.getItem("eventId");
}

const clearLocalEventId = async () => {
  await localStorage.removeItem("eventId");
}

export const EventService = {
  getEventsList,
  getEventProps,
  cacheEventId,
  getEventId,
  clearLocalEventId
}
