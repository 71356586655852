import { MenuItem } from '@material-ui/core';
import React, { useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form';
import { ExhibitorQuestion, ExhibitorQuestionResponse, ExhibitorQuestionTypes } from '../../interfaces/IExhibitor'
import InputController from '../forms/InputController';
import OldCheckboxController from '../forms/OldCheckboxController';
import SelectController from '../forms/SelectController';
import MixButton from '../general/MixButton';
import TranslatedString from '../general/TranslatedString';

interface Props {
    questions: ExhibitorQuestion[],
    onSubmit: (data: any) => void,
    selectedTimestamp: string;
}

export default function ScheduleAppointmentForm({
    questions,
    onSubmit,
    selectedTimestamp
}: Props) {

    const { control, errors, handleSubmit } = useForm<ExhibitorQuestionResponse>();
    const { fields, append } = useFieldArray({
        control,
        name: "questions"
    });

    // console.log(watch());

    useEffect(() => {
        if (questions?.length) {
            append(questions);
        }
    }, [questions?.length]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="fc__appointment-form">
            {
                fields.map((field, i) => {
                    switch (field.type) {
                        case ExhibitorQuestionTypes.Checkbox:
                            return (
                                <OldCheckboxController
                                    defaultValue=""
                                    key={i}
                                    name={field.text}
                                    errors={errors}
                                    label={field.text}
                                    control={control}
                                />
                            )
                        case ExhibitorQuestionTypes.TextInput:

                            return (
                                <InputController
                                    defaultValue=""
                                    key={i}
                                    name={field.text}
                                    errors={errors}
                                    label={field.text}
                                    control={control}
                                />
                            )
                        case ExhibitorQuestionTypes.DropDown:
                            return (
                                <SelectController
                                    defaultValue=""
                                    key={i}
                                    name={field.text}
                                    errors={errors}
                                    label={field.text}
                                    control={control}
                                >
                                    {field.options?.map((value: any) => (
                                        <MenuItem key={value} value={value}>{value}</MenuItem>
                                    ))}
                                </SelectController>
                            )
                        default:
                            return null;
                    }
                })
            }
            <MixButton disabled={selectedTimestamp === ""} submit>
                <TranslatedString tid="exhibitor-profile_schedule-btn" />
            </MixButton>
        </form>
    );
}
