import { IonCheckbox, IonItem, IonLabel } from '@ionic/react';
import React from 'react';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import { renderErrorMessage } from '../../functions';

interface Props {
    name: string
    control?: Control<any>
    errors: DeepMap<any, FieldError>
    errorField?: FieldError | undefined
    required?: boolean
    defaultValue?: string
    label?: string
}

const OldCheckboxController: React.FC<Props> = ({ name, control, errors, errorField, required = false, defaultValue = "", label }) => {
    return (
        <Controller
            defaultValue={defaultValue}
            render={({ onChange, value }) => (
                <IonItem className="ui__item">
                    <IonLabel className="ui__label">
                        {label}{required ? " (*)" : null}
                        {errors[name] ? renderErrorMessage(errors, name) : null}
                    </IonLabel>
                    <IonCheckbox value={value} onIonChange={onChange} />
                </IonItem>
            )}
            control={control}
            name={name}
            rules={{
                required: required
            }}
        />
    );
}

export default OldCheckboxController;
