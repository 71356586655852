const determineEnv = () => {
    switch (process.env.MIXABL_ENV) {
        case "production":
            return "production"
        case "marketing": 
            return "marketing"
        default:
            return "sandbox"

    }
}

export const ENVIRONMENT: "production" | "sandbox" | "marketing" = determineEnv();


