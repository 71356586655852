import { User } from 'firebase';
import React, { createContext, useEffect, useState } from 'react';
import { auth } from '../configs/firebase';

const defaultUser: User = undefined as any;

export const AuthContext = createContext(defaultUser);

export const AuthContextProvider: React.FC = ({ children }) => {
    const [authUser, setAuthUser] = useState<any>(null);

    useEffect(() => {
        return auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                setAuthUser(authUser)
            }
            else {
                setAuthUser(null as any);
            }
        })
    }, []);

    return <AuthContext.Provider value={authUser}>{children}</AuthContext.Provider>
}
