import { ExhibitorQuestionResponse } from "./IExhibitor";

export interface Meeting {
    id: string;
    userId: string;
    time: string;
    ts_time: Date;
    client: string;
    exhibitorId: string;
    userName: string,
    createdAt?: Date;
    updatedAt?: Date;
    questionResponses?: ExhibitorQuestionResponse[];
}

export enum ShowNotification {
    NOSHOW,
    SHOW,
    SHOWLASTMINUTE,
    SEENNOTIF,
    SEENLASTMINUTE,
    NEWMEETING
}