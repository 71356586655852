import React, { createContext, useState } from 'react';
import { languageOptions, dictionaryList } from '../translations';

const defaultLocalization = dictionaryList.fr_ca;

export const LocalizationContext = createContext({
    userLanguage: 'fr_ca',
    dictionary: defaultLocalization,
    userLanguageChange: (arg: string) => { }
});

export const LocalizationContextProvider: React.FC = ({ children }) => {
    const [userLanguage, setUserLanguage] = useState('fr_ca');

    const provider = {
        userLanguage,
        dictionary: dictionaryList[userLanguage],
        userLanguageChange: (selected: string) => {
            const newLanguage = languageOptions[selected] ? selected : 'fr_ca'
            setUserLanguage(newLanguage);
            window.localStorage.setItem('mixabl-lang', newLanguage);
        }
    };

    return (
        <LocalizationContext.Provider value={provider}>
            {children}
        </LocalizationContext.Provider>
    );
}
