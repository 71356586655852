import React, { useContext } from 'react';
import AdminNavigation from '../../components/admin/AdminNavigation';
import LayoutPage from '../../components/templates/LayoutPage';

import { AppContext } from '../../reducers/AppContext';
import TicketsTable from '../../components/admin/tickets/TicketsTable';
import UIContent from '../../components/UI/UIContent';
import TranslatedString from '../../components/general/TranslatedString';

const TicketManagementPage: React.FC = () => {
    const { eventId, eventProps, setLoading } = useContext(AppContext);

    return (
        <LayoutPage columns={3}>
            <UIContent>
                <AdminNavigation />
                <TicketsTable
                    eventId={eventId}
                    setLoading={setLoading} />
                <div className="ion-margin-bottom">
                    <TranslatedString tid="admin-ticket_amount-of-tickets" />: {eventProps?.userCount - eventProps?.deletedUserCount}
                </div>
            </UIContent>
        </LayoutPage>
    );
}

export default TicketManagementPage;
