import { DatabaseService } from "../database/database.service"
import { DB_COLLECTIONS } from "../../configs/constants"
import { Exhibitor, ExhibitorOffering, ExhibitorVoiceChat } from "../../interfaces/IExhibitor"
import { MeetingsService } from "../meetings/meetings.service"

const getExhibitors = async (eventId: string) => {
  return DatabaseService.getCol<Exhibitor>(DB_COLLECTIONS.getExhibitorCol(eventId));
}

const getExhibitor = async (eventId: string, exhibitorId: string) => {
  return DatabaseService.getDoc<Exhibitor>(DB_COLLECTIONS.getExhibitorPath(eventId, exhibitorId))
}

const getMeeting = async (eventId: string, exhibitorId: string, meetingId: string) => {
  return MeetingsService.getMeetingForExibitor(eventId, exhibitorId, meetingId);
}

const saveVisitor = async (eventId: string, exhibitorId: string, visitorId?: string) => {
  const path = DB_COLLECTIONS.getExhibitorAnalyticsPath(eventId, exhibitorId);

  const visit = !!visitorId ? { visitorId: visitorId } : {};

  return DatabaseService.add(path, visit);
}

const updateVoiceChatAvailability = (eventId: string, exhibitorId: string, voiceChat: "available" | "busy" | "not available") => {
  return DatabaseService.update(DB_COLLECTIONS.getExhibitorPath(eventId, exhibitorId), { voiceChat });
}

const getExhibitorR = (eventId: string, exhibitorId: string, cb: (exhibitor: Exhibitor) => void) => {
  return DatabaseService.getDocR<Exhibitor>(DB_COLLECTIONS.getExhibitorPath(eventId, exhibitorId)).subscribe(cb);
}

const getVoiceChats = (eventId: string, exhibitorId: string, cb: (voiceChat: ExhibitorVoiceChat[]) => void) => {
  return DatabaseService.getColR<ExhibitorVoiceChat>(DB_COLLECTIONS.getExhibitorVoiceChatCol(eventId, exhibitorId), (ref) => {
    return ref.where('state', '==', 'opened');
  }).subscribe(cb);
}

const addVoiceChat = (eventId: string, exhibitorId: string, voiceChat: ExhibitorVoiceChat) => {
  return DatabaseService.add(DB_COLLECTIONS.getExhibitorVoiceChatCol(eventId, exhibitorId), voiceChat);
}

const updateVoiceChat = (eventId: string, exhibitorId: string, voiceChatId: string, voiceChat: ExhibitorVoiceChat) => {
  return DatabaseService.update(DB_COLLECTIONS.getExhibitorVoiceChatDoc(eventId, exhibitorId, voiceChatId), voiceChat);
}

export const ExhibitorService = {
  getExhibitors,
  getExhibitor,
  getMeeting,
  saveVisitor,
  updateVoiceChatAvailability,
  getExhibitorR,
  getVoiceChats,
  addVoiceChat,
  updateVoiceChat,
}
