import { StorageFile } from "./IFile";

export interface EventProps {
  id: string,
  bannerImg: StorageFile,
  headerImg: StorageFile,
  closedTo: string[],
  companyName: string,
  companyLogo: string,
  companyWebsite?: string,
  eventName: string,
  eventSubname: string,
  eventDates: string[],
  eventLocation: string,
  eventPhone: string,
  eventEmail: string,
  eventLogo?: string,
  partnerLogos?: string[],
  eventColor?: string,
  features: string[],
  deletedUserCount: number,
  userCount: number,
  eventPDF: StorageFile,
  tags: string[],
  authMethod: string;
}

export enum EventFeatures {
  SCHEDULE = "schedule",
  EXHIBITORS = "exhibitors",
  SPONSORS = "sponsors",
  INFO = "info",
  ADMIN = "admin",
}
