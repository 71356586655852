import React from 'react';

interface Props {
    className?: string
}

const MixDropdownOption: React.FC<Props> = ({ className, children }) => {
    const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
    }

    return (
        <div className={`fc__dropdown-option ${className}`} onClick={(e) => handleClick(e)}>
            {children}
        </div>
    );
}

export default MixDropdownOption;
