import {IonIcon} from '@ionic/react';
import React, {useContext} from 'react';
import AddTicketIcon from '../../../assets/icons/ic-admin-btn-add-ticket.svg';
import {PopupContext} from '../../../reducers/PopupContext';
import ManageExhibitorForm from './ManageExhibitorForm';
import {LocalizationContext} from "../../../reducers/LocalizationContext";

const AddExhibitor: React.FC = () => {
    const {setModalContent, showModal} = useContext(PopupContext);
    const {dictionary} = useContext(LocalizationContext);

    const openForm = () => {
        setModalContent(<ManageExhibitorForm/>);
        showModal();
    }

    return (
        <div className="admin__add-exhibitor">
            <div className="btn" onClick={() => openForm()}>
                <div className="icon">
                    <IonIcon src={AddTicketIcon}/>
                </div>
                <div className="text">
                    {dictionary['admin-exhibitor_add']}
                </div>
            </div>
        </div>
    );
}

export default AddExhibitor;
