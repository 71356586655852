import { IonIcon } from '@ionic/react';
import React, { useRef, useState } from 'react';
import Icon from '../../assets/icons/ic-navigation-arrow_drop_down.svg';
import UIRow from '../UI/UIRow';
import Heading from './Heading';

interface Props {
    title?: string
    actionsComponent?: JSX.Element
    openByDefault?: boolean
}

const MixAccordion: React.FC<Props> = ({ title, actionsComponent, openByDefault = false, children }) => {
    const [open, setOpen] = useState(openByDefault);
    const content: React.MutableRefObject<HTMLDivElement> = useRef(null as any);

    return (
        <div className={`fc__accordion${open ? ' open' : ''}`}>
            <UIRow aic className="meta" onClick={(e) => setOpen(!open)} onMouseDown={(e) => e.preventDefault()}>
                <IonIcon src={Icon} />
                <Heading className="name" level={3} eventColor={false}>
                    {title}
                </Heading>
                <div className="actions">
                    {actionsComponent}
                </div>
            </UIRow>
            <div className="content" style={{ maxHeight: open ? content.current?.scrollHeight + 64 : 0 }} ref={content}>
                {children}
            </div>
        </div>
    );
}

export default MixAccordion;
