import React from 'react';

interface Props {
    width?: string
    alignItems?: string
    justifyContent?: string
}

const UICol: React.FC<Props> = ({ width = 'auto', alignItems = 'inherit', justifyContent = 'inherit', children }) => {
    return (
        <div className="ui__col" style={{
            width: width,
            alignItems: alignItems,
            justifyContent: justifyContent
        }}>
            {children}
        </div>
    );
}

export default UICol;
