import dayjs from 'dayjs';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { ScheduleItem } from '../../interfaces/ISchedule';
import { ScheduleService } from '../../services/schedule/schedule.service';
import Heading from '../general/Heading';
import { LocalizationContext } from "../../reducers/LocalizationContext";
import TranslatedString from "../general/TranslatedString";

interface Props {
    scheduleItems?: ScheduleItem[]
}

const UpcomingPresentations: React.FC<Props> = ({ scheduleItems }) => {
    const history = useHistory();
    const { dictionary } = useContext(LocalizationContext);

    return (
        <div className="fc__upcoming-presentations">
            <Heading level={2}>{dictionary['upcoming_presentation']}</Heading>
            <div className="cards">
                {
                    (scheduleItems && scheduleItems.length) ?
                        scheduleItems
                            .sort((a, b) => a.startsAt > b.startsAt ? 1 : -1)
                            .map((item, index) => (
                                <div className="card" key={index} onClick={() => {
                                    history.push(`./${item.id}`);
                                }}>
                                    <div className="startsAt">
                                        {dayjs(item.startsAt, "MM/D/YYYY HH:mm").format("HH:mm")}
                                    </div>
                                    <div className="name">
                                        {item.name.substr(0, 30) + `${item.name.length > 27 ? '...' : ''}`}
                                    </div>
                                    <div className="presenters">
                                        {item.presenters.map(ScheduleService.getPresenterDisplayName).join().length > 50 ? item.presenters.map(ScheduleService.getPresenterDisplayName).join().substr(0, 47) + "..." : item.presenters.map(ScheduleService.getPresenterDisplayName)}
                                    </div>
                                </div>
                            ))
                        : <TranslatedString tid="upcoming_presentation_nothing" />
                }
            </div>
        </div>
    );
}

export default UpcomingPresentations;
