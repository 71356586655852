import React from 'react';

interface Props {
    className?: string
}

const UIGroup: React.FC<Props> = ({ children, className }) => {
    return (
        <div className={"ui__group " + className}>
            {children}
        </div>
    );
}

export default UIGroup;
