import React from 'react';
import LayoutPage from '../../components/templates/LayoutPage';

const HelpPage: React.FC = () => {
    return (
        <LayoutPage
            columns={3}
            contentClass="help__wrapper"

            header="Event help"
            subHeader="You can find help here"
        >
            <div className="ui__content">

            </div>
        </LayoutPage>
    );
}

export default HelpPage;