import { IonSelect } from '@ionic/react';
import React from 'react';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import { renderErrorMessage } from '../../functions';

interface Props {
    name: string
    control?: Control<any>
    errors: DeepMap<any, FieldError>
    errorField?: (FieldError | undefined) | (FieldError | undefined)[]
    required?: boolean
    defaultValue?: string
    label?: string
    multiple?: boolean
    interfaceMode?: "action-sheet" | "popover" | "alert" | undefined
}

const OldSelectController: React.FC<Props> = ({ name, control, errors, errorField, required = true, defaultValue, label, multiple = false, interfaceMode, children }) => {
    return (
        <React.Fragment>
            {errors[name] ? renderErrorMessage(errors, name) : null}
            <Controller
                defaultValue={defaultValue}
                render={({ onChange, value }) => (
                    <IonSelect
                        value={value}
                        onIonChange={onChange}
                        className="ui__input"
                        placeholder={label + (required ? " (*)" : "")}
                        multiple={multiple}
                        interface={interfaceMode}>
                        {children}
                    </IonSelect>
                )}
                control={control}
                name={name}
                rules={{
                    required: true
                }}
            />
        </React.Fragment>
    );
}

export default OldSelectController;
