import React from 'react';
import { IonIcon, IonLoading } from '@ionic/react';

import WaitingIcon from '../../assets/icons/ic-join-video-chat.svg';
import MixButton from '../general/MixButton';
import TranslatedString from '../general/TranslatedString';
import { useContext } from 'react';
import { LocalizationContext } from '../../reducers/LocalizationContext';

export const Lobby: React.FC<{ handleSubmit: (event: React.FormEvent) => void, loading: boolean }> = ({ handleSubmit, loading }) => {
  const { dictionary } = useContext(LocalizationContext);

  return (
    <div className="ui__col">
      <IonLoading isOpen={loading} message={dictionary["general_loading"]} />

      <div className="appointment__lobby">
        <div className="status">
          <div className="icon">
            <IonIcon src={WaitingIcon} />
          </div>
          <div className="message">
            <TranslatedString tid="video-chat_connect-to-call" />
          </div>
          <MixButton
            className="btn-join-call"
            onClick={handleSubmit}
            disabled={loading}>
            <TranslatedString tid="video-chat_join-btn" />
          </MixButton>
        </div>
      </div>
    </div>
  );
};