import React from 'react';
import { ScheduleItem } from '../../interfaces/ISchedule';
import { VideoPlayer } from "../videoPlayer/videoPlayer";
import PlayerPlaceholder from './PlayerPlaceholder';
import StreamHasEnded from './StreamHasEnded';

interface Props {
    scheduleItem?: ScheduleItem
}

// streamStatus: undefined | "LIVE" | "DISCONNECTED" | "VOD" | "COMPLETED"
// undefined => not yet started, show countdown
// "LIVE" => show player and do nothing
// "DISCONNECTED" => show player and do nothing
// "VOD" => show player and do nothing

const player = (scheduleItem: ScheduleItem | undefined, videoJsOptions: any) => {
    if (scheduleItem?.m3u8) {
        const status = scheduleItem.streamStatus;
        if (typeof status === 'undefined' || status === null) {
            return (
                <PlayerPlaceholder timestamp={scheduleItem.startsAt} />
            );
        }
        if (status === "LIVE" || status === "DISCONNECTED" || status === "VOD") {
            return (
                <VideoPlayer {...videoJsOptions} />
            );
        }
        if (status === "COMPLETED") {
            return (
                <StreamHasEnded />
            );
        }
    } else {
        return (
            <iframe
                className="video-player"
                title="Stream player"
                src={scheduleItem?.streamUrl}
                scrolling="no" allow="autoplay" allowFullScreen={true} />
        );
    }
}

const WatchSection: React.FC<Props> = ({ scheduleItem }) => {
    const videoJsOptions = {
        autoplay: true,
        controls: true,
        fluid: true,
        sources: [{
            src: scheduleItem?.m3u8,
            type: 'application/x-mpegURL'
        }]
    };

    return (
        <React.Fragment>
            {player(scheduleItem, videoJsOptions)}
        </React.Fragment>
    );
}

export default WatchSection;