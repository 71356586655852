import React, {useContext, useState} from 'react';
import {IonIcon, IonLoading} from '@ionic/react';
import MixButton from '../general/MixButton';
import {Participant} from './participant';
import WaitingIcon from '../../assets/icons/ic-join-video-chat.svg';
import TranslatedString from '../general/TranslatedString';
import {LocalizationContext} from '../../reducers/LocalizationContext';
import {VideoChatService} from "../../services/videoChat/videoChat.service";
import IconButton from '@material-ui/core/IconButton';
import { Mic, MicOff, Videocam, VideocamOff, CallEnd } from '@material-ui/icons';

const muteButton = (muteState: boolean, room: any, cb: () => void) => {
    return !muteState ? <IconButton className="appointment__onOffBtn" onClick={() => {
        if (room) {
            VideoChatService.muteAudio(room)
            cb();
        }
    }}><Mic /></IconButton> : <IconButton className="appointment__onOffBtn" onClick={() => {
        if (room) {
            VideoChatService.unMuteAudio(room)
            cb();
        }
    }}><MicOff /></IconButton>
}

const videoButton = (videoState: boolean, room: any, cb: () => void) => {
    return !videoState ? <IconButton className="appointment__onOffBtn" onClick={() => {
        VideoChatService.cutVideo(room)
        cb();
    }}>
        <Videocam />
    </IconButton> : <IconButton className="appointment__onOffBtn" onClick={() => {
        VideoChatService.openVideo(room)
        cb();
    }}>
        <VideocamOff />
    </IconButton>
}

export const Room: React.FC<{
    handleLogout: () => void,
    loading: boolean,
    room: any,
    participants: any[]
}> = ({handleLogout, loading, room, participants}) => {
    const {dictionary} = useContext(LocalizationContext);
    const [muted, setMuted] = useState<boolean>(false)
    const [video, setVideo] = useState<boolean>(false)

    const remoteParticipants = participants.map(participant => (
        <Participant key={participant.sid} participant={participant}/>
    ));

    return (
        <div className="appointment__room">
            <IonLoading isOpen={loading} message={dictionary["general_loading"]}/>
            <div className="ui__container">
                <div className="ui__col">
                    <div className="appointment__remote">
                        {remoteParticipants.length !== 0 ? remoteParticipants : (
                            <div className="status">
                                <div className="icon">
                                    <IonIcon src={WaitingIcon}/>
                                </div>
                                <div className="status-message">
                                    <TranslatedString tid="video-chat_waiting-for-participant"/>
                                </div>
                            </div>
                        )}
                        <div className="appointment_buttons">
                            {muteButton(muted, room, () => {
                                setMuted(!muted);
                            })}
                            <IconButton onClick={handleLogout} className="appointment__logoutBtn">
                                <CallEnd />
                            </IconButton>
                            {videoButton(video, room, () => {
                                setVideo(!video)
                            })}
                        </div>
                    </div>
                    <div className="appointment__local">
                        {room ? (
                            <Participant
                                key={room.localParticipant.sid}
                                participant={room.localParticipant}
                            />
                        ) : (
                            dictionary["general_connecting"] + "..."
                        )}
                    </div>

                </div>
            </div>
        </div>
    );
};
