export enum AnalyticsPreference {
  NoAnswer = 0,
  Yes = 1,
  No = 2,
}

export interface User {
  id: string,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  company: string,
  title: string,

  standard: boolean,
  presenter: string[],
  exhibitor: string,
  admin: boolean,

  activeSession?: string,
  revokedSessions?: string[],
  enrolledInAnalytics: AnalyticsPreference,
}
