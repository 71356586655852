import AddToCalendar from '@culturehq/add-to-calendar';
import dayjs, { Dayjs } from 'dayjs';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ScheduleItem } from '../../interfaces/ISchedule';
import ThumbnailPlaceholder from '../../assets/video-placeholder.png';
import { useHistory } from 'react-router';
import { useWindowSize } from '../../hooks';
import { AppContext } from '../../reducers/AppContext';
import { mutateColor } from '../../functions';
import { ScheduleService } from '../../services/schedule/schedule.service';
import duration from 'dayjs/plugin/duration';
import TranslatedString from '../general/TranslatedString';

dayjs.extend(duration)

interface Props {
    items: ScheduleItem[]
    eventId: string
    activeDate: string
}

const ScheduleList: React.FC<Props> = ({ items, eventId, activeDate }) => {
    const color = useContext(AppContext).eventProps?.eventColor;
    const history = useHistory();

    let startTime = useRef("");
    let endTime = useRef("");
    let [timestamps, setTimestamps] = useState<string[]>(null as any);

    const component = document.querySelector('.fc__schedule-list');
    const { height } = useWindowSize();

    const [filteredItems, setFilteredItems] = useState<ScheduleItem[]>([]);
    const [now, setNow] = useState<Dayjs>(dayjs());

    useEffect(() => {
        if (activeDate) {
            setFilteredItems(items.filter((item) => dayjs(item.startsAt, "MM/D/YYYY HH:mm").format("MM/D/YYYY") === activeDate));
        }
    }, [items, activeDate]);

    useEffect(() => {
        if (activeDate && filteredItems?.length) {
            startTime.current = filteredItems.reduce((res, obj) => {
                return (obj.startsAt < res.startsAt) ? obj : res;
            }).startsAt;
            endTime.current = filteredItems.reduce((res, obj) => {
                return (obj.endsAt > res.endsAt) ? obj : res;
            }).endsAt;

            let currentTimestamp = startTime.current;
            let arr: string[] = [];
            while (currentTimestamp < endTime.current) {
                arr.push(currentTimestamp)
                currentTimestamp = dayjs(currentTimestamp, 'MM/D/YYYY HH:mm').add(15, 'minute').format('MM/DD/YYYY HH:mm');
            }
            setTimestamps(arr);
        }
    }, [filteredItems]);

    useEffect(() => {
        const interval = setInterval(() => {
            setNow(dayjs())
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const timestampsDiv = document.querySelector('.timestamps');
    const [timestampsHeight, setTimestampsHeight] = useState(0);
    const [currentTopPos, setCurrentTopPos] = useState(0);
    const [displayCrawler, setDisplayCrawler] = useState(false);

    useEffect(() => {
        if (timestamps && timestampsDiv) {
            setTimestampsHeight(timestampsDiv.scrollHeight);
        }
    }, [timestamps]);

    useEffect(() => {
        if (
            (now && timestamps) &&
            now.isAfter(dayjs(timestamps[0])) &&
            now.isBefore(dayjs(timestamps[timestamps.length - 1]))
        ) {
            // we're in
            console.log("we're in");
            setDisplayCrawler(true);
            // 220
            const timeDiff = Math.abs(now.diff(dayjs(timestamps[timestamps.length - 1]), 'minute', true));
            console.log("time difference between now and last timestamp: ", timeDiff);
            // 570
            const allTimeDiff = Math.abs(dayjs(timestamps[0]).diff(timestamps[timestamps.length - 1], "minute"));
            console.log("time difference between first and last timestamps: ", allTimeDiff);
            // 350
            const sub = allTimeDiff - timeDiff;
            // 5.7
            const absPercent = allTimeDiff / 100;
            // ~70%
            const pPercent = sub / absPercent;

            if (timestampsDiv) {
                setCurrentTopPos((timestampsHeight / 100 * pPercent) - 15);
            }
        }
    }, [now]);

    let oldCard: HTMLDivElement = null as any;
    const highlightCard = (card: HTMLDivElement) => {
        if (card) {
            if (oldCard) {
                oldCard.classList.remove('highlighted')
            }
            oldCard = card;
            card.classList.add('highlighted')
        }
    }

    return (
        <div className="fc__schedule-list">
            <div className="header" style={color ? { borderColor: color } : {}} />
            <div className="content">
                <div className="timestamps">
                    {
                        displayCrawler &&
                        <div className="crawler" style={{ top: currentTopPos }}>
                            <div className="time">
                                {now.format("HH:mm")}
                            </div>
                        </div>
                    }
                    {
                        timestamps?.map((timestamp, index) => (
                            <div className="timestamp" key={index}>
                                <div className="value">
                                    {dayjs(timestamp).format('HH:mm')}
                                </div>
                                <div className="events">
                                    {filteredItems?.filter(element => element?.startsAt === timestamp).map((item, index) => (
                                        <div
                                            className={`event ${item.streamStatus === 'LIVE' ? "live" : item.streamStatus === 'VOD' ? "vod" : "scheduled"}`}
                                            onClick={() => {
                                                if (component) {
                                                    component.scrollTop = document.getElementById(`${item.id}`)!.offsetTop - (height / 2.5);
                                                    highlightCard(document.getElementById(`${item.id}`) as HTMLDivElement);
                                                }
                                            }} key={index}>
                                            <div className="tip">
                                                <TranslatedString tid="general_ends" /> {
                                                    dayjs(
                                                        filteredItems?.find(
                                                            element => element?.startsAt === timestamp
                                                        )?.endsAt,
                                                        'MM/D/YYYY HH:mm'
                                                    ).format('HH:mm')
                                                }
                                            </div>
                                        </div>
                                    ))
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="streams">
                    {
                        filteredItems &&
                        filteredItems
                            .sort((a, b) => dayjs(a.startsAt, 'MM/D/YYYY HH:mm').valueOf() - dayjs(b.startsAt, 'MM/D/YYYY HH:mm').valueOf())
                            .map((stream, index) => (
                                <div className="stream-card" id={`${stream.id}`} key={index}
                                    onClick={() => {
                                        if (stream?.type != 'general')
                                            history.push(`/my/${eventId}/watch/${stream.id}`)
                                    }}>
                                    <div className="location">
                                        {
                                            stream?.location?.length > 16 ? stream.location.substr(0, 14) + '...' : stream.location
                                        }
                                    </div>
                                    <div className="wrapper">
                                        <div className="meta">
                                            <div
                                                className="name"
                                                style={color ? { color: mutateColor(color, 9) } : {}}
                                            >
                                                {stream?.name?.length > 58 ?
                                                    stream.name.substr(0, 58) + '...' : stream.name}
                                            </div>
                                            <div
                                                className="presentors"
                                                style={color ? { color: mutateColor(color, 11) } : {}}
                                            >
                                                {stream?.presenters && stream.presenters.map(ScheduleService.getPresenterDisplayName).join(', ')}
                                            </div>
                                            <div className="description">
                                                {stream?.description?.length > 96 ?
                                                    stream.description.substr(0, 96) + '...' : stream.description}
                                                <div className="more">MORE</div>
                                            </div>
                                        </div>
                                        <div className="thumbnail">
                                            <img
                                                src={stream?.thumbnail?.url ? stream.thumbnail.url : ThumbnailPlaceholder}
                                                alt="Stream preview" />
                                        </div>
                                        {(stream?.startsAt && stream?.endsAt) ?
                                            <div className="add-to-calendar" onClick={(e) => e.stopPropagation()}>
                                                <AddToCalendar
                                                    children={null}
                                                    event={{
                                                        name: stream?.name,
                                                        details: stream?.description,
                                                        location: stream?.streamUrl,
                                                        startsAt: dayjs(stream.startsAt, 'MM/DD/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:00-05:00'),
                                                        endsAt: dayjs(stream.endsAt, 'MM/DD/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:00-05:00')
                                                    }}
                                                />
                                            </div> : null
                                        }
                                        {
                                            (stream?.streamStatus) ?
                                                (
                                                    (stream?.streamStatus !== 'COMPLETED' && stream?.streamStatus !== 'DISCONNECTED') &&
                                                    <div
                                                        className={`status${stream.streamStatus === 'LIVE' ? " live" : ""}`}>
                                                        {stream.streamStatus === 'LIVE' ?
                                                            <TranslatedString tid="general_live" />
                                                            : stream.streamStatus === 'VOD' ?
                                                                <TranslatedString tid="general_vod" />
                                                                : stream.streamStatus}
                                                    </div>
                                                )
                                                :
                                                (
                                                    <div className="status scheduled">
                                                        {
                                                            dayjs(stream?.startsAt, "MM/DD/YYYY HH:mm").format("HH:mm")
                                                        }
                                                    </div>
                                                )
                                        }
                                    </div>
                                </div>
                            ))
                    }
                </div>
            </div>

        </div>
    );
}

export default ScheduleList;
