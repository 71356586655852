import React, { useContext } from 'react';
import AdminNavigation from '../../components/admin/AdminNavigation';
import MixAccordion from '../../components/general/MixAccordion';
import Heading from '../../components/general/Heading';
import LayoutPage from '../../components/templates/LayoutPage';
import UIContent from '../../components/UI/UIContent';
import UIRow from '../../components/UI/UIRow';
import { LocalizationContext } from "../../reducers/LocalizationContext";
import MixDropdown from '../../components/general/MixDropdown';
import MixDropdownOption from '../../components/general/MixDropdownOption';
import UICol from '../../components/UI/UICol';
import MixCard from '../../components/general/MixCard';
import UIGroup from '../../components/UI/UIGroup';
import MixText from '../../components/general/MixText';
import SearchBar from '../../components/general/SearchBar';

const AnalyticsPage: React.FC = () => {
    const { dictionary } = useContext(LocalizationContext);
    const metaComponent = <AdminNavigation />;

    const userActionsComponent = (
        <UIRow>
            <div className="item">Live</div>
            <div className="item">To-Date</div>
            <MixDropdown className="item" title="By Date">
                <MixDropdownOption>
                    Day 1
                </MixDropdownOption>
                <MixDropdownOption>
                    Day 2
                </MixDropdownOption>
            </MixDropdown>
            <MixDropdown className="item" title="By Screen">
                <MixDropdownOption>
                    Schedule
                </MixDropdownOption>
                <MixDropdownOption>
                    All Live Streams
                </MixDropdownOption>
                <MixDropdownOption>
                    Exhibitors (Grid)
                </MixDropdownOption>
                <MixDropdownOption>
                    ...
                </MixDropdownOption>
            </MixDropdown>
        </UIRow>
    );

    const viewershipActionsComponent = (
        <UIRow>
            <div className="item">Overall</div>
            <MixText className="item">
                OR
            </MixText>
            <SearchBar className="item" />
        </UIRow>
    );

    return (
        <LayoutPage columns={3}>
            <UIContent>
                <AdminNavigation />
                <Heading>{dictionary['general_admin-page']}: Event Analytics</Heading>
                <MixAccordion title="Users" actionsComponent={userActionsComponent} openByDefault>
                    <UIRow>
                        <UICol width="33.3%">
                            <MixCard title="Quick Facts">
                                <UIGroup>
                                    <UIRow aic jcc>
                                        <MixText width="70%">
                                            Logged In Now:
                                        </MixText>
                                        <MixText width="30%">
                                            97
                                        </MixText>
                                    </UIRow>
                                    <UIRow aic jcc>
                                        <MixText width="70%">
                                            Avg. Time/Screen:
                                        </MixText>
                                        <MixText width="30%">
                                            7:30
                                        </MixText>
                                    </UIRow>
                                    <UIRow aic jcc>
                                        <MixText width="70%">
                                            Avg. Screens/Session:
                                        </MixText>
                                        <MixText width="30%">
                                            17
                                        </MixText>
                                    </UIRow>
                                </UIGroup>
                            </MixCard>
                        </UICol>
                        <UICol width="33.3%">
                            <MixCard title="Screen Distribution">

                            </MixCard>
                        </UICol>
                        <UICol width="33.3%">
                            <MixCard title="User Type Distribution">

                            </MixCard>
                        </UICol>
                    </UIRow>
                </MixAccordion>
                <MixAccordion title="Viewership" actionsComponent={viewershipActionsComponent} openByDefault>
                    <UIRow>
                        <UICol width="33.3%">
                            <MixCard title="Quick Facts">
                                <UIGroup>
                                    <UIRow aic jcc>
                                        <MixText width="70%">
                                            Subscribers
                                        </MixText>
                                        <MixText width="30%">
                                            10,656
                                        </MixText>
                                    </UIRow>
                                    <UIRow aic jcc>
                                        <MixText width="70%">
                                            Hours Watched
                                        </MixText>
                                        <MixText width="30%">
                                            45,000
                                        </MixText>
                                    </UIRow>
                                    <UIRow aic jcc>
                                        <MixText width="70%">
                                            Viewed/Completed*
                                        </MixText>
                                        <MixText width="30%">

                                        </MixText>
                                    </UIRow>
                                    <hr />
                                    <UIRow aic jcc>
                                        <MixText width="70%">
                                            Groups
                                        </MixText>
                                        <MixText width="30%">
                                            17/12*
                                        </MixText>
                                    </UIRow>
                                    <UIRow aic jcc>
                                        <MixText width="70%">
                                            Presentations
                                        </MixText>
                                        <MixText width="30%">
                                            800/750*
                                        </MixText>
                                    </UIRow>
                                </UIGroup>
                            </MixCard>
                        </UICol>
                        <UICol width="33.3%">
                            <MixCard title="Gross Revenue">
                                <UIGroup>
                                    <Heading centered level={3} eventColor={false}>
                                        $467,000
                                    </Heading>
                                </UIGroup>
                            </MixCard>
                        </UICol>
                        <UICol width="33.3%">
                            <MixCard title="Top Presentations">

                            </MixCard>
                        </UICol>
                    </UIRow>
                </MixAccordion>
            </UIContent>
        </LayoutPage>
    );
}

export default AnalyticsPage;
