import React, { useContext, useEffect, useState } from 'react';
import { IonIcon } from '@ionic/react';
import { AppContext } from '../../reducers/AppContext';
import { Exhibitor } from '../../interfaces/IExhibitor';
import { hexToRGB } from '../../functions';
import { ExhibitorService } from '../../services/exhibitor/exhibitor.service';
import { useParams } from 'react-router';
import GridIcon from '../../assets/icons/ic-grid.svg';
import ListIcon from '../../assets/icons/ic-list.svg';
import { useWindowSize } from '../../hooks';
import TranslatedString from '../../components/general/TranslatedString';
import LayoutPage from '../../components/templates/LayoutPage';
import { LocalizationContext } from '../../reducers/LocalizationContext';
import UIGroup from '../../components/UI/UIGroup';
import UIContent from '../../components/UI/UIContent';
import ExhibitorsGrid from '../../components/exhibitors/ExhibitorsGrid';
import ExhibitorsList from '../../components/exhibitors/ExhibitorsList';
import Heading from '../../components/general/Heading';

const ExhibitorsDirectoryPage: React.FC = () => {
  const { eventId } = useParams<{ [id: string]: string }>();
  const { eventProps } = useContext(AppContext);
  const { dictionary } = useContext(LocalizationContext);

  const { width } = useWindowSize();

  const [gridMode, setGridMode] = useState(true);
  const [exhibitorList, setExhibitorList] = useState<Exhibitor[]>(null as any);
  const [goldExhibitors, setGoldExhibitors] = useState<Exhibitor[]>();
  const [silverExhibitors, setSilverExhibitors] = useState<Exhibitor[]>();
  const [bronzeExhibitors, setBronzeExhibitors] = useState<Exhibitor[]>();
  const [partners, setPartners] = useState<Exhibitor[]>();
  const [otherExhibitors, setOtherExhibitors] = useState<Exhibitor[]>();

  useEffect(() => {
    if (eventId) {
      ExhibitorService.getExhibitors(eventId).then(setExhibitorList)
    }
  }, [eventId]);

  useEffect(() => {
    if (exhibitorList) {
      setGoldExhibitors(exhibitorList?.filter(exhibitor => exhibitor.exhibitorType === 'gold').sort((a, b) => a.name < b.name ? -1 : 1));
      setSilverExhibitors(exhibitorList?.filter(exhibitor => exhibitor.exhibitorType === 'silver').sort((a, b) => a.name < b.name ? -1 : 1));
      setBronzeExhibitors(exhibitorList?.filter(exhibitor => exhibitor.exhibitorType === 'bronze').sort((a, b) => a.name < b.name ? -1 : 1));
      setPartners(exhibitorList?.filter(exhibitor => exhibitor.exhibitorType === 'partner').sort((a, b) => a.name < b.name ? -1 : 1));
      setOtherExhibitors(exhibitorList?.filter(exhibitor => exhibitor.exhibitorType !== 'partner' && exhibitor.exhibitorType !== 'bronze' && exhibitor.exhibitorType !== 'silver' && exhibitor.exhibitorType !== 'gold').sort((a, b) => a.name < b.name ? -1 : 1));
    }
  }, [exhibitorList]);

  useEffect(() => {
    if (width <= 1024) {
      setGridMode(false);
    }
  }, [width]);

  return (
    <LayoutPage
      pageClass="exhibitors-page"
      contentClass="exhibitors__wrapper"
      columns={3}
    >
      <UIContent>
        <Heading>
          {dictionary["exhibitors_exhibitor-directory"]}
        </Heading>
        <Heading level={2} className="ion-margin-bottom">
          {dictionary["exhibitors_click-tip"]}
        </Heading>
        <div style={eventProps?.eventColor ? { borderColor: hexToRGB(eventProps.eventColor, 0.3) } : {}}
          className="sort-section">
          <div className="results-str">
            {exhibitorList?.length} <TranslatedString tid="exhibitors_results" />
          </div>
          <div className="action-btns">
            <IonIcon className="grid-btn" src={GridIcon} onClick={() => setGridMode(true)} />
            <IonIcon className="list-btn" src={ListIcon} onClick={() => setGridMode(false)} />
          </div>
        </div>
        {
          gridMode ?
            (
              <UIGroup>
                <ExhibitorsGrid goldExhibitors={goldExhibitors} silverExhibitors={silverExhibitors} bronzeExhibitors={bronzeExhibitors} partners={partners} otherExhibitors={otherExhibitors} />
              </UIGroup>
            ) : (
              <UIGroup>
                <ExhibitorsList goldExhibitors={goldExhibitors} silverExhibitors={silverExhibitors} bronzeExhibitors={bronzeExhibitors} partners={partners} otherExhibitors={otherExhibitors} />
              </UIGroup>
            )
        }
      </UIContent>
    </LayoutPage>
  );
};

export default ExhibitorsDirectoryPage;
