import { IonButton, IonIcon, IonLoading, useIonViewDidLeave } from '@ionic/react';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Heading from '../../components/general/Heading';
import MixText from '../../components/general/MixText';
import { hexToRGB, urlify } from '../../functions';
import { AppContext } from '../../reducers/AppContext';
import DownloadIcon from '../../assets/icons/ic-download.svg';
import { ExhibitorService } from '../../services/exhibitor/exhibitor.service';
import { Exhibitor, ExhibitorOffering } from '../../interfaces/IExhibitor';
import { MeetingsService } from '../../services/meetings/meetings.service';
import GoldExhibitorCard from '../../components/exhibitors/GoldExhibitorCard';
import SilverExhibitorCard from '../../components/exhibitors/SilverExhibitorCard';
import ExhibitorCard from '../../components/exhibitors/ExhibitorCard';
import TranslatedString from '../../components/general/TranslatedString';
import { LocalizationContext } from '../../reducers/LocalizationContext';
import LayoutPage from '../../components/templates/LayoutPage';
import ScheduleAppointmentForm from '../../components/exhibitors/ScheduleAppointmentForm';
import { StorageFile } from '../../interfaces/IFile';
import { DB_COLLECTIONS } from '../../configs/constants';
import VoiceChat from '../../components/voiceChat/voiceChat';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SendChatRequestBody } from '../../interfaces/IChat';

const ExhibitorProfilePage: React.FC = () => {
    const { eventProps, user, nextAppointment, setPageChat } = useContext(AppContext);
    const { dictionary } = useContext(LocalizationContext);

    const { eventId, exhibitorId } = useParams<{ [id: string]: string }>();

    const eventColor = eventProps?.eventColor;

    const [selectedTimestamp, setSelectedTimestamp] = useState("");
    const [exhibitor, setExhibitor] = useState<Exhibitor | any>(null as any);
    const [exhibitorR, setExhibitorR] = useState<Exhibitor | any>(null as any);
    const [offerings, setOfferings] = useState<ExhibitorOffering[]>(null as any);
    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [isError, setIsError] = useState<boolean>(false);

    useEffect(() => {
        if (exhibitor) {
            setPageChat({
                data: {
                    eventId, exhibitorId: 
                    exhibitor.id,
                    type: "exhibitor",
                } as SendChatRequestBody,
                name: dictionary["generalChat_exhibitor"],
                chatEnabled: true
            });
        }
    }, [exhibitor, dictionary]);

    useEffect(() => {
        return () => {
            setPageChat(null);
        }
    }, []);

    useEffect(() => {
        ExhibitorService.getExhibitor(eventId, exhibitorId).then(setExhibitor);
        return ExhibitorService.getExhibitorR(eventId, exhibitorId, setExhibitorR);
    }, [eventId, exhibitorId]);

    useEffect(() => {
        if (exhibitorR) {
            const selected = exhibitorR.availableAppointments?.find((time: string) => time === selectedTimestamp);
            if (!selected) {
                setSelectedTimestamp("");
            }
        }
    }, [exhibitorR?.availableAppointments?.length]);

    useEffect(() => {
        if (user && eventId && exhibitor) {
            if (user?.enrolledInAnalytics === 1) {
                ExhibitorService.saveVisitor(eventId, exhibitorId, user.id);
            } else {
                ExhibitorService.saveVisitor(eventId, exhibitorId);
            }
        }
    }, [user, eventId, exhibitor, exhibitorId]);

    const scheduleMeeting = (data: any) => {
        setLoading(true);
        setMessage("");
        setIsError(false);
        MeetingsService.scheduleMeeetingWithExibitor(
            eventId, exhibitorId, user.id, selectedTimestamp, data
        ).then(() => {
            setIsError(false);
            setMessage(`${dictionary["exhibitor-profile_meeting-saved-1"]} ${exhibitor?.name} ${dictionary["exhibitor-profile_meeting-saved-2"]}`);
            // updateAppointments();
        }).catch(() => {
            setIsError(true);
            setMessage(dictionary["exhibitor-profile_meeting-error"]);
        }).finally(() => {
            // ExhibitorService.getExhibitor(eventId, exhibitorId).then(setExhibitor);
            setLoading(false);
        })
        setSelectedTimestamp("");
    }

    useIonViewDidLeave(() => setExhibitor(null as any))

    const metaComponent = (
        <React.Fragment>
            {
                exhibitor?.exhibitorType === 'gold' ? (
                    <GoldExhibitorCard exhibitor={exhibitor} />
                )
                    : exhibitor?.exhibitorType === 'silver' ? (
                        <SilverExhibitorCard exhibitor={exhibitor} />
                    ) :
                        (
                            <ExhibitorCard exhibitor={exhibitor} />
                        )
            }
            <div className="voice-calling">
                <Heading level={2}>{dictionary['voice-chat_title']}</Heading>
                <VoiceChat exhibitor={exhibitor} />
            </div>
        </React.Fragment>
    );

    const actionsComponent = (
        <div className="ui__col form">
            <div className="ui__group heading">
                <Heading centered level={4} className="dark fwn">
                    <TranslatedString tid="exhibitor-profile_schedule-meeting" />
                </Heading>
            </div>
            <div className="ui__group meeting-time-table"
                style={exhibitorR?.color && (exhibitorR?.exhibitorType === 'gold' || exhibitorR?.exhibitorType === 'silver' ? { borderColor: hexToRGB(exhibitorR.color, 0.3) } : { borderColor: hexToRGB(eventProps?.eventColor ? eventProps?.eventColor : '#000', 0.3) })}>
                {
                    exhibitorR?.availableAppointments ? exhibitorR?.availableAppointments.map((timestamp: string, index: number) => (
                        <div
                            onClick={() => setSelectedTimestamp(timestamp)}
                            className={`timestamp ${selectedTimestamp === timestamp ? 'selected' : ''}`}
                            key={index}
                            style={
                                (exhibitorR?.exhibitorType === 'gold' || exhibitorR?.exhibitorType === 'silver' ? { color: exhibitorR.color } : {})
                            }>
                            {dayjs(timestamp, 'MMDDYYYY-HHmm').format('HH:mm')}
                        </div>
                    )) : (
                            <MixText>
                                <TranslatedString tid="exhibitor-profile_everything-is-booked" />
                            </MixText>
                        )
                }
            </div>
            {
                exhibitorR?.availableAppointments ?
                    (
                        <div className="ui__group meeting-form">
                            <ScheduleAppointmentForm
                                selectedTimestamp={selectedTimestamp}
                                onSubmit={scheduleMeeting}
                                questions={exhibitorR.questionnaire}
                            />
                        </div>
                    ) : null
            }
        </div>

    );

    return (
        <LayoutPage
            pageClass="exhibitors-page"
            contentClass="exhibitors__schedule-meeting"
            gridClass="exhibitors__profile-grid"

            columns={3}

            header={dictionary["exhibitor-profile_title"]}
            subHeader={exhibitor?.name}

            metaComponent={metaComponent}
            actionsComponent={actionsComponent}

            back
        >
            <IonLoading isOpen={loading} message={dictionary["general_loading"]} />

            <div className="ui__container profile">
                <div className="ui__col about">
                    <div className="ui__content">
                        <Slider arrows dots infinite speed={500} slidesToShow={1} slidesToScroll={1}>
                            {
                                exhibitor?.videoLink &&
                                <div className="slide">
                                    <video title={dictionary["exhibitor-profile_title"]} controls autoPlay={false}>
                                        <source src={exhibitor?.videoLink.url} />
                                    </video>
                                </div>
                            }
                            {
                                exhibitor?.sliderImages?.map((media: StorageFile, index: number) => {
                                    if (media?.type?.includes('video')) {
                                        return (
                                            <div className="slide" key={index}>
                                                <video controls autoPlay={false}>
                                                    <source src={media.url} />
                                                </video>
                                            </div>
                                        )
                                    }
                                    if (media?.type?.includes('image')) {
                                        return (
                                            <div className="slide" key={index}>
                                                <img src={media.url} alt="" />
                                            </div>
                                        )
                                    }
                                })
                            }
                            {exhibitor?.offerings?.map((offering: ExhibitorOffering, index: number) => (
                                <div className="offering slide" key={index}>
                                    <div className="ui__col info">
                                        <div className="ui__group">
                                            {offering.name &&
                                                <MixText color="dark" className="name">{offering.name}</MixText>
                                            }
                                            {offering.description &&
                                                <MixText color="dark" className="description">{offering.description}</MixText>
                                            }
                                        </div>
                                    </div>
                                    {offering?.file?.url &&
                                        <div className="ui__col buttons">
                                            <div className="ui__group">
                                                <IonButton
                                                    className="btn-action"
                                                    style={{
                                                        'background': eventColor,
                                                        '--background': eventColor,
                                                        '--background-hover': eventColor,
                                                        '--background-activated': eventColor,
                                                    }}
                                                    onClick={() => window.open(offering?.file?.url, '_system')}>
                                                    <IonIcon src={DownloadIcon} />
                                                    <TranslatedString tid="general_download-btn" />
                                                </IonButton>
                                            </div>
                                        </div>
                                    }
                                </div>
                            ))
                            }
                        </Slider>
                        {
                            exhibitor?.description && (
                                <div className="ui__group description">
                                    <Heading level={4} className="dark">
                                        <TranslatedString tid="exhibitor-profile_description" />
                                    </Heading>
                                    <MixText color="dark" className="booth__description">
                                        {urlify(exhibitor?.description.replace(/\\n/g, '\n'))}
                                    </MixText>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </LayoutPage>
    );
}

export default ExhibitorProfilePage;
