import {ENVIRONMENT} from "."

const production = {
    apiUrl: 'https://us-central1-mixabl.cloudfunctions.net',
}

const sandbox = {
    apiUrl: 'https://us-central1-mixabl-sanbox-east1.cloudfunctions.net',
}

const marketing = {
    apiUrl: 'https://us-central1-mixabl-marketing.cloudfunctions.net',
}

export const commitHash = 'cedfc4f'


export const environment = (() => {
    switch (ENVIRONMENT) {
        case 'production':
            return production;
        case 'sandbox':
            return sandbox;
        case "marketing":
            return marketing;
    }
})()

