import {DB_COLLECTIONS, CLIENT_ID} from "../../configs/constants"
import {User} from "../../interfaces/IUser"
import {auth} from "../../configs/firebase";
import {environment} from "../../configs/environment";
import firestore from "firebase";
import {PaginationService} from "../pagination/pagination.service";
import {DatabaseService} from "../database/database.service";

const getUsers = (eventId: string) => {
    return new PaginationService.Paginator<User>(
        DB_COLLECTIONS.getUserCol(eventId),
        [
            {key: 'lastName', direction: 'asc'},
            {key: 'firstName', direction: 'asc'},
            {key: firestore.firestore.FieldPath.documentId(), direction: 'asc'}
        ]
    );
}

const getUser = (eventId: string, userId: string, cb: (user: User) => void) => {
    return DatabaseService.getDocR<User>(DB_COLLECTIONS.getUserPath(eventId, userId)).subscribe(cb);
}

const createTicket = async (user: User, eventId: string) => {
    const body = JSON.stringify({
        ...user,
        eventId: eventId,
        clientId: CLIENT_ID
    });
    const token: any = await auth.currentUser?.getIdToken();

    return await fetch(`${environment.apiUrl}/createTicket`, {
        method: 'POST',
        body: body,
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    })
        .then(res => res.json())
        .catch((err) => console.log(err));
}

const updateTicket = async (user: User, targetId: string, eventId: string) => {
    const body = JSON.stringify({
        ...user,
        targetId: targetId,
        eventId: eventId,
        clientId: CLIENT_ID
    });
    const token: any = await auth.currentUser?.getIdToken();

    return fetch(`${environment.apiUrl}/updateTicket`, {
        method: 'POST',
        body: body,
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }).catch((err) => console.log(err))
}

const resetTicket = async (targetId: string, eventId: string) => {
    const body = JSON.stringify({
        eventId: eventId,
        targetId: targetId,
        clientId: CLIENT_ID
    });
    const token: any = await auth.currentUser?.getIdToken();

    return await fetch(`${environment.apiUrl}/resetTicket`, {
        method: 'POST',
        body: body,
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    })
        .then(res => res.text())
        .catch((err) => console.log(err))
}

const deleteTicket = async (targetId: string, eventId: string) => {
    const body = JSON.stringify({
        eventId: eventId,
        targetId: targetId,
        clientId: CLIENT_ID
    });
    const token: any = await auth.currentUser?.getIdToken();

    return fetch(`${environment.apiUrl}/deleteTicket`, {
        method: 'POST',
        body: body,
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }).catch((err) => console.log(err))
}

const createScheduleItem = async (scheduleItem: any, eventId: string) => {
    const body = JSON.stringify({
        ...scheduleItem,
        eventId: eventId,
        clientId: CLIENT_ID
    });
    const token: any = await auth.currentUser?.getIdToken();

    return await fetch(`${environment.apiUrl}/createScheduleItem`, {
        method: 'POST',
        body: body,
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    })
        .then((res) => res.text())
        .catch((err) => console.log(err))
}

const updateScheduleItem = async (scheduleItem: any, eventId: string) => {
    const body = JSON.stringify({
        ...scheduleItem,
        targetId: scheduleItem.id,
        eventId: eventId,
        clientId: CLIENT_ID
    });
    const token: any = await auth.currentUser?.getIdToken();

    return fetch(`${environment.apiUrl}/updateScheduleItem`, {
        method: 'POST',
        body: body,
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }).catch((err) => console.log(err))
}

const deleteScheduleItem = async (id: string, eventId: string) => {
    const body = JSON.stringify({
        targetId: id,
        eventId: eventId,
        clientId: CLIENT_ID
    });
    const token: any = await auth.currentUser?.getIdToken();

    return await fetch(`${environment.apiUrl}/deleteScheduleItem`, {
        method: 'POST',
        body: body,
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }).catch((err) => console.log(err))
}

const createExhibitor = async (exhibitor: any, eventId: string) => {
    const body = JSON.stringify({
        ...exhibitor,
        eventId: eventId,
        clientId: CLIENT_ID
    });
    const token: any = await auth.currentUser?.getIdToken();

    console.log('body', body);
    console.log('token', token);

    return await fetch(`${environment.apiUrl}/createExhibitor`, {
        method: 'POST',
        body: body,
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    })
        .then((res) => res.text())
        .catch((err) => console.log(err))
}

const updateExhibitor = async (exhibitor: any, eventId: string, targetId: string) => {
    const body = JSON.stringify({
        ...exhibitor,
        eventId: eventId,
        targetId: targetId,
        clientId: CLIENT_ID
    });
    const token: any = await auth.currentUser?.getIdToken();

    return await fetch(`${environment.apiUrl}/updateExhibitor`, {
        method: 'POST',
        body: body,
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }).catch((err) => console.log(err))
}

const deleteExhibitor = async (eventId: string, targetId: string) => {
    const body = JSON.stringify({
        eventId: eventId,
        targetId: targetId,
        clientId: CLIENT_ID
    });
    const token: any = await auth.currentUser?.getIdToken();

    return await fetch(`${environment.apiUrl}/deleteExhibitor`, {
        method: 'POST',
        body: body,
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }).catch((err) => console.log(err))
}

const updateEvent = async (eventProps: any, eventId: string) => {
    const body = JSON.stringify({
        ...eventProps,
        targetId: eventId,
        clientId: CLIENT_ID
    });
    const token: any = await auth.currentUser?.getIdToken();

    return await fetch(`${environment.apiUrl}/updateEvent`, {
        method: 'POST',
        body: body,
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }).catch((err) => console.log(err))
}

const turnToVod = async (targetId: string, eventId: string) => {
    const body = JSON.stringify({
        eventId: eventId,
        targetId: targetId,
        clientId: CLIENT_ID
    });
    const token: any = await auth.currentUser?.getIdToken();

    return await fetch(`${environment.apiUrl}/testMux`, {
        method: 'POST',
        body: body,
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }).catch((err) => console.log(err))
}

export const AdminService = {
    getUsers,
    getUser,
    createTicket,
    updateTicket,
    resetTicket,
    deleteTicket,

    createScheduleItem,
    updateScheduleItem,
    deleteScheduleItem,

    createExhibitor,
    updateExhibitor,
    deleteExhibitor,

    updateEvent,

    turnToVod
}
