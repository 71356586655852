import React from 'react';

interface Props {
    fullWidth?: boolean
    className?: string
    aic?: boolean
    jcc?: boolean
    wrap?: boolean
    margin?: boolean
    onClick?: (...args: any) => void
    onMouseDown?: (...args: any) => void
}

const UIRow: React.FC<Props> = ({ fullWidth, className = "", aic, jcc, wrap, margin, onClick, onMouseDown, children }) => {
    return (
        <div className={`ui__row${margin ? " margin" : ""} ${className}`} style={{
            width: fullWidth ? '100%' : 'inherit',
            alignItems: aic ? 'center' : 'inherit',
            justifyContent: jcc ? 'center' : 'inherit',
            flexWrap: wrap ? 'wrap' : 'inherit',
        }} onClick={onClick} onMouseDown={onMouseDown}>
            {children}
        </div>
    );
}

export default UIRow;
