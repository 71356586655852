import { IonInput, IonItem, IonLabel } from '@ionic/react';
import React from 'react';
import { Control, Controller, DeepMap, FieldError, ValidationValueMessage } from 'react-hook-form';
import { renderErrorMessage } from '../../functions';

interface Props {
    name: string
    control?: Control<any>
    errors: DeepMap<any, FieldError>
    errorField?: FieldError | undefined
    required?: boolean
    defaultValue?: string
    label?: string
    pattern?: RegExp | ValidationValueMessage<RegExp> | undefined
    type?: "number" | "time" | "text" | "search" | "date" | "email" | "password" | "tel" | "url" | "week" | "month" | "datetime-local" | undefined
}

const OldInputController: React.FC<Props> = ({ name, control, errors, errorField, required = false, defaultValue = "", label, pattern, type }) => {
    return (
        <Controller
            defaultValue={defaultValue}
            render={({ onChange, value }) => (
                <IonItem className="ui__item">
                    <IonLabel position="stacked" className="ui__label">
                        {label}{required ? " (*)" : null}
                        {errors[name] ? renderErrorMessage(errors, name) : null}
                    </IonLabel>
                    <IonInput type={type} value={value} onIonChange={onChange} />
                </IonItem>
            )}
            control={control}
            name={name}
            rules={{
                required: required,
                pattern: pattern
            }}
        />
    );
}

export default OldInputController;
