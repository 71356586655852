import React from 'react';
import { hexToRGB } from '../../functions';
import { EventProps } from '../../interfaces/IEvent';

interface Props {
  eventProps: EventProps
}

const EventRow: React.FC<Props> = ({ eventProps }) => {
  return (
    <div className="fc__event-row">
      <div className="logo">
        <img
          src={eventProps?.companyLogo}
          alt="Presentor logo"
          onClick={() => window.open(eventProps?.companyWebsite, '_blank')} />
      </div>
      <div className="bg" style={{ backgroundColor: eventProps?.eventColor && hexToRGB(eventProps?.eventColor, 0.1) }} />
    </div>
  );
}

export default EventRow;