import { DeleteChatRequestBody, Message, MessageAttacment, MessageComposer, SendChatRequestBody } from "../../interfaces/IChat";
import { DatabaseService } from "../database/database.service";
import { StorageService } from "../storage/storage.service";
import { auth } from "../../configs/firebase"
import { environment } from "../../configs/environment";
import { CLIENT_ID } from "../../configs/constants";

// ML for swear filter
require('@tensorflow/tfjs');
const toxicity = require('@tensorflow-models/toxicity');
const predictionTrustRequired = 0.3;
const Filter = require('bad-words'),
    filter = new Filter();

const createChatMessage = (
    text: string = "",
    name: string,
    attachments: FileList = null as any
): MessageComposer => {

    const message: MessageComposer = {
        name: name,
        text: text,
        state: 'sent',
        attachments
    }

    return message;
}

const sendChatMessage = async (metadata: SendChatRequestBody, composer: MessageComposer) => {
  
    const { text, name, state } = composer
    let attachments: MessageAttacment[] = [];
    const token = await auth.currentUser?.getIdToken();
   
    
    const message: Message = {
        text,
        name,
        state,
        attachments,
        userId: auth.currentUser?.uid as string
    }
   
    if (filter.isProfane(text)) {
        toxicity.load(predictionTrustRequired).then((model: { classify: (arg0: string) => Promise<any>; }) => {
            model.classify(message.text).then(async (predictions: any) => {
                if (predictions[0].results[0].match || predictions[1].results[0].match || predictions[2].results[0].match || predictions[3].results[0].match || predictions[4].results[0].match || predictions[5].results[0].match || predictions[6].results[0].match)
                    console.log('Text Flagged!');
                else {
                    /*
                      if (composer.attachments) {
                          const tempAttachments = await uploadAttachments(path, composer.attachments);
                          tempAttachments.forEach((attachment) => {
                              if (attachment)
                                  attachments.push(attachment);
                          })

                      }*/


                      console.log("the body ", {
                        metadata,
                        message,
                        clientId: CLIENT_ID
                    })
                    return await fetch(`${environment.apiUrl}/api/chat`, {
                        method: 'POST',
                        body: JSON.stringify({
                            metadata,
                            message,
                            clientId: CLIENT_ID
                        }),
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': token as string,
                        }
                    })

                    // return DatabaseService.add(path, message);
                }
            })
        })
    } else {

        console.log("the body ", {
            metadata,
            message,
            clientId: CLIENT_ID
        })

        return await fetch(`${environment.apiUrl}/api/chat`, {
            method: 'POST',
            body: JSON.stringify({
                metadata,
                message,
                clientId: CLIENT_ID,
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token as string,
            }
        })
        // return DatabaseService.add(path, message);
    }
}

const getMessages = (path: string, cb: (messages: Message[]) => void) => {
    return DatabaseService.getColR<Message>(path, (ref) => {
        return ref
            .where('state', '==', 'sent')
            .orderBy('createdAt', 'asc')
    }).subscribe(cb);
}

const getOldMessages = async (path: string) => {
    return DatabaseService.getCol<Message>(path, (ref) => {
        return ref
            .orderBy('createdAt', 'desc')
            .limit(20);
    }).then((messages) => messages.reverse());
}

const uploadAttachments = async (
    _path: string,
    files: FileList,
) => {
    return await Promise.all(Array.from(files).map(async (file) => {
        const fileStored = await StorageService.uploadFile(_path, file);
        if (!fileStored)
            return;
        return { url: fileStored.url, name: file.name, path: fileStored.path, type: file.type } as MessageAttacment
    }))
}

const deleteMessage = async (metadata: DeleteChatRequestBody) => {
    if (!metadata.messageId)
        return


    const token = await auth.currentUser?.getIdToken();
    return await fetch(`${environment.apiUrl}/api/chat`, {
        method: 'DELETE',
        body: JSON.stringify({
            metadata,
            clientId: CLIENT_ID
        }),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token as string,
        }
    })
    // return DatabaseService.update(data + "/" + messageId, { 'state': 'deleted' })
}

export const ChatService = {
    sendChatMessage,
    createChatMessage,
    getMessages,
    getOldMessages,
    deleteMessage
}
