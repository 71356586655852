import React from 'react';
import LayoutPage from '../../components/templates/LayoutPage';

const SponsorsPage: React.FC = () => {
    return (
        <LayoutPage
            columns={3}
            contentClass="sponsors__wrapper"

            header="Event sponsors"
            subHeader="Information about our sponsors"
        >
            <div className="ui__content">

            </div>
        </LayoutPage>
    );
}

export default SponsorsPage;
