import { IonItem, IonLabel, IonInput, IonIcon } from '@ionic/react';
import React, { useContext } from 'react';
import MixButton from '../../general/MixButton';
import MixText from '../../general/MixText';
import CopyIcon from '../../../assets/icons/ic-copy-to-clipboard.svg';
import { PopupContext } from '../../../reducers/PopupContext';
import { useHistory } from 'react-router';
import ManageScheduleItemForm from './ManageScheduleItemForm';
import { LocalizationContext } from '../../../reducers/LocalizationContext';
import TranslatedString from '../../general/TranslatedString';

interface Props {
    eventId: string
    input: any
}

const DisplayEventLink: React.FC<Props> = ({ eventId, input }) => {
    const { setModalContent, setModalCssClass, hideModal } = useContext(PopupContext);
    const { dictionary } = useContext(LocalizationContext);
    const history = useHistory();

    const path = `/my/${eventId}/watch/${input}`;

    return (
        <React.Fragment>
            <MixText>
                {typeof input === "string" ?
                    dictionary["admin-schedule_item-updated"] :
                    dictionary["admin-schedule_item-created"]}
            </MixText>
            <div className="ui__group">
                <IonItem className="ui__item">
                    <IonLabel position="fixed" slot="start" className="ui__label">
                        <TranslatedString tid="general_link" />:
                    </IonLabel>
                    <IonInput value={window.location.origin + path} readonly />
                </IonItem>
                <div className="btn" onClick={(e) => navigator.clipboard.writeText(window.location.origin + path)} onMouseDown={(e) => e.preventDefault()}>
                    <IonIcon className="icon-copy" src={CopyIcon} />
                    <div className="text">
                        <TranslatedString tid="general_copy-to-clipboard" />
                    </div>
                </div>
            </div>
            <div className="ion-margin-top">
                <MixButton onClick={() => {
                    hideModal();
                    setModalCssClass("");
                    history.push(path);
                }}>
                    <TranslatedString tid="admin-schedule_go-to-page" />
                </MixButton>
                <MixButton onClick={() => {
                    setModalContent(<ManageScheduleItemForm />);
                    setModalCssClass("add-schedule-item");
                }}>
                    <TranslatedString tid="admin-schedule_add-new-schedule-item" />
                </MixButton>
            </div>
        </React.Fragment>
    );
}

export default DisplayEventLink;
