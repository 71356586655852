import { IonIcon, IonSelect, IonSelectOption } from '@ionic/react';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';

import MixablLogo from '../../assets/logos/logo-mixabl-grayscale.svg';
import { AppContext } from '../../reducers/AppContext';
import { LocalizationContext } from '../../reducers/LocalizationContext';
import { languageOptions } from '../../translations';
import { commitHash } from "../../configs/environment";
import TranslatedString from "./TranslatedString";

const menuItems = [
    {
        name: 'Hivehub.xyz',
        link: 'https://hivehub.xyz',
        external: true
    },
    {
        name: 'Contact',
        link: ''
    },
];

const MixFooter: React.FC = () => {
    const history = useHistory();
    const { userLanguage, userLanguageChange } = useContext(LocalizationContext);
    const { appVersion, user } = useContext(AppContext);

    const handleLanguageChange = (e: any) => userLanguageChange(e);

    let langOptions: JSX.Element[] = [];
    Object.keys(languageOptions).forEach((key, index) =>
        langOptions.push(
            <IonSelectOption value={key} key={index}>{languageOptions[key]}</IonSelectOption>
        )
    );

    useEffect(() => {
        let defaultLanguage = window.localStorage.getItem('mixabl-lang');
        if (!defaultLanguage) {
            defaultLanguage = window.navigator.language.substring(0, 2);
        }
        userLanguageChange(defaultLanguage);
    }, [userLanguageChange]);

    return (
        <footer className="fc__footer">
            <div className="ui__container">
                <div className="info">
                    <div className="brand">
                        <IonIcon className="logo" src={MixablLogo} />
                        <div className="appVersion">
                            V{appVersion + ' - ' + commitHash}
                        </div>
                    </div>

                    <div className="legal">
                        <div className="ownership">
                            <TranslatedString tid='general_mixabl-product' />
                        </div>
                        <div className="ui__row links">
                            <a href="https://hivehub.xyz/"
                                rel="noopener noreferrer"
                                target="_blank">
                                hivehub.xyz
                            </a>
                        </div>
                        <div className="copyright">
                            &copy; <TranslatedString tid='general_rights-reserved' />
                        </div>
                    </div>
                </div>

                <div className="ui__col navigation">
                    <div className="menu">
                        <div className="item">
                            <IonSelect
                                placeholder="Language"
                                interface="popover"
                                value={userLanguage}
                                onIonChange={(e) => handleLanguageChange(e.detail.value)}>
                                {
                                    langOptions.map(o => o)
                                }
                            </IonSelect>
                        </div>
                        {
                            menuItems
                                .filter((o) => o.link === '/find-events' && user ? null : o)
                                .map((item, index) =>
                                    item.external ?
                                        <a className="item"
                                            href={item.link}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            key={index}>
                                            {item.name}
                                        </a>
                                        :
                                        <div className="item"
                                            key={index}
                                            onClick={() => history.push(item.link)}>
                                            {item.name}
                                        </div>
                                )
                        }
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default MixFooter;