import React, { useContext } from 'react';
import { AppContext } from '../../reducers/AppContext';

interface Props {
  level?: number
  centered?: boolean
  className?: string
  eventColor?: boolean
}

const Heading: React.FC<Props> = ({ children, level = 1, centered = false, eventColor = true, className = "" }) => {
  const color = useContext(AppContext)?.eventProps?.eventColor;

  return (
    <div
      className={`ui__h${level} ${centered && 'ion-text-center'} ${className}`}
      style={(eventColor && color) ? { color: color } : {}}>
      {children}
    </div>
  );
}

export default Heading;
