import React, { useContext, useEffect, useState } from 'react';
import { hexToRGB, mutateColor } from '../../functions';
import { SendChatRequestBody } from '../../interfaces/IChat';
import { AppContext } from '../../reducers/AppContext';
import TranslatedString from '../general/TranslatedString';
import Chat from './chat';

const GeneralChat: React.FC = () => {
    const { eventId, pageChat, eventProps, chatCollapsed, setChatCollapsed, user } = useContext(AppContext);
    const [globalChat, setGlobalChat] = useState<SendChatRequestBody>({ eventId, type: "general", });
    const [globalIsFocus, setGlobalIsFocus] = useState<boolean>(true);

    useEffect(() => {
        if (eventId) {
            setGlobalChat({ eventId, type: "general" });
        } else {
            setGlobalChat(null as any);
        }
    }, [eventId]);

    useEffect(() => {
        if (pageChat) {
            setGlobalIsFocus(false);
        } else {
            setGlobalIsFocus(true);
        }
    }, [pageChat]);

    const onFolderClick = (isGlobal: boolean) => {
        if (chatCollapsed) {
            if (isGlobal) {
                setGlobalIsFocus(true);
            } else {
                setGlobalIsFocus(false);
            }
            setChatCollapsed(false);
        } else {
            if (isGlobal) {
                if (globalIsFocus) {
                    setChatCollapsed(true);
                } else {
                    setGlobalIsFocus(true);
                }
            } else {
                if (globalIsFocus) {
                    setGlobalIsFocus(false);
                } else {
                    setChatCollapsed(true);
                }
            }
        }
    }

    return user ? (
        <div
            className={`general_chat_frame${chatCollapsed ? " closed" : ""}`}
            style={eventProps?.eventColor ? { borderTopColor: mutateColor(eventProps.eventColor, 14) } : {}}>
            <div className="general_chat_folders">
                <div
                    className={`general_chat_folder${globalIsFocus ? " open" : ""}`}
                    onClick={() => onFolderClick(true)}
                    style={(eventProps?.eventColor && globalIsFocus) ? { backgroundColor: eventProps.eventColor } : {}}>
                    <div className="ui__col">
                        <div className="mixabl">Mixabl</div>
                        <div className="ui__row">
                            <div className="name">
                                <TranslatedString tid="generalChat_event" />
                            </div>
                            <div className="chat">
                                <TranslatedString tid="generalChat_chat" />
                            </div>
                        </div>
                    </div>
                </div>
                {
                    pageChat &&
                    (
                        <div
                            className={`general_chat_folder${globalIsFocus ? "" : " open"}`}
                            onClick={() => onFolderClick(false)}
                            style={(eventProps?.eventColor && !globalIsFocus) ? { backgroundColor: eventProps.eventColor } : {}}>
                            <div className="ui__col">
                                <div className="mixabl">Mixabl</div>
                                <div className="ui__row">
                                    <div className="name">
                                        {pageChat.name}
                                    </div>
                                    <div className="chat">
                                        <TranslatedString tid="generalChat_chat" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
            <div className="general_chat_body">
                {
                    !chatCollapsed &&
                    <div className="general_chat_chat"
                        style={eventProps?.eventColor ? { borderColor: hexToRGB(eventProps.eventColor, 0.3) } : {}}>
                        <Chat
                            data={globalIsFocus ? globalChat : pageChat?.data}
                            chatEnabled={globalIsFocus ? true : pageChat?.chatEnabled}
                        />
                    </div>
                }
            </div>
        </div>
    ) : null;
}

export default GeneralChat;
