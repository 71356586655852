import React from 'react';

const TableRow: React.FC = ({ children }) => {
    return (
        <tr className="table__row">
            {children}
        </tr>
    );
}

export default TableRow;
