import React, { useContext, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import {
    IonAlert,
    IonIcon,
    IonLabel,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonToast
} from '@ionic/react';
import dayjs from 'dayjs';

import EventHomePage from './pages/home/EventHomePage';
import WatchPage from './pages/schedule/WatchPage';
import EventSchedulePage from './pages/schedule/SchedulePage';
import VirtualBoothPage from './pages/schedule/VirtualBoothPage';
import ExhibitorsDirectoryPage from './pages/exhibitors/ExhibitorsDirectoryPage';
import ExhibitorProfilePage from './pages/exhibitors/ExhibitorProfilePage';
import VideoChatPage from './pages/meeting/VideoChatPage';

import LogoutIcon from './assets/icons/ic-account-logout.svg';
import ScheduleIcon from './assets/icons/ic-schedule.svg';
import MyScheduleIcon from './assets/icons/ic-my-schedule.svg';
import ExhibitorsIcon from './assets/icons/ic-exhibitors.svg';
import AssistanceIcon from './assets/icons/ic-assistance-old.svg';
import { AppContext } from './reducers/AppContext';
import { hexToRGB } from './functions';
import { UserService } from './services/user/user.service';
import { AnalyticsPreference } from './interfaces/IUser';
import { ShowNotification } from './interfaces/IMeeting';
import HelpPage from './pages/help/HelpPage';
import SponsorsPage from './pages/sponsors/SponsorsPage';
import TicketManagementPage from './pages/admin/TicketManagementPage';
import ScheduleManagementPage from './pages/admin/ScheduleManagementPage';
import EventSettingsPage from './pages/admin/EventSettingsPage';
import ExhibitorsManagementPage from './pages/admin/ExhibitorsManagementPage';
import AnalyticsPage from './pages/admin/AnalyticsPage';
import { LocalizationContext } from './reducers/LocalizationContext';

const AppTabs: React.FC = () => {
    const { eventId, analyticsPrompt, setAnalyticsPrompt, user, nextAppointment, showNotif, modifyShowNotif } = useContext(AppContext);
    const { dictionary } = useContext(LocalizationContext);
    const eventColor = useContext(AppContext).eventProps?.eventColor;

    const [logOutAlert, setLogOutAlert] = useState(false);

    const tabStyles = {
        '--color-selected': eventColor ? hexToRGB(eventColor, 0.5) : '#000',
        color: eventColor ? eventColor : '#000'
    }

    const history = useHistory();

    return (
        <>
            <IonTabs>
                <IonRouterOutlet style={{}}>
                    <Switch>
                        <Route path="/my/:eventId/home" component={EventHomePage} exact />
                        <Route path="/my/:eventId/schedule" component={EventSchedulePage} exact />
                        <Route path="/my/:eventId/watch/:streamId" component={WatchPage} exact />
                        <Route path="/my/:eventId/booth/:boothId" component={VirtualBoothPage} exact />
                        <Route path="/my/:eventId/sponsors" component={SponsorsPage} exact />
                        <Route path="/my/:eventId/exhibitors" component={ExhibitorsDirectoryPage} exact />
                        <Route path="/my/:eventId/exhibitors/:exhibitorId/schedule-meeting"
                            component={ExhibitorProfilePage} exact />
                        <Route path="/my/:eventId/exhibitors/:exhibitorId/appointment/:meetingId" exact
                            component={VideoChatPage} />
                        <Route path="/my/:eventId/help" component={HelpPage} exact />

                        <Route path="/admin/:eventId/general" component={EventSettingsPage} exact />
                        <Route path="/admin/:eventId/tickets" component={TicketManagementPage} exact />
                        <Route path="/admin/:eventId/schedule" component={ScheduleManagementPage} exact />
                        <Route path="/admin/:eventId/exhibitors" component={ExhibitorsManagementPage} exact />
                        {/* <Route path="/admin/:eventId/sponsors" component={SponsorsManagementPage} exact /> */}
                        <Route path="/admin/:eventId/analytics" component={AnalyticsPage} exact />
                    </Switch>
                </IonRouterOutlet>
                <IonTabBar slot="bottom" style={{ borderTopColor: eventColor ? hexToRGB(eventColor, 0.3) : '#000' }}>
                    <IonTabButton tab="schedule"
                        href={`/my/${eventId}/schedule`}
                        style={tabStyles}>
                        <IonIcon icon={ScheduleIcon} size="small" />
                        <IonLabel>Programme</IonLabel>
                    </IonTabButton>
                    <IonTabButton tab="exhibitors"
                        href={`/my/${eventId}/exhibitors`}
                        style={tabStyles}>
                        <IonIcon icon={ExhibitorsIcon} size="small" />
                        <IonLabel>Exposants</IonLabel>
                    </IonTabButton>
                    <IonTabButton tab="meetings"
                        href={`/my/${eventId}/meeting-schedule`}
                        style={tabStyles}>
                        <IonIcon icon={MyScheduleIcon} size="small" />
                        <IonLabel>Mes réunions</IonLabel>
                    </IonTabButton>
                    <IonTabButton
                        style={tabStyles}>
                        <a style={{ color: eventColor ? eventColor : '#000', textDecoration: 'none' }}
                            className="custom-btn"
                            href="https://cosior.com/soutien-technique/"
                            target="_blank"
                            rel="noopener noreferrer">
                            <IonIcon icon={AssistanceIcon} size="small" />
                            <IonLabel>Aide</IonLabel>
                        </a>
                    </IonTabButton>
                    <IonTabButton style={tabStyles}>
                        <div className="custom-btn" onClick={() => setLogOutAlert(true)}>
                            <IonIcon icon={LogoutIcon} size="small" />
                            <IonLabel>Déconnexion</IonLabel>
                        </div>
                    </IonTabButton>
                </IonTabBar>
            </IonTabs>

            <IonToast
                isOpen={analyticsPrompt}
                onDidDismiss={() => setAnalyticsPrompt(false)}
                header={dictionary["general_cookies-header"]}
                message={dictionary["general_cookies-message"]}
                position="top"
                cssClass="cookie-toast"
                translucent
                buttons={[
                    {
                        text: dictionary["general_accept"],
                        role: '',
                        handler: () => {
                            UserService.setAnalyticsPreference(eventId, AnalyticsPreference.Yes)
                        }
                    },
                    {
                        text: dictionary["general_refuse"],
                        role: 'cancel',
                        handler: () => {
                            UserService.setAnalyticsPreference(eventId, AnalyticsPreference.No)
                        }
                    }
                ]}
            />

            {nextAppointment && <IonToast
                isOpen={showNotif === ShowNotification.SHOW || showNotif === ShowNotification.SHOWLASTMINUTE}
                onDidDismiss={() => modifyShowNotif(showNotif ? ShowNotification.SEENLASTMINUTE : ShowNotification.SEENNOTIF)}
                message={`${dictionary["general_meeting-starts-in"]} ${dayjs(nextAppointment.time, 'DDMMYYYY-HHmm').diff(dayjs(), 'minute') + 1} ${dictionary["general_meeting-minutes"]}.`}
                position="top"
                header={dictionary["admin-exhibitor_meeting"]}
                cssClass="cookie-toast"
                translucent
                buttons={showNotif === ShowNotification.SHOWLASTMINUTE ? [
                    {
                        text: dictionary["general_join-meeting"],
                        role: '',
                        handler: () => {
                            modifyShowNotif(ShowNotification.SEENLASTMINUTE)
                            history.replace(`/my/${eventId}/exhibitors/${nextAppointment.exhibitorId}/appointment/${nextAppointment.id}`)
                        }
                    },
                    {
                        text: dictionary["general_close"],
                        role: 'cancel',
                        handler: () => {
                            modifyShowNotif(ShowNotification.SEENLASTMINUTE)
                        }
                    }
                ] : [
                        {
                            text: dictionary["general_close"],
                            role: 'cancel',
                            handler: () => {
                                modifyShowNotif(ShowNotification.SEENNOTIF)
                            }
                        }
                    ]}
            />}

            {showNotif === ShowNotification.NEWMEETING && <IonToast
                isOpen={showNotif === ShowNotification.NEWMEETING}
                onDidDismiss={() => modifyShowNotif(ShowNotification.NOSHOW)}
                message={dictionary["general_new-meeting-notification"]}
                position="top"
                header={dictionary["admin-exhibitor_meeting"]}
                cssClass="cookie-toast"
                translucent
                buttons={[
                    {
                        text: dictionary["general_close"],
                        role: 'cancel',
                        handler: () => {
                            modifyShowNotif(ShowNotification.NOSHOW)
                        }
                    }
                ]}
            />}

            <IonAlert
                isOpen={logOutAlert}
                onDidDismiss={() => setLogOutAlert(false)}
                header={dictionary["general_logout"]}
                message={dictionary["general_logout-prompt"]}
                buttons={[
                    {
                        text: dictionary["general_cancel"],
                        role: 'cancel',
                    },
                    {
                        text: dictionary["general_logout"],
                        cssClass: 'logout-confirm',
                        handler: () => UserService.logOut().then(() => history.replace('/find-events'))
                    }
                ]}
            />
        </>
    );
};

export default AppTabs;
