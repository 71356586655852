import React, { useRef } from 'react';
import { getDataUrl } from '../../utils/file';

interface Props {
    onSelected: (data: any) => void;
    render: (onPress: () => void) => JSX.Element;
    accept: string | undefined;
    multiple?: boolean;
}


export default function FilePicker({
    onSelected,
    render,
    accept,
    multiple
}: Props) {

    const inputRef = useRef(null);


    const onChange = async ({ target }: any) => {
        const filelists: FileList = target.files


        let url = "";
        let file = filelists.item(0);
        if (file && file.type.match(/images?\/*/)) {
            url = await getDataUrl(file);
        }

        onSelected({ url, files: filelists });
    }

    const onPick = () => {
        if (inputRef.current !== null) {
            (inputRef?.current as any).click();
        }
    }


    return (
        <>
            {render(onPick)}
            <input
                multiple={multiple}
                type="file"
                ref={inputRef}
                accept={accept}
                style={{ display: 'none' }}
                onChange={onChange}
            />
        </>
    )
}
