import { IonIcon, IonInput } from '@ionic/react';
import React from 'react';
import { EventProps } from '../../interfaces/IEvent';
import Heading from '../general/Heading';
import MixButton from '../general/MixButton';
import ErrorMessage from './ErrorMessage';
import TranslatedString from '../general/TranslatedString';
import BackButton from '../general/BackButton';
import Placeholder from '../../assets/headerImg_placeholder.jpg';
import PhoneIcon from '../../assets/icons/ic-phone.svg';
import EmailIcon from '../../assets/icons/ic-email.svg';
import dayjs from 'dayjs';
import { useHistory } from 'react-router';

interface Props {
  eventProps: EventProps
  userNip: string
  setUserNip: React.Dispatch<React.SetStateAction<string>>
  loginError: number
  setEventCode: React.Dispatch<React.SetStateAction<string>>
  setEventProps: React.Dispatch<React.SetStateAction<EventProps>>
  codeConfirm: string
  setCodeConfirm: React.Dispatch<React.SetStateAction<string>>
  waitingForCode: boolean
}

const EnterNipPrompt: React.FC<Props> = ({ eventProps, userNip, setUserNip, loginError, setEventCode, setEventProps, codeConfirm, setCodeConfirm, waitingForCode }) => {
  const history = useHistory();

  return (
    <div className="enter-nip">
      {/* <EventRow eventProps={eventProps} /> */}
      <div className="event-logo" style={eventProps?.eventColor ? { borderColor: eventProps.eventColor } : {}}>
        <div className="image">
          {
            (
              <img src={eventProps?.headerImg?.url ? eventProps.headerImg.url : Placeholder} alt="" />
            )
          }
        </div>
        <div className="content">
          <div className="meta">
            <div className="time">{dayjs(eventProps?.eventDates[0]).format("D MMMM YYYY")}</div>
            <div className="location">{eventProps?.eventLocation}</div>
          </div>
          <div className="contacts">
            <div className="phone">
              <IonIcon src={PhoneIcon} />
              <div className="text">
                <a href={`tel:${eventProps?.eventPhone}`}>
                  {eventProps?.eventPhone}
                </a>
              </div>
            </div>
            <div className="email">
              <IonIcon src={EmailIcon} />
              <div className="text">
                <a href={`mailto:${eventProps?.eventEmail}`}>
                  {eventProps?.eventEmail}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ui__group">
        <div className="login__join-form">
          <BackButton onClick={() => history.replace('/find-events')} />
          <Heading level={3}>
            <TranslatedString tid="login_join-now" />
          </Heading>
          <IonInput className="ui__input nip-input"
            placeholder={eventProps?.authMethod === "phoneAuth" ? "# de téléphone" : "NIP"}
            type={eventProps?.authMethod === "phoneAuth" ? "text" : "password"}
            value={userNip}
            onIonChange={(e) => setUserNip(e.detail.value!)}
            disabled={eventProps && eventProps.authMethod === "phoneAuth" && waitingForCode}
          />
          {eventProps && eventProps.authMethod === "phoneAuth" && waitingForCode && <IonInput className="ui__input nip-input"
            placeholder="Code de confirmation"
            type="text"
            value={codeConfirm}
            onIonChange={(e) => setCodeConfirm(e.detail.value!)} />}
          <MixButton eventProps={eventProps} submit>
            <TranslatedString tid="login_login-btn" />
          </MixButton>
          <ErrorMessage loginError={loginError} />
          <div className="ion-text-center ion-padding-bottom">
            <a href="tel:15142774544"
              style={{ color: eventProps?.eventColor }}>
              <TranslatedString tid="login_dont-have-ticket" />
            </a>
          </div>
          <div className="ion-text-center ion-padding-bottom">
            <a href="https://cosior.com/soutien-technique/"
              style={{ color: eventProps?.eventColor }}
              target="_blank" rel="noopener noreferrer">
              <TranslatedString tid="login_technical-difficulties" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnterNipPrompt;
