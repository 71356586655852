import { IonImg } from '@ionic/react';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { hexToRGB } from '../../functions';
import { Exhibitor } from '../../interfaces/IExhibitor';
import { AppContext } from '../../reducers/AppContext';
import BronzeExhibitorCard from './BronzeExhibitorCard';
import GoldExhibitorCard from './GoldExhibitorCard';
import PartnerCard from './PartnerCard';
import SilverExhibitorCard from './SilverExhibitorCard';

interface Props {
    goldExhibitors?: Exhibitor[]
    silverExhibitors?: Exhibitor[]
    bronzeExhibitors?: Exhibitor[]
    otherExhibitors?: Exhibitor[]
    partners?: Exhibitor[]

    narrow?: boolean
}

const ExhibitorsList: React.FC<Props> = ({ goldExhibitors, silverExhibitors, bronzeExhibitors, partners, otherExhibitors, narrow = false }) => {
    const { eventProps, eventId } = useContext(AppContext);
    const history = useHistory();

    return (
        <div className={`fc__exhibitors-list${narrow ? " narrow" : ""}`}>
            <div className={narrow ? "scrollable" : ""}>
                <div style={eventProps?.eventColor ? { borderColor: hexToRGB(eventProps.eventColor, 0.3) } : {}}
                    className="gold">
                    {
                        goldExhibitors?.map((exhibitor, index) => (
                            <GoldExhibitorCard
                                key={index}
                                exhibitor={exhibitor}
                                narrow={narrow} />
                        ))
                    }
                </div>
                <div className="silver">
                    {
                        silverExhibitors?.map((exhibitor, index) => (
                            <SilverExhibitorCard
                                fullWidth={narrow}
                                key={index}
                                exhibitor={exhibitor} />
                        ))
                    }
                </div>
                <div className="bronze">
                    {
                        bronzeExhibitors?.map((exhibitor, index) => (
                            <BronzeExhibitorCard
                                fullWidth={narrow}
                                key={index}
                                exhibitor={exhibitor} />
                        ))
                    }
                </div>
                <div className="partners">
                    {
                        partners?.map((exhibitor, index) => (
                            <PartnerCard
                                fullWidth={narrow}
                                key={index}
                                exhibitor={exhibitor} />
                        ))
                    }
                </div>

                {
                    otherExhibitors && otherExhibitors
                        .sort((a, b) => {
                            if (a.name < b.name) return -1;
                            if (a.name > b.name) return 1;
                            return 0;
                        })
                        .map((exhibitor, index) => (
                            <div key={index}
                                onClick={() => history.push(`/my/${eventId}/exhibitors/${exhibitor.id}/schedule-meeting`)}
                                className="exhibitor">
                                <div className="logo">
                                    <IonImg src={exhibitor.bannerImg?.url} />
                                </div>
                                { !narrow &&
                                    <div className="meta">
                                        {exhibitor?.description && <div className="description">{exhibitor?.description?.substr(0, 120)} ...</div>}
                                        {exhibitor?.cta && <div className="cta">{exhibitor.cta}</div>}
                                    </div>
                                }
                            </div>
                        ))
                }
            </div>
        </div>
    );
}

export default ExhibitorsList;
