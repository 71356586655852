import { IonIcon, IonLoading } from '@ionic/react';
import React, { useContext } from 'react';
import Heading from '../../general/Heading';
import Table from '../../table/Table';
import TableBody from '../../table/TableBody';
import TableCell from '../../table/TableCell';
import TableHead from '../../table/TableHead';
import TableHeaderCell from '../../table/TableHeaderCell';
import TableRow from '../../table/TableRow';
import EditIcon from '../../../assets/icons/ic-admin-btn-edit.svg';
import DeleteIcon from '../../../assets/icons/ic-close.svg';
import { ScheduleItem } from '../../../interfaces/ISchedule';
import dayjs from 'dayjs';
import { PopupContext } from '../../../reducers/PopupContext';
import ManageScheduleItemForm from './ManageScheduleItemForm';
import { AppContext } from '../../../reducers/AppContext';
import { AdminService } from '../../../services/admin/admin.service';
import AddScheduleItem from './AddScheduleItem';
import TranslatedString from '../../general/TranslatedString';
import { LocalizationContext } from '../../../reducers/LocalizationContext';
import { ScheduleService } from '../../../services/schedule/schedule.service';

interface Props {
    scheduleItems?: ScheduleItem[]
}

const ScheduleTable: React.FC<Props> = ({ scheduleItems }) => {
    const { setLoading, eventId } = useContext(AppContext);
    const { setAlertProps, showAlert, setModalContent, setModalCssClass, showModal, setToastProps } = useContext(PopupContext);
    const { dictionary } = useContext(LocalizationContext);

    const deleteItem = (scheduleItem: ScheduleItem) => {
        setAlertProps({
            header: dictionary["general_delete-item"],
            subHeader: scheduleItem?.name,
            message: dictionary["admin-schedule_delete-item"],
            buttons: [
                {
                    text: dictionary["general_cancel"],
                    role: 'cancel'
                },
                {
                    text: dictionary["general_delete"],
                    cssClass: 'danger',
                    handler: () => {
                        setLoading(true);
                        if (scheduleItem.id) {
                            const name = scheduleItem?.name;
                            AdminService.deleteScheduleItem(scheduleItem.id, eventId).then(() => {
                                setLoading(false);
                                setToastProps({ isOpen: true, message: `${dictionary["admin-schedule_deleted"]} - ${name}`, duration: 3000, position: "bottom" });
                            });
                        } else {
                            setLoading(false);
                            setToastProps({ isOpen: true, message: dictionary["general_error"], duration: 1000, position: "bottom" });
                        }
                    }
                }
            ]
        });
        showAlert();
    }

    const editItem = (scheduleItem: ScheduleItem) => {
        setModalCssClass("add-schedule-item");
        setModalContent(<ManageScheduleItemForm scheduleItem={scheduleItem} />);
        showModal();
    }

    return (
        <div className="admin__schedule-table">
            <div className="header">
                <Heading>
                    {dictionary['general_admin-page']}: <TranslatedString tid="admin-schedule_heading" />
                </Heading>
                <AddScheduleItem />
            </div>
            <div className="content">
                <Table className="schedule-table">
                    <TableHead>
                        <TableRow>
                            <TableHeaderCell align="center">
                                <TranslatedString tid="admin-schedule_thumbnail" />
                            </TableHeaderCell>
                            <TableHeaderCell>
                                <TranslatedString tid="admin-schedule_item-type" />
                            </TableHeaderCell>
                            <TableHeaderCell>
                                <TranslatedString tid="admin-schedule_item-title" />
                            </TableHeaderCell>
                            {/* <TableHeaderCell>
                                <TranslatedString tid="admin-schedule_location" />
                            </TableHeaderCell> */}
                            <TableHeaderCell>
                                <TranslatedString tid="admin-schedule_date" />
                            </TableHeaderCell>
                            <TableHeaderCell>
                                <TranslatedString tid="admin-schedule_live-time" />
                            </TableHeaderCell>
                            <TableHeaderCell>
                                <TranslatedString tid="admin-schedule_end-time" />
                            </TableHeaderCell>
                            {/* <TableHeaderCell>
                                <TranslatedString tid="admin-schedule_presenters" />
                            </TableHeaderCell> */}
                            <TableHeaderCell align="center">
                                <TranslatedString tid="general_edit" />
                            </TableHeaderCell>
                            <TableHeaderCell align="center">
                                <TranslatedString tid="general_delete" />
                            </TableHeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            scheduleItems ? scheduleItems
                                .map((scheduleItem, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="center">
                                            {scheduleItem?.thumbnail?.url ? (
                                                <img src={scheduleItem.thumbnail.url} className="thumbnail-img" alt={`${scheduleItem?.name} thumbnail`} />
                                            ) : null}
                                        </TableCell>
                                        <TableCell>
                                            {scheduleItem?.type === 'stream' ? dictionary["event-schedule_category-stream"] : null}
                                            {scheduleItem?.type === 'vod' ? dictionary["event-schedule_category-vod"] : null}
                                            {scheduleItem?.type === 'general' ? dictionary["event-schedule_category-general"] : null}
                                        </TableCell>
                                        <TableCell>
                                            {scheduleItem?.name?.length > 35 ? scheduleItem.name.substr(0, 32) + '...' : scheduleItem.name}
                                        </TableCell>
                                        {/* <TableCell>
                                            {scheduleItem?.location?.length > 35 ? scheduleItem.location.substr(0, 32) + '...' : scheduleItem.location}
                                        </TableCell> */}
                                        <TableCell>
                                            {dayjs(scheduleItem?.startsAt, 'MM/DD/YYYY HH:mm').format('DD MMM YY')}
                                        </TableCell>
                                        <TableCell>
                                            {dayjs(scheduleItem?.startsAt, 'MM/DD/YYYY HH:mm').format('HH:mm')}
                                        </TableCell>
                                        <TableCell>
                                            {dayjs(scheduleItem?.endsAt, 'MM/DD/YYYY HH:mm').format('HH:mm')}
                                        </TableCell>
                                        {/* <TableCell>
                                            {
                                                scheduleItem?.presenters && scheduleItem.presenters.map(ScheduleService.getPresenterDisplayName).join(', ').substr(0, 32) + '...'
                                            }
                                        </TableCell> */}
                                        <TableCell align="center">
                                            <IonIcon
                                                className="btn-edit"
                                                src={EditIcon}
                                                onMouseDown={(e) => e.preventDefault()}
                                                onClick={() => editItem(scheduleItem)} />
                                        </TableCell>
                                        <TableCell align="center">
                                            <IonIcon
                                                className="btn-delete"
                                                src={DeleteIcon}
                                                onMouseDown={(e) => e.preventDefault()}
                                                onClick={() => deleteItem(scheduleItem)} />
                                        </TableCell>
                                    </TableRow>
                                )) : <IonLoading isOpen />
                        }

                    </TableBody>
                </Table>
            </div>
        </div>
    );
}

export default ScheduleTable;
