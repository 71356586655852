import React, { useEffect, useState, useContext, useCallback, useRef } from 'react';
import { Exhibitor, ExhibitorVoiceChat } from '../../interfaces/IExhibitor';
import { AppContext } from '../../reducers/AppContext';
import { ExhibitorService } from '../../services/exhibitor/exhibitor.service';
import { IconButton, Switch } from '@material-ui/core';
import { VideoChatService, VideoChatRoom } from '../../services/videoChat/videoChat.service';
import MixButton from '../general/MixButton';
import MixText from '../general/MixText';
import { LocalizationContext } from "../../reducers/LocalizationContext";
import TranslatedString from "../general/TranslatedString";
import dayjs from 'dayjs';
import { Beforeunload } from 'react-beforeunload';
import { CallEnd, Mic, MicOff } from '@material-ui/icons';

const Sound = require('react-sound').default;
const joinSound = require('../../assets/sounds/JoinCall.mp3')
const leaveSound = require('../../assets/sounds/LeaveCall.mp3')

const muteButton = (muteState: boolean, room: any, cb: () => void) => {
    return !muteState ? <IconButton className="appointment__onOffBtn" onClick={() => {
        if (room) {
            VideoChatService.muteAudio(room)
            cb();
        }
    }}><Mic /></IconButton> : <IconButton className="appointment__onOffBtn" onClick={() => {
        if (room) {
            VideoChatService.unMuteAudio(room)
            cb();
        }
    }}><MicOff /></IconButton>
}

interface VoiceChatProps {
    exhibitor: Exhibitor
}

const VoiceChat: React.FC<VoiceChatProps> = ({ exhibitor }) => {
    const { user, eventId } = useContext(AppContext);
    const { dictionary } = useContext(LocalizationContext);

    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [voiceChatState, setVoiceChatState] = useState<"available" | "busy" | "not available">("not available");
    const [voiceChat, setVoiceChat] = useState<ExhibitorVoiceChat | null>(null);
    const [token, setToken] = useState<string>("");
    const [room, setRoom] = useState<VideoChatRoom | null>(null);
    const [participants, setParticipants] = useState<any[]>([]);
    const [audioTracks, setAudioTracks] = useState<any[]>([]);
    // const [gotBothParticipants, setGotBothParticipants] = useState<boolean>(false);
    const [soundStatus, setSoundStatus] = useState<any>(Sound.status.STOPPED);
    const [soundType, setSoundType] = useState<any>(joinSound);
    const [muted, setMuted] = useState<boolean>(false);

    const audioRef = useRef<any>();

    const trackpubsToTracks = (trackMap: any[]) => Array.from(trackMap.values())
        .map(publication => publication.track)
        .filter(track => track !== null);

    useEffect(() => {
        participants.forEach((participant) => {
            const trackSubscribed = (track: any) => {
                if (track.kind === 'audio') {
                    setAudioTracks(audioTracks => [...audioTracks, track]);
                }
            };

            const trackUnsubscribed = (track: any) => {
                if (track.kind === 'audio') {
                    setAudioTracks(audioTracks => audioTracks.filter(a => a !== track));
                }
            };
            setAudioTracks(trackpubsToTracks(participant.audioTracks));

            participant.on('trackSubscribed', trackSubscribed);
            participant.on('trackUnsubscribed', trackUnsubscribed);
        })

        return () => {
            setAudioTracks([]);
            participants.forEach((participant) => {
                participant.removeAllListeners();
            })
        };
    }, [participants]);

    useEffect(() => {
        const audioTrack = audioTracks[0];
        if (audioTrack) {
            audioTrack.attach(audioRef.current);
            return () => {
                audioTrack.detach();
            };
        }
    }, [audioTracks]);

    useEffect(() => {
        if (exhibitor && exhibitor.id) {
            return ExhibitorService.getExhibitorR(eventId, exhibitor.id, (data) => {
                if (data.voiceChat) {
                    setVoiceChatState(data.voiceChat);
                } else {
                    setVoiceChatState("not available");
                }
            })
        } else {
            setVoiceChatState("not available");
        }
    }, [exhibitor])

    useEffect(() => {
        return () => {
            if (exhibitor && exhibitor.id && voiceChat?.id) {
                ExhibitorService.updateVoiceChat(eventId, exhibitor.id, voiceChat.id, {
                    state: "closed",
                    roomName: voiceChat.roomName
                }).then(() => {
                    setVoiceChat(null);
                    setToken("");
                });
            }
        };
    }, [exhibitor, voiceChat])

    useEffect(() => {
        return () => {
            if (exhibitor && exhibitor.id && isAdmin) {
                ExhibitorService.updateVoiceChatAvailability(eventId, exhibitor.id, "not available");
            }
        }
    }, [exhibitor, isAdmin])

    useEffect(() => {
        if (user && exhibitor && exhibitor.id && exhibitor.admins?.includes(user.id)) {
            setIsAdmin(true);
            return ExhibitorService.getVoiceChats(eventId, exhibitor.id, (voiceChats) => {
                if (voiceChats && voiceChats.length > 0) {
                    VideoChatService.getRoomToken(user, voiceChats[0].roomName).then((token) => {
                        setToken(token);
                        setVoiceChat(voiceChats[0]);
                        if (exhibitor.id) {
                            ExhibitorService.updateVoiceChatAvailability(eventId, exhibitor.id, "busy");
                            if (voiceChats[0].id) {
                                ExhibitorService.updateVoiceChat(eventId, exhibitor.id, voiceChats[0].id, {
                                    state: "ongoing",
                                    roomName: voiceChats[0].roomName
                                });
                            }
                        }
                    });
                }
            })
        } else {
            setIsAdmin(false);
        }
    }, [user, exhibitor])

    useEffect(() => {
        if (token && voiceChat?.roomName) {
            VideoChatService.connectToVoiceRoom(voiceChat.roomName, token).then(({
                room,
                onParticipantConnected,
                onParticipantDisConnected
            }) => {
                setRoom(room);

                onParticipantConnected((participant) => {
                    setSoundType(joinSound);
                    setSoundStatus(Sound.status.PLAYING);
                    setParticipants(prevParticipants => [...prevParticipants, participant]);
                })
                onParticipantDisConnected((participant) => {
                    setSoundType(leaveSound);
                    setSoundStatus(Sound.status.PLAYING);
                    setParticipants(prevParticipants =>
                        prevParticipants.filter(p => p !== participant)
                    );
                    disconnect();
                })
            })
            return disConnectFromRoom;
        }
    }, [token, voiceChat, isAdmin])

    useEffect(() => {
        return () => {
            if (room) {
                VideoChatService.disconnectFromRoom(room);
            }
        }
    }, [room])

    const disConnectFromRoom = useCallback(() => {
        setRoom(currentRoom => {
            if (currentRoom && currentRoom.localParticipant.state === 'connected') {
                setSoundType(leaveSound);
                setSoundStatus(Sound.status.PLAYING);
                VideoChatService.disconnectFromRoom(currentRoom);
                return null;
            } else {
                return currentRoom;
            }
        });
    }, [setRoom])

    const onStateChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (exhibitor.id) {
            ExhibitorService.updateVoiceChatAvailability(eventId, exhibitor.id, checked ? "available" : "not available")
        }
    }

    const connect = () => {
        if (exhibitor.id && !voiceChat && voiceChatState === "available") {
            const chatRoom: ExhibitorVoiceChat = {
                roomName: `exhibitor_voiceChat_${exhibitor.id}_${user.id}_${dayjs().millisecond()}`,
                state: "opened"
            }
            VideoChatService.getRoomToken(user, chatRoom.roomName).then((token) => {
                if (exhibitor.id) {
                    ExhibitorService.addVoiceChat(eventId, exhibitor.id, chatRoom).then((data) => {
                        chatRoom.id = data.id;
                        setToken(token);
                        setVoiceChat(chatRoom);
                    });
                }
            });
        }
    }

    const disconnect = () => {
        if (exhibitor) {
            if (exhibitor.id && isAdmin) {
                ExhibitorService.updateVoiceChatAvailability(eventId, exhibitor.id, "not available");
            }
            if (exhibitor.id && voiceChat?.id) {
                ExhibitorService.updateVoiceChat(eventId, exhibitor.id, voiceChat.id, {
                    state: "closed",
                    roomName: voiceChat.roomName
                }).then(() => {
                    setVoiceChat(null);
                    setToken("");
                });
            }
        }
    }

    return <div className="voiceChat_container">
        <div className="voiceChat_body">
            {isAdmin &&
                <div className="voiceChat_exhibitor_switch">
                    <TranslatedString tid='voice-chat_disable' />
                    <Switch checked={voiceChatState === "available"} onChange={onStateChange} />
                    <TranslatedString tid='voice-chat_enable' />
                </div>
            }
            <div className="voiceChat_button">
                {isAdmin && voiceChatState === "available" &&
                    <MixButton onClick={disconnect} color={voiceChat ? undefined : "primary"}
                        disabled={!voiceChat}><TranslatedString tid='voice-chat_awaiting' /></MixButton>
                }
                {voiceChat &&
                    <div className="participant">
                        {participants &&
                            <MixText className="name" centered>
                                {participants[participants?.length - 1]?.identity}
                            </MixText>
                        }

                        <div className="voiceChatBtn">
                            {muteButton(muted, room, () => {
                                setMuted(!muted);
                            })}
                            <IconButton onClick={disconnect} className="appointment__logoutBtn">
                                <CallEnd />
                            </IconButton>
                        </div>
                    </div>
                }
                {!isAdmin && !voiceChat && voiceChatState !== "not available" &&
                    <MixButton onClick={connect}>
                        {voiceChatState === "available" && <>{`${dictionary['voice-chat_talk_to']} ${exhibitor?.name}`}</>}
                        {voiceChatState === "busy" && <>{`${exhibitor?.name} ${dictionary['voice-chat_busy']}`}</>}
                    </MixButton>
                }
                {voiceChatState === "not available" && !isAdmin &&
                    <MixText>{exhibitor?.name} {dictionary['voice-chat_unavailable']}</MixText>
                }
            </div>
            {voiceChat &&
                <div className="voiceChat_liveIndicator">
                    <audio ref={audioRef} autoPlay={true} muted={false} />
                    <MixText color="primary" centered>
                        <TranslatedString tid='voice-chat_live' />
                    </MixText>
                </div>
            }
        </div>
        <Sound
            url={soundType}
            playStatus={soundStatus}
            onFinishedPlaying={() => {
                setSoundStatus(Sound.status.STOPPED)
            }}
        />
        <Beforeunload onBeforeunload={() => disConnectFromRoom()} />
    </div>

}

export default VoiceChat;
