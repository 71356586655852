import React, {useContext} from 'react';
import {IonModal, IonButton, IonAlert} from '@ionic/react';
import {AppContext} from "../../reducers/AppContext";
import {UserService} from "../../services/user/user.service";
import {useHistory} from "react-router";
import {LocalizationContext} from "../../reducers/LocalizationContext";

export const DoubleAuth: React.FC = () => {
    const {doubleAuthWarning, setDoubleAuthWarning} = useContext(AppContext);
    const history = useHistory();
    const {dictionary} = useContext(LocalizationContext);

    return <IonAlert
        isOpen={doubleAuthWarning}
        header={dictionary['general_doubleAuth-title']}
        message={dictionary['general_doubleAuth-subTitle']}
        cssClass="alert-double-auth"
        backdropDismiss={false}
        buttons={[
            {
                text: dictionary['general_connect_here'],
                cssClass: 'blue',
                handler: () => {
                    UserService.activateSession();
                    setDoubleAuthWarning(false);
                }
            },
            {
                text: dictionary['general_logout'],
                handler: () => {
                    UserService.doubleLogOut();
                    history.replace('/find-events')
                    setDoubleAuthWarning(false);
                }
            },
        ]}
    />
};