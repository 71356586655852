import { FormControl, FormHelperText, Input, InputLabel } from '@material-ui/core';
import React from 'react';
import { Control, Controller, DeepMap, FieldError, ValidationValueMessage } from 'react-hook-form';
import Heading from '../general/Heading';
import { renderErrorMessage } from '../../functions';

interface Props {
    name: string
    control?: Control<any>
    errors: DeepMap<any, FieldError>
    multiline?: boolean
    rows?: number
    required?: boolean
    defaultValue?: string
    label?: string
    pattern?: RegExp | ValidationValueMessage<RegExp> | undefined
    type?: "number" | "time" | "text" | "search" | "date" | "email" | "password" | "tel" | "url" | "week" | "month" | "datetime-local" | undefined
}

const InputController: React.FC<Props> = ({ name, control, errors, multiline = false, rows, required = false, defaultValue = "", label, pattern, type }) => {
    return (
        <FormControl className="fw">
            {
                label?.length! > 45 ? (
                    <Heading level={4} eventColor={false}>{label}</Heading>
                ) : (
                        <InputLabel>
                            {required && "*"} {label}
                        </InputLabel>
                    )
            }
            <Controller
                defaultValue={defaultValue}
                render={({ onChange, value }) => (
                    <Input multiline={multiline} rows={rows} type={type} value={value} onChange={onChange} />
                )}
                control={control}
                name={name}
                rules={{
                    required: required,
                    pattern: pattern
                }}
            />
            <FormHelperText>
                {errors[name] ? renderErrorMessage(errors, name) : null}
            </FormHelperText>
        </FormControl>
    );
}

export default InputController;
