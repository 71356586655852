import { IonText } from '@ionic/react';
import React from 'react';
import TranslatedString from '../general/TranslatedString';

interface Props {
  loginError: number
}

const ErrorMessage: React.FC<Props> = ({ loginError }) => {
  switch (loginError) {
    case 0:
      return null;
    case 1:
      return (<div className="ion-text-center ion-padding-vertical">
        <IonText color="danger">
          <TranslatedString tid="login_error-bad-nip" />
        </IonText>
      </div>);
    case 2:
      return (<div className="ion-text-center ion-padding-vertical">
        <IonText color="danger">
          <TranslatedString tid="login_error-event-not-available" />
        </IonText>
      </div>);
    default:
      return null;
  }
}

export default ErrorMessage;
