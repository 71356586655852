import { IonLabel } from '@ionic/react';
import React from 'react';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import { DatePicker } from '@material-ui/pickers';
import { renderErrorMessage } from '../../functions';

interface Props {
    name: string
    control?: Control<any>
    errors: DeepMap<any, FieldError>
    required?: boolean
    defaultValue?: string
    displayFormat?: string
    label?: string
}

const DatePickerController: React.FC<Props> = ({ name, control, errors, required = false, defaultValue = "", displayFormat = "", label }) => {
    return (
        <Controller
            defaultValue={defaultValue}
            render={({ onChange, value }) => (
                <React.Fragment>
                    <IonLabel position="stacked" className="ui__label">
                        {label}{required ? " (*)" : null}
                        {errors[name] ? renderErrorMessage(errors, name) : null}
                    </IonLabel>
                    <DatePicker fullWidth value={value} onChange={onChange} />
                </React.Fragment>
            )}
            control={control}
            name={name}
            rules={{
                required: required
            }}
        />
    );
}

export default DatePickerController;
