import React, { useState, useCallback, useContext, useEffect } from 'react';
import { AppContext } from '../../reducers/AppContext';
import { Lobby } from './lobby';
import { Room } from './room';
import { useIonViewWillLeave } from '@ionic/react';
import { VideoChatRoom, VideoChatService } from '../../services/videoChat/videoChat.service';


export const VideoChat: React.FC<{ roomName: string }> = ({ roomName }) => {
    const user = useContext(AppContext).user;

    const [token, setToken] = useState("");
    const [loading, setLoading] = useState<boolean>(false)

    const handleSubmit = useCallback(async () => {

        setLoading(true);
        const token = await VideoChatService.getRoomToken(user, roomName);
        setToken(token);
        setLoading(false);

    }, [user, roomName]);

    const [room, setRoom] = useState<VideoChatRoom | null>(null);
    const [participants, setParticipants] = useState<any[]>([]);

    const handleLogout = useCallback(() => setToken(""), []);

    const disConnectFromRoom = useCallback(() => {
        setRoom(currentRoom => {

            if (currentRoom && currentRoom.localParticipant.state === 'connected') {
                VideoChatService.disconnectFromRoom(currentRoom);
                return null;
            } else {

                return currentRoom;
            }
        });
    }, [setRoom])

    useIonViewWillLeave(() => disConnectFromRoom());

    useEffect(() => {
        if (token && roomName) {

            VideoChatService.connectToRoom(roomName, token)
                .then(({
                    room,
                    onParticipantConnected,
                    onParticipantDisConnected
                }) => {
                    setRoom(room);

                    onParticipantConnected((participant) => {
                        setParticipants(prevParticipants => [...prevParticipants, participant]);
                    })
                    onParticipantDisConnected((participant) => {
                        setParticipants(prevParticipants =>
                            prevParticipants.filter(p => p !== participant)
                        );
                    })
                })

            return disConnectFromRoom;
        }

    }, [roomName, token]);


    if (token) {
        return (
            <Room
                handleLogout={handleLogout}
                room={room}
                participants={participants}
                loading={loading} />
        );
    }


    return (
        <Lobby handleSubmit={handleSubmit} loading={loading} />
    );
}