import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { Exhibitor } from '../../interfaces/IExhibitor';
import { AppContext } from '../../reducers/AppContext';
import TranslatedString from '../general/TranslatedString';

interface Props {
    exhibitor: Exhibitor
    noDescription?: boolean
    narrow?: boolean
}

const GoldExhibitorCard: React.FC<Props> = ({ exhibitor, narrow = false }) => {
    const history = useHistory();
    const { eventId } = useContext(AppContext);

    return (
        <div className="gold-exhibitor-card"
            onClick={() =>
                history.push(`/my/${eventId}/exhibitors/${exhibitor.id}/schedule-meeting`)
            }>
            <div className="label">
                <TranslatedString tid="exhibitors_gold-label" />
            </div>
            <div style={exhibitor?.color ? { borderColor: exhibitor.color, minHeight: narrow ? 200 : "unset" } : { minHeight: narrow ? 200 : "unset" }}
                className="content">
                <div className="meta">
                    <img src={exhibitor.bannerImg?.url} alt="" />
                    {exhibitor?.cta && exhibitor?.ctaImg && <div className="description">
                        {exhibitor.cta}
                    </div>}
                </div>
                {exhibitor?.ctaImg && !narrow &&
                    <div style={exhibitor?.color ? { color: exhibitor.color } : {}}
                        className="cta">
                        <img src={exhibitor.ctaImg?.url} alt="" />
                        {/* <div className="text">
                            {exhibitor.cta}
                        </div> */}
                    </div>
                }
            </div>
        </div>
    );
}

export default GoldExhibitorCard;