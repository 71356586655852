import { IonLabel, IonText } from '@ionic/react';
import React, { useContext } from 'react';
import { LocalizationContext } from '../../reducers/LocalizationContext';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import FilePicker from './FilePicker.component';
import TranslatedString from '../general/TranslatedString';

interface Props {
    render: (onPick: () => void) => JSX.Element
    name: string
    control?: Control<any>
    errors: DeepMap<any, FieldError>
    required?: boolean
    defaultValue?: any
    label?: string
    accept?: string | undefined
    multiple?: boolean
    preview?: { path: string, type: string, url: string }
    setValue: (...args: any) => void
}

const ControlledFilePicker: React.FC<Props> = ({ render, name, control, errors, required, defaultValue, label = "", accept, multiple, preview, setValue }) => {
    const { dictionary } = useContext(LocalizationContext);

    const renderErrorMessage = (key: string) => {
        let result = (errors as any)[key];
        return <IonText color="danger">{result?.message || " — This is a required field"}</IonText>
    }

    const showFileName = (path: string) => {
        // return "";
        try {
            const splitName = path.split('/');
            const fullName = splitName[splitName.length - 1];
            let leftPart = fullName.split(".")[0];
            let rightPart = fullName.split(".")[1];
            if (rightPart) {
                rightPart = rightPart.split("_")[0];
            }
            else {
                rightPart = "";
            }
            leftPart += "." + rightPart;
            return leftPart;
        } catch (e) {
            return "Error displaying file"
        }
    }

    return (
        <Controller
            defaultValue={defaultValue}
            render={({ onChange, value }) => (
                <div className="fc__file-picker">
                    <IonLabel className="ui__label">
                        {label}
                        {errors[name] ? renderErrorMessage(name) : null}
                    </IonLabel>
                    <div className="preview">
                        {
                            value && value.files ? value.files.length > 1 && value !== "DELETED" ?
                                <div>{value.files.length} {dictionary["general_file"]}s</div>
                                : <div>1 {dictionary["general_file"]}</div>
                                : preview && (preview.type?.includes("image") && value !== "DELETED" ?
                                    <img src={preview.url} className="image_preview" alt="preview" />
                                    : value !== "DELETED" && value.url ?
                                        <div>{showFileName(preview.path)}</div> : null
                                )
                        }
                    </div>
                    <div className="actions">
                        <FilePicker render={render} onSelected={onChange} accept={accept} multiple={multiple} />
                        {
                            value && value !== 'DELETED' && value.url && !required &&
                            (
                                <div className="delete btn" color="danger" onClick={() => setValue(name, "DELETED", { shouldDirty: true })}>
                                    <TranslatedString tid="general_delete" />
                                </div>
                            )
                        }
                    </div>
                </div>
            )}
            control={control}
            name={name}
            rules={{
                required: required
            }}
        />
    );
}

export default ControlledFilePicker;
