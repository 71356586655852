import {IonIcon} from '@ionic/react';
import React, {useContext, useMemo} from 'react';
import {useHistory, useLocation} from 'react-router';
import {EventFeatures} from '../../interfaces/IEvent';
import {AppContext} from '../../reducers/AppContext';
import TranslatedString from './TranslatedString';
import EventHomeIcon from '../../assets/icons/ic-nav-event-home.svg';
import EventScheduleIcon from '../../assets/icons/ic-nav-event-schedule.svg';
import ExhibitorsIcon from '../../assets/icons/ic-nav-exhibitors.svg';
import SponsorsIcon from '../../assets/icons/ic-nav-sponsors.svg';
import HelpIcon from '../../assets/icons/ic-nav-help.svg';
import {LocalizationContext} from "../../reducers/LocalizationContext";

const EventNavigation: React.FC = () => {
    const history = useHistory();
    const location = useLocation();
    const {eventId, eventProps, user} = useContext(AppContext);
    const {userLanguage} = useContext(LocalizationContext)

    const iconStyle = eventProps?.eventColor ? {color: eventProps.eventColor} : {};

    const menuItems = useMemo(() => {
        return [
            {
                key: 'home',
                icon: <IonIcon style={iconStyle} src={EventHomeIcon}/>,
                string: <TranslatedString tid="general_event-home"/>,
                path: `/my/${eventId}/home`,
                feature: ""
            },
            {
                key: 'schedule',
                icon: <IonIcon style={iconStyle} src={EventScheduleIcon}/>,
                string: <TranslatedString tid="general_schedule"/>,
                path: `/my/${eventId}/schedule`,
                feature: EventFeatures.SCHEDULE
            },
            {
                key: 'exhibitors',
                icon: <IonIcon style={iconStyle} src={ExhibitorsIcon}/>,
                string: <TranslatedString tid="general_exhibitors"/>,
                path: `/my/${eventId}/exhibitors`,
                feature: EventFeatures.EXHIBITORS
            },
            {
                key: 'sponsors',
                icon: <IonIcon style={iconStyle} src={SponsorsIcon}/>,
                string: <TranslatedString tid="general_sponsors"/>,
                path: `/my/${eventId}/sponsors`,
                feature: EventFeatures.SPONSORS
            },
            {
                key: 'help',
                icon: <IonIcon style={iconStyle} src={HelpIcon}/>,
                string: <TranslatedString tid="general_help"/>,
                path: `/my/${eventId}/help`,
                feature: EventFeatures.INFO
            },
            {
                key: 'admin',
                icon: null,
                string: <TranslatedString tid="general_admin-page"/>,
                path: `/admin/${eventId}/general`,
                feature: EventFeatures.ADMIN
            }
        ]
            .filter(({feature}) => feature === "" || (eventProps && eventProps?.features?.includes(feature)))
            .filter((item) => user?.admin ? true : item.key !== 'admin')
            .filter((item) => user?.standard || user?.admin || eventId === 'lpes1' || eventId === 'lpes2' ? true : item.key !== 'schedule')
    }, [eventId, eventProps, user, userLanguage])

    return (
        <aside className="fc__event-navigation">
            {
                menuItems.map(({icon, string, key, path}) => (
                    <div className={`nav-item${location.pathname === path ? ' selected' : ''}`} key={key}
                         onClick={() => history.push(path)}>
                        {icon && icon}
                        {string}
                    </div>
                ))
            }
        </aside>
    );
}

export default EventNavigation;
