import { IonImg } from '@ionic/react';
import React from 'react';
import { EventProps } from '../../interfaces/IEvent';
import Placeholder from '../../assets/bannerImg_placeholder.png';

interface Props {
  eventProps: EventProps
}

const LoginCover: React.FC<Props> = ({ eventProps }) => {
  return (
    <div className="ui__col login__cover">
      <IonImg src={eventProps?.bannerImg?.url ? eventProps.bannerImg.url : Placeholder} alt="" />
    </div>
  );
}

export default LoginCover;
