import React from 'react';

const TableBody: React.FC = ({ children }) => {
    return (
        <tbody className="table__body">
            {children}
        </tbody>
    );
}

export default TableBody;
