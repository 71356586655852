import React, { useState } from 'react';

interface Props {
    title: string
    titleOnClick?: string
    onClick?: (...args: any) => void
    width?: number
    showOnHover?: boolean
    showOnClick?: boolean
}

const Tooltip: React.FC<Props> = ({ title, titleOnClick = "", onClick = () => null, width, showOnHover = true, showOnClick = false, children }) => {
    const [clicked, setClicked] = useState(false);
    const [hovered, setHovered] = useState(false);

    return (
        <div className="fc__tooltip">
            {hovered && showOnHover &&
                <div className="tooltip" style={width ? { width: width } : {}}>
                    {
                        titleOnClick !== '' ?
                            (clicked ? titleOnClick : title)
                            : title
                    }
                </div>
            }
            {hovered && showOnClick && clicked &&
                <div className="tooltip" style={width ? { width: width } : {}}>
                    {
                        titleOnClick
                    }
                </div>
            }
            <div className="children" onClick={() => {
                setClicked(true);
                onClick();
            }}
                onMouseOver={() => {
                    setHovered(true)
                }}
                onMouseLeave={() => {
                    setHovered(false);
                    setClicked(false);
                }}>
                {children}
            </div>
        </div>
    );
}

export default Tooltip;
