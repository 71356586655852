import React from 'react';

interface Props {

}

const UIContent: React.FC<Props> = ({ children }) => {
    return (
        <div className="ui__content">
            {children}
        </div>
    );
}

export default UIContent;