import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { ScheduleItem, ScheduleItemComposer } from '../../../interfaces/ISchedule';
import { AppContext } from '../../../reducers/AppContext';
import { LocalizationContext } from '../../../reducers/LocalizationContext';
import { PopupContext } from '../../../reducers/PopupContext';
import { AdminService } from '../../../services/admin/admin.service';
import Heading from '../../general/Heading';
import MixButton from '../../general/MixButton';
import TranslatedString from '../../general/TranslatedString';
import DisplayEventLink from './DisplayEventLink';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';
import TimePickerController from '../../forms/TimePickerController';
import ControlledFilePicker from '../../FilePicker/ControlledFilePicker.component';
import { mapToFileComposer } from '../../../functions';
import { StorageService } from '../../../services/storage/storage.service';
import InputController from '../../forms/InputController';
import SelectController from '../../forms/SelectController';
import { Chip, MenuItem } from '@material-ui/core';
import DatePickerController from '../../forms/DatePickerController';
import UIRow from '../../UI/UIRow';
import UIGroup from '../../UI/UIGroup';
import { DevTool } from "@hookform/devtools";

const defaultScheduleItem: ScheduleItem = {
    description: "",
    endsAt: "",
    location: "",
    name: "",
    presenters: [],
    startsAt: "",
    streamStatus: undefined,
    streamUrl: "",
    studioLink: "",
    thumbnail: null as any,
    video: null as any,
    trailer: null as any,
    chatEnabled: false,
    downloadLink: "",
    m3u8: "",
    type: null as any,
    postStatus: "",
    enableAds: "disable",
    releaseTime: "",
    tags: []
}

interface Props {
    scheduleItem?: ScheduleItem
}

const ManageScheduleItemForm: React.FC<Props> = ({ scheduleItem = defaultScheduleItem }) => {
    const editing = !!scheduleItem.id;

    const { eventProps, eventId, setLoading } = useContext(AppContext);
    const { setModalContent, setModalCssClass } = useContext(PopupContext);
    const { dictionary } = useContext(LocalizationContext);

    const now = dayjs().set('minute', 0).format();

    const initialValues: any = {
        itemType: scheduleItem.type ? scheduleItem?.type : "",
        itemTitle: scheduleItem.name,
        itemDescription: scheduleItem.description,
        location: scheduleItem.location,
        downloadLink: scheduleItem.downloadLink,
        thumbnail: mapToFileComposer(scheduleItem?.thumbnail),
        video: mapToFileComposer(scheduleItem?.video),
        trailer: mapToFileComposer(scheduleItem?.trailer),
        startDate: scheduleItem.startsAt ? dayjs(scheduleItem.startsAt, 'MM/D/YYYY HH:mm').format('MM/D/YYYY') : eventProps?.eventDates[0],
        liveTime: scheduleItem.startsAt ? dayjs(scheduleItem.startsAt, 'MM/D/YYYY HH:mm') : now,
        endTime: scheduleItem.endsAt ? dayjs(scheduleItem?.endsAt, 'MM/D/YYYY HH:mm') : now,
        presenters: scheduleItem.presenters,
        postStatus: scheduleItem.postStatus,
        enableAds: scheduleItem.enableAds,
        releaseTime: scheduleItem.releaseTime ? dayjs(scheduleItem.releaseTime, 'MM/D/YYYY HH:mm') : now,
        tags: scheduleItem.tags
    };

    const { handleSubmit, errors, control, reset, setValue, getValues, register, watch } = useForm<ScheduleItemComposer>({
        defaultValues: initialValues
    });

    const [tags, setTags] = useState<string[]>([]);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [type, setType] = useState(null as any);
    const [showReleaseTime, setShowReleaseTime] = useState(false);

    useEffect(() => {
        if (eventProps?.tags) {
            setTags(eventProps.tags);
        }
        setSelectedTags(scheduleItem?.tags);
    }, []);

    useEffect(() => {
        switch (getValues("itemType")) {
            case "general":
                setType("general");
                // Note: shouldn't be available if template of event is set to "OTT"
                break;
            case "stream":
                setType("stream");
                break;
            case "vod":
                setType("vod");
                break;
        }
    }, [watch().itemType]);

    useEffect(() => {
        if (getValues("postStatus") === "future") {
            setShowReleaseTime(true);
        } else setShowReleaseTime(false);
    }, [watch().postStatus]);

    const removeTag = (tag: string) => {
        setSelectedTags((chips) => chips.filter((chip) => chip !== tag));
        setTags((chips) => chips.concat(tag));
    };

    const addTag = (tag: string) => {
        setSelectedTags((chips) => chips.concat(tag));
    };

    useEffect(() => {
        setTags((chips) => chips.filter((chip) => selectedTags.indexOf(chip) === -1));
    }, [selectedTags]);

    const createScheduleItem = async (data: any) => {
        const uploadDir = `events/${eventId}/schedule`;

        const body = {
            type: data.itemType,
            startsAt: dayjs(data.startDate).format("MM/DD/YYYY").concat(' ', dayjs(data.liveTime).format("HH:mm")),
            endsAt: dayjs(data.startDate).format("MM/DD/YYYY").concat(' ', dayjs(data.endTime).format("HH:mm")),
            downloadLink: data.downloadLink,
            name: data.itemTitle,
            description: data.itemDescription,
            location: data.location,
            presenters: scheduleItem?.presenters,
            postStatus: data?.postStatus,
            enableAds: data?.enableAds,
            releaseTime: data?.releaseTime,
            tags: selectedTags
        } as ScheduleItem;

        if (data.thumbnail && data.thumbnail.files && data.thumbnail !== "DELETED") {
            body.thumbnail = await StorageService.uploadFile(uploadDir, data.thumbnail.files.item(0));
        }
        if (data.video && data.video.files && data.video !== "DELETED") {
            body.video = await StorageService.uploadFile(uploadDir, data.video.files.item(0));
        }
        if (data.trailer && data.trailer.files && data.trailer !== "DELETED") {
            body.trailer = await StorageService.uploadFile(uploadDir, data.trailer.files.item(0));
        }

        AdminService.createScheduleItem(body, eventId).then((res) => {
            setLoading(false);
            reset();
            setModalCssClass("add-schedule-item-success");
            setModalContent(
                <DisplayEventLink eventId={eventId} input={res} />
            );
        });
    }

    const updateScheduleItem = async (data: any) => {
        const uploadDir = `events/${eventId}/schedule`;

        const body = {
            id: scheduleItem.id,
            type: data.itemType,
            startsAt: dayjs(data.startDate).format("MM/DD/YYYY").concat(' ', dayjs(data.liveTime).format("HH:mm")),
            endsAt: dayjs(data.startDate).format("MM/DD/YYYY").concat(' ', dayjs(data.endTime).format("HH:mm")),
            downloadLink: data.downloadLink,
            name: data.itemTitle,
            description: data.itemDescription,
            location: data.location,
            presenters: scheduleItem?.presenters,
            postStatus: data?.postStatus,
            enableAds: data?.enableAds,
            releaseTime: data?.releaseTime,
            tags: selectedTags
        } as ScheduleItem;

        if (data.thumbnail && data.thumbnail.files !== null && data.thumbnail !== "DELETED") {
            body.thumbnail = await StorageService.uploadFile(uploadDir, data.thumbnail.files.item(0));
        }
        if (data.video && data.video.files !== null && data.video !== "DELETED") {
            body.video = await StorageService.uploadFile(uploadDir, data.video.files.item(0));
        }
        if (data.trailer && data.trailer.files !== null && data.trailer !== "DELETED") {
            body.trailer = await StorageService.uploadFile(uploadDir, data.trailer.files.item(0));
        }

        AdminService.updateScheduleItem(body, eventId).then((res) => {
            setLoading(false);
            reset();
            setModalCssClass("add-schedule-item-success");
            setModalContent(
                <DisplayEventLink eventId={eventId} input={scheduleItem?.id} />
            );
        });
    }

    const onSubmit = (data: any) => {
        try {
            setLoading(true);
            if (editing) {
                updateScheduleItem(data)
            } else {
                createScheduleItem(data);
            }
        } catch (e) {
            console.log('error: ', e);
            setLoading(false);
        }
    }

    return (
        <MuiPickersUtilsProvider utils={DayjsUtils}>
            <Heading level={2}>
                {editing ? dictionary["admin-schedule_edit-schedule-item"] : dictionary["admin-schedule_add-schedule-item"]}
            </Heading>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="ui__group presentation">
                    <div className="ui__group">
                        <Heading level={3}>
                            <TranslatedString tid="admin-schedule_presentation" />
                        </Heading>
                        <SelectController name="itemType" label={dictionary["admin-schedule_item-type"]} errors={errors} control={control} defaultValue={initialValues.itemType} required>
                            <MenuItem value="stream">
                                <TranslatedString tid="event-schedule_category-stream" />
                            </MenuItem>
                            <MenuItem value="vod">
                                <TranslatedString tid="event-schedule_category-vod" />
                            </MenuItem>
                            <MenuItem value="general">
                                <TranslatedString tid="event-schedule_category-general" />
                            </MenuItem>
                        </SelectController>
                        <InputController
                            name="itemTitle"
                            label={dictionary["admin-schedule_item-title"]}
                            errors={errors}
                            control={control}
                            defaultValue={initialValues.itemTitle}
                            required />
                        <InputController
                            multiline
                            rows={4}
                            name="itemDescription"
                            label={dictionary["admin-schedule_item-description"]}
                            errors={errors}
                            control={control}
                            defaultValue={initialValues.itemDescription}
                            required />
                        <InputController
                            name="location"
                            label={dictionary["admin-schedule_location"]}
                            errors={errors}
                            control={control}
                            defaultValue={initialValues.location}
                            required />
                        {
                            type && (type === "stream" || type === "vod") &&
                            <InputController
                                name="downloadLink"
                                label={dictionary["admin-schedule_download-link"]}
                                errors={errors}
                                control={control}
                                defaultValue={initialValues.downloadLink}
                                pattern={{
                                    value: /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/i,
                                    message: "- Incorrect URL format"
                                }} />
                        }
                        <ControlledFilePicker
                            accept="image/*"
                            setValue={setValue}
                            render={(onPick) => <MixButton onClick={onPick}>{dictionary['general_upload']}</MixButton>}
                            name="thumbnail" label={dictionary["admin-schedule_thumbnail"]}
                            errors={errors}
                            control={control}
                            defaultValue={initialValues.thumbnail}
                            preview={scheduleItem?.thumbnail} />
                        {
                            type && type === "vod" &&
                            <ControlledFilePicker
                                accept="video/*"
                                setValue={setValue}
                                render={(onPick) => <MixButton onClick={onPick}>{dictionary['general_upload']}</MixButton>}
                                name="video"
                                label={dictionary["admin-schedule_video"]}
                                errors={errors}
                                control={control}
                                defaultValue={initialValues.video}
                                preview={scheduleItem?.video}
                                required />
                        }
                        {
                            type && (type === "stream" || type === "vod") &&
                            <ControlledFilePicker
                                accept="video/*"
                                setValue={setValue}
                                render={(onPick) => <MixButton onClick={onPick}>{dictionary['general_upload']}</MixButton>}
                                name="trailer"
                                label={dictionary["admin-schedule_trailer"]}
                                errors={errors}
                                control={control}
                                defaultValue={initialValues.trailer}
                                preview={scheduleItem?.trailer} />
                        }
                        {
                            type && type === "stream" &&
                            <React.Fragment>
                                <SelectController name="startDate" label={dictionary["admin-schedule_start-date"]} errors={errors} control={control} defaultValue={initialValues.startDate} required>
                                    {
                                        eventProps?.eventDates?.map((date, index) => (
                                            <MenuItem value={date} key={index}>
                                                {date}
                                            </MenuItem>
                                        ))
                                    }
                                </SelectController>
                                <TimePickerController
                                    name="liveTime"
                                    label={dictionary["admin-schedule_live-time"]}
                                    displayFormat="HH:mm"
                                    errors={errors}
                                    errorField={errors.liveTime}
                                    control={control}
                                    defaultValue={initialValues.liveTime}
                                    required />
                                <TimePickerController
                                    name="endTime"
                                    label={dictionary["admin-schedule_end-time"]}
                                    displayFormat="HH:mm"
                                    errors={errors}
                                    errorField={errors.endTime}
                                    control={control}
                                    defaultValue={initialValues.endTime}
                                    required />
                            </React.Fragment>
                        }
                        {
                            type && (type === "stream" || type === "vod") &&
                            <SelectController
                                name="enableAds"
                                label={dictionary["admin-schedule_advertisements"]}
                                errors={errors}
                                control={control}
                                defaultValue={initialValues.enableAds}
                                required>
                                <MenuItem value="enable">
                                    <TranslatedString tid="general_enable" />
                                </MenuItem>
                                <MenuItem value="disable">
                                    <TranslatedString tid="general_disable" />
                                </MenuItem>
                            </SelectController>
                        }

                        <UIGroup>
                            <Heading level={2}>
                                Selected tags
                            </Heading>
                            <UIRow wrap margin>
                                {
                                    selectedTags?.length ? selectedTags.map((tag, index) => (
                                        <Chip
                                            key={index}
                                            variant="outlined"
                                            size="small"
                                            label={tag}
                                            onDelete={() => removeTag(tag)}
                                        />
                                    )) : <TranslatedString tid="general_nothing" />
                                }
                            </UIRow>
                        </UIGroup>
                        <UIGroup>
                            <Heading level={3}>
                                All tags
                            </Heading>
                            <UIRow wrap margin>
                                {
                                    tags?.length ? tags.map((tag, index) => (
                                        <Chip
                                            key={index}
                                            variant="outlined"
                                            size="small"
                                            label={tag}
                                            onClick={() => addTag(tag)}
                                        />
                                    )) : <TranslatedString tid="general_nothing" />
                                }
                            </UIRow>
                        </UIGroup>

                        <SelectController
                            name="postStatus"
                            label={dictionary["admin-schedule_post-status"]}
                            errors={errors}
                            control={control}
                            defaultValue={initialValues.postStatus}
                            required>
                            <MenuItem value="published">
                                Published
                            </MenuItem>
                            <MenuItem value="draft">
                                Draft
                            </MenuItem>
                            <MenuItem value="future">
                                To be released in the future
                            </MenuItem>
                        </SelectController>
                        {
                            showReleaseTime &&
                            <DatePickerController
                                name="releaseTime"
                                label={dictionary["admin-schedule_release-time"]}
                                errors={errors}
                                control={control}
                                defaultValue={initialValues.releaseTime}
                                required />
                        }
                    </div>
                </div>
                <div className="ui__group ion-padding-top">
                    <MixButton submit>
                        {editing ? dictionary["admin-schedule_edit-schedule-item"] : dictionary["admin-schedule_add-schedule-item"]}
                    </MixButton>
                </div>
            </form>
            <DevTool control={control} />
        </MuiPickersUtilsProvider>
    );
}

export default ManageScheduleItemForm;
