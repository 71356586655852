import React, { useContext, useEffect, useState } from 'react';
import { IonApp, IonIcon } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { AppContext, AppContextProvider } from './reducers/AppContext';
import { hotjar } from 'react-hotjar';

// App styles
import './styles/App.scss';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/text-alignment.css';

import Routes from './Routes';
import { LocalizationContextProvider } from './reducers/LocalizationContext';
import { DoubleAuth } from "./components/login/doubleAuthPrompt";
import { UserService } from "./services/user/user.service";
import { PopupContextProvider } from './reducers/PopupContext';

import firebase from "firebase";
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { mutateColor } from './functions';

import MixablLogo from './assets/logo-mixabl.svg';
import MaintenancePic from './assets/ic-maintenance.svg';
import { AuthContextProvider } from './reducers/AuthContext';

const App: React.FC = () => {
    const color = useContext(AppContext).eventProps?.eventColor;
    const [maintenance, setMaintenance] = useState(false);

    // Trying to hide Safari UI on iOS
    useEffect(() => window.top.scrollTo(0, 1), []);

    useEffect(() => {
        firebase.analytics();
        hotjar.initialize(2087919, 1);
        return () => {
            UserService.activateSession('available')
        }
    }, []);

    let theme = createMuiTheme({
        typography: {
            fontFamily: [
                'Poppins',
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
        },
        palette: {
            primary: {
                main: '#3880ff',
            },
            secondary: {
                main: '#3dc2ff'
            },
        },
    });

    useEffect(() => {
        if (color) {
            theme.palette.primary = color as any;
            theme.palette.secondary = mutateColor(color, 12) as any;
        }
    }, [color]);

    return maintenance ? (
        <div className="fc__maintenance">
            <div className="illustration">
                <IonIcon src={MaintenancePic} />
            </div>
            <div className="content">
                <IonIcon src={MixablLogo} />
                <div className="text">
                    Nous serons de retour après cette maintenance programmée.
                </div>
            </div>
        </div>
    ) : (
            <IonApp>
                <AuthContextProvider>
                    <IonReactRouter>
                        <AppContextProvider>
                            <LocalizationContextProvider>
                                <PopupContextProvider>
                                    <ThemeProvider theme={theme}>
                                        <Routes />
                                        <DoubleAuth />
                                    </ThemeProvider>
                                </PopupContextProvider>
                            </LocalizationContextProvider>
                        </AppContextProvider>
                    </IonReactRouter>
                </AuthContextProvider>
            </IonApp>
        );
};

export default App;
