import dayjs from 'dayjs';
import React, {useContext, useEffect, useState} from 'react';
import {IonIcon} from '@ionic/react';
import PlayIcon from '../../assets/icons/ic-player-countdown-play.svg';
import {AppContext} from '../../reducers/AppContext';
import duration from 'dayjs/plugin/duration';
import TranslatedString from "../general/TranslatedString";

interface Props {
    timestamp: string
}

const PlayerPlaceholder: React.FC<Props> = ({timestamp}) => {
    const color = useContext(AppContext).eventProps?.eventColor;
    dayjs.extend(duration);

    const [remainingTime, setReaminingTime] = useState(
        dayjs.duration(dayjs(dayjs(timestamp).diff(dayjs())), 'millisecond')
    );
    const [isStarting, setIsStarting] = useState(false);
    const [timer, setTimer] = useState(1000);

    useEffect(() => {
        if (timer !== 0) {
            const interval = setInterval(() => {
                setReaminingTime(
                    dayjs.duration(dayjs(dayjs(timestamp).diff(dayjs())), 'millisecond')
                );
            }, timer);
            return () => clearInterval(interval);
        }
    }, [timer]);

    useEffect(() => {
        if (remainingTime.asSeconds() < 0) {
            setTimer(0);
            setIsStarting(true);
        } else if (!timestamp) {
            setTimer(0);
        }
    }, [remainingTime]);

    const formatRemainingTime = (remainingTime: duration.Duration) => {
        return remainingTime.days().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false}) + ":" +
            remainingTime.hours().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false}) + ":" +
            remainingTime.minutes().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false}) + ":" +
            remainingTime.seconds().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false})
    }

    return (
        <div className="fc__player-countdown">
            <div className="wrapper">
                <div className="info-wrapper">
                    <div className="info">
                        <div className="col icon" style={color ? {color: color} : {}}>
                            <IonIcon src={PlayIcon}/>
                        </div>
                        <div className="col remaining-time" style={color ? {color: color} : {}}>
                            {
                                isStarting ?
                                    (
                                        <React.Fragment>
                                            <div className="text unsellectable">
                                                <TranslatedString tid='event-schedule_about_start_title'/>
                                            </div>
                                            <div className="time">
                                                <TranslatedString tid='event-schedule_about_start_subtitle'/>
                                            </div>
                                        </React.Fragment>
                                    ) :
                                    (
                                        <React.Fragment>
                                            <div className="text unsellectable">
                                                <TranslatedString tid='event-schedule_in'/>
                                            </div>
                                            <div className="time unsellectable">
                                                {formatRemainingTime(remainingTime)}
                                            </div>
                                        </React.Fragment>
                                    )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg"/>
        </div>
    );
}

export default PlayerPlaceholder;
