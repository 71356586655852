// Groupe le point ID
export const CLIENT_ID = 'ReSOTfM5BcFYjKd5mfqo';

export class DB_COLLECTIONS {
  private static _clients = "clients";
  private static _events = "events";
  private static _tickets = "tickets";
  private static _scheduleItems = "scheduleItems";
  private static _admins = "admins";
  private static _exhibitorsCol = "exhibitors";
  private static _exhibitorMeetingsCol = "appointments";
  private static _appointments = "appointments";
  private static _privates = "privates";
  private static _chatsCol = "chats";
  private static _analytics = "Analytics";
  private static _generalChat = "generalChat";
  private static _voiceChat = "voiceChats";
  private static _streamInfo = "streamInfo";

  static getClientsCol() {
    return `${this._clients}`;
  }
  static getClientPath() {
    return `${this._clients}/${CLIENT_ID}`;
  }
  static getEventsCol() {
    return `${this._clients}/${CLIENT_ID}/${this._events}`;
  }
  static getEventPath(eventId: string) {
    return `${this._clients}/${CLIENT_ID}/${this._events}/${eventId}`;
  }
  static getScheduleItemCol(eventId: string) {
    return `${this._clients}/${CLIENT_ID}/${this._events}/${eventId}/${this._scheduleItems}`;
  }
  static getScheduleItemPath(eventId: string, scheduleItemId: string) {
    return `${this._clients}/${CLIENT_ID}/${this._events}/${eventId}/${this._scheduleItems}/${scheduleItemId}`;
  }
  static getScheduleItemStreamInfoPath(eventId: string, scheduleItemId: string) {
    return `${this._clients}/${CLIENT_ID}/${this._events}/${eventId}/${this._scheduleItems}/${scheduleItemId}/${this._privates}/${this._streamInfo}`;
  }
  static getScheduleItemCommentsCol(streamId: string, eventId: string) {
    return `${this._clients}/${CLIENT_ID}/${this._events}/${eventId}/${this._scheduleItems}/${streamId}/${this._chatsCol}`;
  }
  static getExhibitorCol(eventId: string,) {
    return `${this._clients}/${CLIENT_ID}/${this._events}/${eventId}/${this._exhibitorsCol}`;
  }
  static getExhibitorAppointementCommentsCol(eventId: string, exhibitorId: string, appointmentId: string) {
    return `${this._clients}/${CLIENT_ID}/${this._events}/${eventId}/${this._exhibitorsCol}/${exhibitorId}/${this._appointments}/${appointmentId}/${this._chatsCol}`;
  }
  static getExhibitorPath(eventId: string, exhibitorId: string) {
    return `${this._clients}/${CLIENT_ID}/${this._events}/${eventId}/${this._exhibitorsCol}/${exhibitorId}`;
  }
  static getExhibitorCommentsCol(eventId: string, exhibitorId: string) {
    return `${this._clients}/${CLIENT_ID}/${this._events}/${eventId}/${this._exhibitorsCol}/${exhibitorId}/${this._chatsCol}`;
  }
  static getExhibitorAnalyticsPath(eventId: string, exhibitorId: string) {
    return `${this._clients}/${CLIENT_ID}/${this._events}/${eventId}/${this._exhibitorsCol}/${exhibitorId}/${this._analytics}`;
  }
  static getExhibitorMeetingCol(eventId: string, exhibitorId: string) {
    return `${this._clients}/${CLIENT_ID}/${this._events}/${eventId}/${this._exhibitorsCol}/${exhibitorId}/${this._exhibitorMeetingsCol}/`;
  }
  static getExhibitorMeetingPath(eventId: string, exhibitorId: string, meetingId: string) {
    return `${this._clients}/${CLIENT_ID}/${this._events}/${eventId}/${this._exhibitorsCol}/${exhibitorId}/${this._exhibitorMeetingsCol}/${meetingId}`;
  }
  static getExhibitorVoiceChatCol(eventId: string, exhibitorId: string) {
    return `${this._clients}/${CLIENT_ID}/${this._events}/${eventId}/${this._exhibitorsCol}/${exhibitorId}/${this._voiceChat}`;
  }
  static getExhibitorVoiceChatDoc(eventId: string, exhibitorId: string, chatId: string) {
    return `${this._clients}/${CLIENT_ID}/${this._events}/${eventId}/${this._exhibitorsCol}/${exhibitorId}/${this._voiceChat}/${chatId}`;
  }
  static getAppointmentsCol() {
    return this._appointments;
  }
  static getUserCol(eventId: string) {
    return `${this._clients}/${CLIENT_ID}/${this._events}/${eventId}/${this._tickets}`;
  }
  static getUserPath(eventId: string, userId: string) {
    return `${this._clients}/${CLIENT_ID}/${this._events}/${eventId}/${this._tickets}/${userId}`;
  }
  static getAdminCol(eventId: string, userId: string) {
    return `${this._clients}/${CLIENT_ID}/${this._events}/${eventId}/${this._admins}/${userId}`;
  }
  static getGeneralChat(eventId: string) {
    return `${this._clients}/${CLIENT_ID}/${this._events}/${eventId}/${this._generalChat}`;
  }
}