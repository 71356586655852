import firebase from "firebase";
import { DatabaseService } from "../database/database.service";

class Paginator<T> {

    private unsub: Function = null as any;
    private nextQry: firebase.firestore.Query<firebase.firestore.DocumentData> = null as any;
    private prevQry: firebase.firestore.Query<firebase.firestore.DocumentData> = null as any;
    private subscribeFn: (data: T[]) => void = null as any
    private lastNextSnap: firebase.firestore.DocumentSnapshot = null as any;
    private lastPrevSnap: firebase.firestore.DocumentSnapshot = null as any;

    private hasNext = true;
    private hasPrev = false;

    constructor(
        path: string,
        private orderBy: { key: string | firebase.firestore.FieldPath, direction: "asc" | "desc" }[],
        private batchSize: number = 11,
        cb?: (_col: firebase.firestore.Query<firebase.firestore.DocumentData>) => firebase.firestore.Query<firebase.firestore.DocumentData>
    ) {

        this.nextQry = DatabaseService.col(path).limit(this.batchSize);
        this.prevQry = DatabaseService.col(path).limit(this.batchSize);

        if (cb) {
            this.nextQry = cb(this.nextQry)
        }

        for (let { key, direction = "asc" } of this.orderBy) {
            this.nextQry = this.nextQry.orderBy(key, direction);
        }

        for (let { key, direction = "asc" } of this.orderBy) {
            this.prevQry = this.prevQry.orderBy(key, direction === 'asc' ? 'desc' : 'asc');
        }
    }

    private initialQry() {

        this.unsub = this.nextQry.onSnapshot((snaps) => {

            this.lastNextSnap = snaps.docs[this.batchSize - 1];

            if(snaps.size <= 0) {
                this.hasNext = false;
            }

            const _data = snaps.docs.map((snap) => {
                return ({ id: snap.id, ...snap.data() } as any) as T;
            })
            this.subscribeFn(_data);
        })
    }

    public subscribe(cb: (data: T[]) => void) {
        this.subscribeFn = cb;
        this.initialQry();
        return () => {
            this.unsub();
        }
    }

    public getHasNext() {
        return this.hasNext;
    }

    public getHasPrev() {
        return this.hasPrev;
    }

    public prev() {

        if (!(this.subscribeFn && this.unsub)) {
            throw new Error("You need to call subscribe first");
        }

        this.unsub();


        if (!this.lastPrevSnap) {
            return
        }

        const lastData = this.lastPrevSnap?.data() as any

        const next_values = this.orderBy.map(({ key }) => key instanceof firebase.firestore.FieldPath ? this.lastPrevSnap?.id : lastData[key]);
        this.unsub = this.prevQry
            .startAfter(...next_values)
            .onSnapshot((snaps) => {
                const docs = snaps.docs

                this.lastNextSnap = docs[0];
                this.lastPrevSnap = docs[this.batchSize - 1];


                if (snaps.size <= 0) {
                    this.unsub();
                    this.hasPrev = false;
                    return
                } else  {

                    this.hasNext = true;
                }

                const _data = docs.reverse().map((snap) => {
                    return ({ id: snap.id, ...snap.data() } as any) as T;
                })

                this.subscribeFn(_data);
            })
    }


    public next() {

        if (!(this.subscribeFn && this.unsub)) {
            throw new Error("You need to call subscribe first");
        }

        this.unsub();

        if (!this.lastNextSnap) {
            return
        }

        const lastData = this.lastNextSnap?.data() as any

        const next_values = this.orderBy.map(({ key }) => key instanceof firebase.firestore.FieldPath ? this.lastNextSnap?.id : lastData[key]);

        this.unsub = this.nextQry
            .startAfter(...next_values)
            .onSnapshot((snaps) => {
                this.lastNextSnap = snaps.docs[this.batchSize - 1];
                this.lastPrevSnap = snaps.docs[0];

                if (snaps.size <= 0) {
                    this.unsub();
                    this.hasNext = false;
                    return
                } else {
                    this.hasPrev = true;
                }

                const _data = snaps.docs.map((snap) => {
                    return ({ id: snap.id, ...snap.data() } as any) as T;
                })


                this.subscribeFn(_data);
            })
    }

}


export const PaginationService = {
    Paginator
}

