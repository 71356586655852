import { IonIcon } from '@ionic/react';
import React, { useContext } from 'react';
import AddTicketIcon from '../../../assets/icons/ic-admin-btn-add-ticket.svg';
import ManageTicketForm from './ManageTicketForm';
import { PopupContext } from '../../../reducers/PopupContext';
import TranslatedString from '../../general/TranslatedString';

const AddTicket: React.FC = () => {
    const { setModalCssClass, setModalContent, showModal } = useContext(PopupContext);

    const openForm = () => {
        setModalCssClass("add-ticket");
        setModalContent(<ManageTicketForm />);
        showModal();
    }

    return (
        <div className="admin__add-ticket">
            <div className="btn" onClick={() => openForm()}>
                <div className="icon">
                    <IonIcon src={AddTicketIcon} />
                </div>
                <div className="text">
                    <TranslatedString tid="admin-ticket_add-ticket" />
                </div>
            </div>
        </div>
    );
}

export default AddTicket;
