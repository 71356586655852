import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { Exhibitor } from '../../interfaces/IExhibitor';
import { AppContext } from '../../reducers/AppContext';
import TranslatedString from '../general/TranslatedString';

interface Props {
    exhibitor: Exhibitor

    fullWidth?: boolean
}

const SilverExhibitorCard: React.FC<Props> = ({ exhibitor, fullWidth = false }) => {
    const history = useHistory();
    const { eventId } = useContext(AppContext);

    return (
        <div className="silver-exhibitor-card"
            style={fullWidth ? { width: "100%", maxWidth: "100%", padding: 0 } : {}}
            onClick={() =>
                history.push(`/my/${eventId}/exhibitors/${exhibitor.id}/schedule-meeting`)
            }>
            <div className="label">
                <TranslatedString tid="exhibitors_silver-label" />
            </div>
            <div style={exhibitor?.color ? { borderColor: exhibitor.color } : {}}
                className="content">
                <img src={exhibitor.bannerImg?.url} alt="" />
                {exhibitor?.cta && (<div style={exhibitor?.color ? { color: exhibitor.color } : {}}
                    className="cta">
                    {exhibitor.cta}
                </div>)}
            </div>
        </div>
    );
}

export default SilverExhibitorCard;
