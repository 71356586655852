import { IonInput } from '@ionic/react';
import React from 'react';
import MixButton from './MixButton';

interface Props {
    height?: number
    value?: string
    onChange?: (...args: any) => void
    className?: string
}

const SearchBar: React.FC<Props> = ({ height = 32, value, onChange, className = "" }) => {
    return (
        <div className={`fc__search-bar ${className}`} style={{ height: height }}>
            <IonInput
                type="search"
                value={value}
                onIonChange={onChange}
                className="ui__input"
                placeholder="Search" />
            <MixButton>
                Go
            </MixButton>
        </div>
    );
}

export default SearchBar;
