import React, { useContext } from 'react';
import { LocalizationContext } from '../../reducers/LocalizationContext';
import Tooltip from './Tooltip';

interface Props {
    title: string
    writeText: string
}

const CopyToClipboard: React.FC<Props> = ({ title, writeText, children }) => {
    const { dictionary } = useContext(LocalizationContext);

    return (
        <Tooltip
            title={title}
            titleOnClick={dictionary["general_copied-to-clipboard"]}
            onClick={() => navigator.clipboard.writeText(writeText)}>
            {children}
        </Tooltip>
    );
}

export default CopyToClipboard;
