import { IonIcon } from '@ionic/react';
import React, { useContext } from 'react';
import AddTicketIcon from '../../../assets/icons/ic-admin-btn-add-ticket.svg';
import { PopupContext } from '../../../reducers/PopupContext';
import TranslatedString from '../../general/TranslatedString';
import ManageScheduleItemForm from './ManageScheduleItemForm';

const AddScheduleItem: React.FC = () => {
    const { setModalContent, showModal } = useContext(PopupContext);

    const openForm = () => {
        setModalContent(<ManageScheduleItemForm />);
        showModal();
    }

    return (
        <div className="admin__add-schedule-item">
            <div className="btn" onClick={() => openForm()}>
                <div className="icon">
                    <IonIcon src={AddTicketIcon} />
                </div>
                <div className="text">
                    <TranslatedString tid="admin-schedule_add-schedule-item" />
                </div>
            </div>
        </div>
    );
}

export default AddScheduleItem;
