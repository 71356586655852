import { IonText } from "@ionic/react";
import React from "react";
import { Emojione } from "react-emoji-render";
import TranslatedString from "./components/general/TranslatedString";
import { ExhibitorOffering, ExhibitorOfferingComposer } from "./interfaces/IExhibitor";
import { FileComposer, StorageFile } from "./interfaces/IFile";

export function sliceRGB(hex: string) {
  let r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);
  return { r, g, b }
}

export function hexToRGB(hex: string, alpha?: number) {
  let { r, g, b } = sliceRGB(hex);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}

export function mutateColor(hex: string, tone?: number, alpha?: number) {
  let { r, g, b } = sliceRGB(hex);

  if (!tone) tone = 10;

  if (alpha) {
    return "rgba(" + (tone * r / 10) + ", " + (tone * g / 10) + ", " + (tone * b / 10) + ", " + alpha + ")";
  } else {
    return "rgb(" + (tone * r / 10) + ", " + (tone * g / 10) + ", " + (tone * b / 10) + ")";
  }
}

export function isIsoDate(str: string) {
  let d = new Date(str);
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
  return d.toISOString() === str;
}

export function mapToFileComposer(file: StorageFile | undefined): FileComposer {
  if (!file) {
    return null as any;
  }
  return {
    files: null as any,
    url: file.url
  }
}

export function containsObject(obj: any, list: any) {
  var i;
  for (i = 0; i < list.length; i++) {
    if (list[i] === obj) {
      return true;
    }
  }

  return false;
}

export function mapToExhibitorOfferingComposer(offering: ExhibitorOffering): ExhibitorOfferingComposer {
  if (!offering) {
    return null as any;
  }
  return {
    name: offering.name,
    description: offering.description,
    file: mapToFileComposer(offering.file)
  }
}

export function urlify(text: string) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  return text
    .split(urlRegex)
    .map((part, index) => {
      if (part.match(urlRegex)) {
        return (
          <a key={index} href={part} target="_blank" rel="noreferrer noopener">{part}</a>
        );
      }
      return <Emojione key={index} text={part} onlyEmojiClassName="make-emojis-large" />;
    });
}

export function renderErrorMessage(errors: any, key: string) {
  let result = (errors as any)[key];
  return <IonText color="danger">{result?.message || <TranslatedString tid="general_required-field" />}</IonText>
}
