import React from 'react';

interface Props {
    title?: string
    className?: string
}

const MixCard: React.FC<Props> = ({ title, className = "", children }) => {
    return (
        <div className={`fc__card ${className}`}>
            <div className="name">
                {title}
            </div>
            <div className="content">
                {children}
            </div>
        </div>
    );
}

export default MixCard;
