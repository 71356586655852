import React, { useContext, useState } from 'react';
import { AppContext } from '../../reducers/AppContext';
import Placeholder from '../../assets/headerImg_placeholder.jpg';

interface Props {

}

const EventBanner: React.FC<Props> = () => {
    const { eventProps } = useContext(AppContext);
    const [shrunk, setShrunk] = useState(false);

    return (eventProps && eventProps?.headerImg) ? (
        <div className={"fc__event-banner" + (shrunk ? " shrunk" : "")} slot="fixed">
            <img src={eventProps.headerImg.url ? eventProps.headerImg.url : Placeholder} alt="" />
        </div>
    ) : null;
}

export default EventBanner;
