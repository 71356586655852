import { IonLoading } from '@ionic/react';
import React, { useContext } from 'react'
import { Redirect, Route, RouteProps } from 'react-router';
import { AuthContext } from '../reducers/AuthContext';

export default function AuthGuard(props: RouteProps) {
    const user = useContext(AuthContext);

    if (user === undefined) {
        return <IonLoading isOpen />
    } else if (user === null) {
        return <Redirect to="/find-events" />
    }

    return <Route {...props} />;
}
