import {DatabaseService} from "../database/database.service"
import {CLIENT_ID, DB_COLLECTIONS} from "../../configs/constants"
import {AnalyticsPreference, User} from "../../interfaces/IUser"
import {auth} from "../../configs/firebase"
import {EventService} from "../event/event.service"
import firebase, {User as FbUser} from "firebase";
import {environment} from "../../configs/environment"


const authStatusR = new (class AuthStatus {

    private user: FbUser = null as any;

    constructor() {
        firebase.auth().onAuthStateChanged((user) => {
            this.user = user as FbUser;
        })
    }


    public subscribe(cb: (user: FbUser | null) => void) {
        return this.user
    }

})()
//     ,
//     get: new Promise((res, rej) => {

//     })
// }

const getUser = async (userId: string, eventId: string) => {
    return DatabaseService.getDoc<User>(DB_COLLECTIONS.getUserPath(eventId, userId))
}

const getAdmin = async (userId: string, eventId: string) => {
    return DatabaseService.getDoc<User>(DB_COLLECTIONS.getAdminCol(eventId, userId))
}

const loginUser = async (userNip: string, eventCode: string, authMethod: string) => {
    switch (authMethod) {
        case 'nip_legacy':
            console.warn('Legacy nip to be removed on the november 1st 2021')
            return await loginUserLegacy(userNip, eventCode);
        case 'nip':
            return await nipLoginUser(userNip, eventCode);
    }
}

// To be removed on the november 1st 2021
const loginUserLegacy = async (userNip: string, eventCode: string) => {
    const username = `${userNip.substr(0, userNip.length - 6)}@${eventCode}.com`;
    let password = userNip.substr(userNip.length - 6);
    await auth.signInWithEmailAndPassword(username, password);
    await EventService.cacheEventId(eventCode);
    return eventCode;
}

const nipLoginUser = async (userNip: string, eventCode: string) => {
    const username = `${userNip.substr(0, userNip.length - 8)}@${eventCode}.com`;
    let password = userNip.substr(userNip.length - 8);
    await auth.signInWithEmailAndPassword(username, password.toUpperCase());
    await EventService.cacheEventId(eventCode);
    return eventCode;
}

const setAnalyticsPreference = async (eventId: string, enrolledInAnalytics: AnalyticsPreference) => {
    //return DatabaseService.upsert(DB_COLLECTIONS.getUserPath(eventId, userId), {enrolledInAnalytics: enrolledInAnalytics})
    const token = await auth.currentUser?.getIdToken();
    return await fetch(`${environment.apiUrl}/enrolledInAnalytics`, {
        method: 'POST',
        body: JSON.stringify({
            token: token,
            eventCode: eventId,
            enrolledInAnalytics: enrolledInAnalytics,
            clientId: CLIENT_ID
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(res => res.json())
        .then((resp) => {
            console.log(resp)
        })
        .catch((err) => "");
}

const loginAdmin = (email: string, password: string, eventCode: string) => {
    return auth.signInWithEmailAndPassword(email, password).then(() => {
        return EventService.cacheEventId(eventCode).then(() => {
            return eventCode
        })
    })
}

const sendTextMessageAuth = (phoneNumber: string, recaptcha: firebase.auth.RecaptchaVerifier) => {
    return auth.signInWithPhoneNumber(phoneNumber, recaptcha);
}

const phoneLoginUser = (eventCode: string) => {
    return EventService.cacheEventId(eventCode);
}

const validSession = async (authUser: firebase.User, userData: User) => {
    let response = 'invalid'
    const path = `clients/${await EventService.getEventId()}/Users/${auth.currentUser?.uid}`
    const status = await isActiveSession(authUser, path, userData);
    switch (status) {
        case 'active':
            response = status;
            return;
        case 'available':
            activateSession();
            response = status;
            return;
    }
    return response;
}


const activateSession = async (activeSession?: string) => {
    const eventId = await EventService.getEventId();
    auth.currentUser?.getIdToken().then(async (res) => {
        return await fetch(`${environment.apiUrl}/updateUserToken`, {
            method: 'POST',
            body: JSON.stringify({
                token: res,
                eventCode: eventId,
                refreshedToken: activeSession ? activeSession : auth.currentUser?.refreshToken,
                clientId: CLIENT_ID
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        }).catch((e) => {
            console.log(e.message)
        })
    })
}

const isActiveSession = (authUser: firebase.User, path: string, userDoc: User) => {
    if (userDoc.activeSession === authUser.refreshToken) {
        return 'active';
    } else if (userDoc.activeSession === '' || userDoc.activeSession === undefined || userDoc.activeSession === 'available') {
        return 'available'
    } else {
        return 'revoked'
    }
}

const clearSession = async () => {
    activateSession('available')
}

const logOut = async () => {
    console.log("logout being called...");
    clearSession().then(async () => {
        await auth.signOut();
        await EventService.clearLocalEventId();
    })
}

const doubleLogOut = async () => {
    await auth.signOut();
    await EventService.clearLocalEventId();
}

export const UserService = {
    getUser,
    getAdmin,
    setAnalyticsPreference,
    loginUser,
    loginAdmin,
    phoneLoginUser,
    validSession,
    sendTextMessageAuth,
    logOut,
    doubleLogOut,
    activateSession
}