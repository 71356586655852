import { IonButton } from '@ionic/react';
import React, { useContext } from 'react';
import { EventProps } from '../../interfaces/IEvent';
import { AppContext } from '../../reducers/AppContext';

interface Props {
  onClick?: (...args: any) => void
  disabled?: boolean
  className?: string
  submit?: boolean
  color?: string

  eventProps?: EventProps
}

const MixButton: React.FC<Props> = ({ children, className = "", disabled = false, onClick = () => null, submit = false, color = "", eventProps = null }) => {
  let eventColor = useContext(AppContext).eventProps?.eventColor;

  if (eventProps) {
    eventColor = eventProps?.eventColor;
  }

  return (
    <IonButton
      className={`ui__button ${className}`}
      style={{
        '--background': eventColor && eventColor,
        '--background-hover': eventColor && eventColor,
        '--background-activated': eventColor && eventColor,
        '--color': color === 'transparent' && eventColor,
        color: color === 'transparent' && eventColor
      }}
      onClick={onClick}
      disabled={disabled}
      type={submit ? "submit" : "button"}
      color={color}>
      { children}
    </IonButton >
  );
}

export default MixButton;
