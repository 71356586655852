import { IonSelectOption } from '@ionic/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Exhibitor } from '../../../interfaces/IExhibitor';
import { ScheduleItem } from '../../../interfaces/ISchedule';
import { User } from '../../../interfaces/IUser';
import { AppContext } from '../../../reducers/AppContext';
import { LocalizationContext } from '../../../reducers/LocalizationContext';
import { PopupContext } from '../../../reducers/PopupContext';
import { AdminService } from '../../../services/admin/admin.service';
import { ExhibitorService } from '../../../services/exhibitor/exhibitor.service';
import { ScheduleService } from '../../../services/schedule/schedule.service';
import OldInputController from '../../forms/OldInputController';
import OldSelectController from '../../forms/OldSelectController';
import Heading from '../../general/Heading';
import MixButton from '../../general/MixButton';
import TranslatedString from '../../general/TranslatedString';
import DisplayNIP from './DisplayNIP';

type Inputs = {
    privileges: string[],
    presentingFor?: string[],
    exhibitorFor?: string,
    lastName: string,
    firstName: string,
    company: string,
    title: string,
    mobile: string,
    email: string
}

interface Props {
    user?: User
}

const ManageTicketForm: React.FC<Props> = ({ user = null }) => {
    const { eventId, setLoading } = useContext(AppContext);
    const { setModalContent, setModalCssClass, hideModal, setToastProps, setAlertProps, showAlert } = useContext(PopupContext);
    const { dictionary } = useContext(LocalizationContext);

    const [displayPresenter, setDisplayPresenter] = useState(false);
    const [displayExhibitor, setDisplayExhibitor] = useState(false);
    const [scheduleItems, setScheduleItems] = useState<ScheduleItem[]>();
    const [exhibitors, setExhibitors] = useState<Exhibitor[]>();
    const initialPresentingFor = useRef(user?.presenter ? user.presenter : []);
    const initialExhibitorFor = useRef(user?.exhibitor ? user.exhibitor : '');

    const initialValues: any = {
        privileges: [
            user?.admin ? "admin" : null,
            user?.presenter ? "presenter" : null,
            user?.exhibitor ? "exhibitor" : null,
            user?.standard ? "standard" : null,
        ],
        presentingFor: initialPresentingFor.current,
        exhibitorFor: initialExhibitorFor.current,
        lastName: user?.lastName,
        firstName: user?.firstName,
        company: user?.company,
        title: user?.title,
        mobile: user?.phone,
        email: user?.email
    };

    const { handleSubmit, errors, control, reset, getValues, watch } = useForm<Inputs>({
        defaultValues: initialValues
    });

    useEffect(() => {
        if (getValues("privileges").indexOf("presenter") > -1) {
            setDisplayPresenter(true);
            return ScheduleService.getScheduleItems(eventId, setScheduleItems);
        } else {
            setDisplayPresenter(false);
        }
    }, [watch().privileges]);

    useEffect(() => {
        if (getValues("privileges").indexOf("exhibitor") > -1 && eventId) {
            setDisplayExhibitor(true);
            ExhibitorService.getExhibitors(eventId).then(setExhibitors);
        } else {
            setDisplayExhibitor(false);
        }
    }, [watch().privileges, eventId]);

    const onSubmit = (data: any) => {
        const body = {
            id: user?.id ? user.id : "TEMP_ID",

            firstName: data.firstName,
            lastName: data.lastName,
            email: data?.email,
            phone: data?.mobile,
            company: data?.company,
            title: data?.title,

            standard: data.privileges.includes("standard") ? true : false,
            exhibitor: data.privileges.includes("exhibitor") ? data?.exhibitorFor : null,
            presenter: data.privileges.includes("presenter") ? data?.presentingFor : null,
            admin: data.privileges.includes("admin") ? true : false,

            enrolledInAnalytics: 0
        } as User;

        if (!body.standard && !body.exhibitor && !body.presenter && !body.admin) {
            setAlertProps({
                header: dictionary["general_error"],
                subHeader: dictionary["admin-ticket_error-privilege-description"],
                backdropDismiss: false,
                buttons: ['ok']
            });
            showAlert();
            return
        }

        setLoading(true);
        if (user) {
            AdminService.updateTicket(body, user.id, eventId).then(async () => {
                const name = user?.firstName + ' ' + user?.lastName;

                const newPresentingFor = data?.presentingFor?.filter((id: string) => !initialPresentingFor.current.includes(id));
                const oldPresentingFor = initialPresentingFor.current.filter((id: string) => !data.presentingFor?.includes(id));


                if (exhibitors) {
                    await Promise.all(exhibitors.map((exh) => {
                        const body = {
                            name: exh.name,
                            description: exh.description,
                            exhibitorType: exh.exhibitorType,
                            cta: exh.cta,
                            admins: exh.admins,
                            offerings: exh.offerings,
                            sliderImages: exh.sliderImages
                        } as Exhibitor;

                        if (exh.admins?.includes(user.id) && data.exhibitorFor !== exh.id) {
                            body.admins = body.admins.filter((_id) => _id !== user.id);
                            return AdminService.updateExhibitor(body, eventId, exh.id as string);
                        } else if (data.exhibitorFor === exh.id) {
                            body.admins.push(user.id)
                            return AdminService.updateExhibitor(body, eventId, exh.id as string)
                        } else {
                            return;
                        }
                    }))
                }
                if (scheduleItems) {
                    await Promise.all(scheduleItems.map((item) => {
                        let presenters = item.presenters;

                        if (oldPresentingFor?.includes(item.id as string)) {
                            presenters = presenters.filter((_id) => _id !== user.id);
                        } else if (newPresentingFor?.includes(item.id as string)) {
                            presenters.push(user.id);
                        } else {
                            return;
                        }

                        const scheduleItemBody = {
                            id: item.id,
                            type: item.type,
                            startsAt: item.startsAt,
                            endsAt: item.endsAt,
                            name: item.name,
                            location: item.location,
                            presenters: presenters
                        };

                        return AdminService.updateScheduleItem(scheduleItemBody, eventId);
                    }))
                }

                setLoading(false);
                reset();
                hideModal();
                setToastProps({
                    isOpen: true,
                    message: `${dictionary["admin-ticket_successfully-edited"]} ${name}`,
                    duration: 3000,
                    position: "bottom"
                });
            });
        } else {
            AdminService.createTicket(body, eventId).then(async ({ nip, id: userId }) => {
                setLoading(false);
                reset();
                setModalCssClass("add-ticket-success");
                setModalContent(
                    <DisplayNIP res={nip} />
                );

                if (data.exhibitorFor && exhibitors) {
                    const exh = exhibitors.find(({ id }) => id === data.exhibitorFor);
                    if (!exh) return;
                    const body = {
                        name: exh.name,
                        description: exh.description,
                        exhibitorType: exh.exhibitorType,
                        cta: exh.cta,
                        admins: (exh.admins || []).concat(userId),
                        offerings: exh.offerings,
                        sliderImages: exh.sliderImages
                    } as Exhibitor;

                    await AdminService.updateExhibitor(body, eventId, exh.id as string)
                }


                if (data?.presentingFor?.length) {
                    const updates: Promise<any>[] = [];
                    data.presentingFor.forEach((id: string) => {
                        const item = scheduleItems?.find((a) => a.id === id);
                        if (item) {
                            let presenters: string[] = [];
                            presenters.concat(item.presenters as string[]);

                            if (item.presenters && typeof userId === "string" && !item.presenters.includes(userId)) {
                                presenters.push(userId);
                            }

                            const scheduleItemBody = {
                                id: item.id,
                                type: item.type,
                                startsAt: item.startsAt,
                                endsAt: item.endsAt,
                                name: item.name,
                                location: item.location,
                                presenters: presenters
                            };

                            updates.push(AdminService.updateScheduleItem(scheduleItemBody, eventId));
                        }
                    });
                    return Promise.all(updates);
                }
            })
        }
    };

    return (
        <React.Fragment>
            <Heading level={2}>
                {user ? dictionary["admin-ticket_edit-ticket"] : dictionary["admin-ticket_add-ticket"]}
            </Heading>
            <form className="ui__group" onSubmit={handleSubmit(onSubmit)}>
                <OldSelectController name="privileges" label={dictionary["privileges_label"]} multiple control={control}
                    errors={errors} errorField={errors.privileges} defaultValue={initialValues.privileges}
                    required>
                    <IonSelectOption value="standard">
                        <TranslatedString tid="privileges_standard" />
                    </IonSelectOption>
                    <IonSelectOption value="exhibitor">
                        <TranslatedString tid="privileges_exhibitor" />
                    </IonSelectOption>
                    <IonSelectOption value="presenter">
                        <TranslatedString tid="privileges_presenter" />
                    </IonSelectOption>
                    {/* <IonSelectOption value="organizer">Organizer</IonSelectOption> */}
                    <IonSelectOption value="admin">
                        <TranslatedString tid="privileges_admin" />
                    </IonSelectOption>
                </OldSelectController>

                {
                    displayPresenter &&
                    (
                        <OldSelectController name="presentingFor" label={dictionary["admin-schedule_presenting-on"]}
                            multiple control={control}
                            errors={errors} errorField={errors.presentingFor}
                            defaultValue={initialValues.presentingFor}
                            required>
                            {
                                scheduleItems?.map((item, index) => (
                                    <IonSelectOption value={item.id} key={index}>
                                        {item?.name}
                                    </IonSelectOption>
                                ))
                            }
                        </OldSelectController>
                    )
                }

                {
                    displayExhibitor &&
                    (
                        <OldSelectController name="exhibitorFor" label={dictionary["admin-schedule_exhibitor-for"]}
                            control={control}
                            errors={errors} errorField={errors.exhibitorFor}
                            defaultValue={initialValues.exhibitorFor}
                            required>
                            {
                                exhibitors?.map((item, index) => (
                                    <IonSelectOption value={item.id} key={index}>
                                        {item?.name}
                                    </IonSelectOption>
                                ))
                            }
                        </OldSelectController>
                    )
                }

                <div className="ui__row">
                    <div className="ui__col">
                        <Heading level={2}>
                            <TranslatedString tid="general_general" />
                        </Heading>
                        <OldInputController name="lastName" label={dictionary["admin-ticket_last-name"]} control={control}
                            errors={errors} errorField={errors.lastName}
                            defaultValue={initialValues.lastName} required />
                        <OldInputController name="firstName" label={dictionary["admin-ticket_first-name"]}
                            control={control} errors={errors} errorField={errors.firstName}
                            defaultValue={initialValues.firstName} required />
                        <OldInputController name="company" label={dictionary["admin-ticket_company"]} control={control}
                            errors={errors} errorField={errors.company}
                            defaultValue={initialValues.company} />
                        <OldInputController name="title" label={dictionary["admin-ticket_title"]} control={control}
                            errors={errors} errorField={errors.title} defaultValue={initialValues.title} />
                    </div>
                    <div className="ui__col">
                        <Heading level={2}>
                            <TranslatedString tid="general_contacts" />
                        </Heading>
                        <OldInputController type="tel" name="mobile" label={dictionary["general_mobile"]} control={control}
                            errors={errors} errorField={errors.mobile} defaultValue={initialValues.mobile}
                            required pattern={{
                                value: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i,
                                message: "- Invalid phone number"
                            }} />
                        <OldInputController type="email" name="email" label={dictionary["general_email"]} control={control}
                            errors={errors} errorField={errors.email} pattern={{
                                value: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+(?:[A-Z]{2}|com|org|net|gov|mil|biz|info|mobi|name|aero|jobs|museum|xyz|health)\b/i,
                                message: " — Invalid e-mail address"
                            }} defaultValue={initialValues.email} required />
                    </div>
                </div>

                <div className="ion-marign-top">
                    <MixButton submit>
                        {user ? dictionary["admin-ticket_edit-ticket"] : dictionary["admin-ticket_add-ticket"]}
                    </MixButton>
                </div>
            </form>
        </React.Fragment>
    );
}

export default ManageTicketForm;
