import fr_ca from './fr_ca.json';
import en_us from './en_us.json';
// import es_mx from './es_mx.json';

export const dictionaryList: any = {
    fr_ca,
    en_us,
    // es_mx
};

export const languageOptions: any = {
    fr_ca: 'Français',
    en_us: 'English',
    // es_mx: 'Español'
};
