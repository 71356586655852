import { FileComposer, StorageFile } from "./IFile";

export enum ExhibitorQuestionTypes {
  TextInput = "textInput",
  Checkbox = 'checkbox',
  DropDown = 'dropdown'
}

export interface ExhibitorQuestion {
  text: string;
  type: ExhibitorQuestionTypes,
  options?: any;
}

export interface ExhibitorQuestionResponse {
  [id: string]: any
}

export interface Exhibitor {
  id?: string,
  name: string,
  description?: string,
  boothId: string,
  admins: string[],
  availableAppointments: string[],
  bannerImg?: StorageFile,
  sliderImages?: StorageFile[],
  videoLink?: StorageFile,

  offerings: ExhibitorOffering[],

  color?: string,
  exhibitorType?: "gold" | "silver" | "bronze" | "standard" | "partner" | "" | undefined,
  cta?: string,
  ctaImg?: StorageFile,
  voiceChat?: "available" | "busy" | "not available",
  questionnaire?: ExhibitorQuestion[],
  meetingInterval?: 15 | 30 | 45 | 60
}

export interface ExhibitorOffering {
  name: string,
  description: string,
  file?: StorageFile;
}

export interface ExhibitorOfferingComposer {
  name: string,
  description: string,
  file?: FileComposer;
}

export interface ExhibitorComposer {
  exhibitorType: string,
  bannerImg: FileComposer,
  ctaImg: FileComposer,
  companyName: string,
  heroVideo: FileComposer,
  cta: string,
  longDescription: string,
  offerings?: ExhibitorOfferingComposer[],
  galleryItems: FileComposer,
  meetingInterval: 15 | 30 | 45 | 60
}

export interface ExhibitorVoiceChat {
  id?: string,
  roomName: string,
  state: "opened" | "ongoing" | "closed"
}