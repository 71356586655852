import { IonAlert, IonContent, IonIcon, IonToast } from '@ionic/react';
import React, { createContext, useState } from 'react';
import CloseIcon from '../assets/icons/ic-close.svg';
import { Fade, Modal } from "@material-ui/core";

const defaultShowAlert: any = () => {
};
const defaultHideAlert: any = () => {
};
const defaultAlertProps = {
    header: "",
    subHeader: "",
    backdropDismiss: false,
    buttons: [],
    cssClass: "",
    message: "",
    translucent: "",
    mode: null as any
} as any;
const defaultSetAlertProps: any = () => {
};

const defaultShowModal: any = () => {
};
const defaultHideModal: any = () => {
};
const defaultModalContent = <React.Fragment></React.Fragment>;
const defaultSetModalContent: React.Dispatch<React.SetStateAction<JSX.Element>> = () => {
};
const defaultModalProps = {
    closeAfterTransition: false,
    disableBackdropClick: true,
    disablePortal: true,
    disableEnforceFocus: true,
    disableEscapeKeyDown: false,
    disableRestoreFocus: false,
    disableScrollLock: false,
    disableAutoFocus: true,
    hideBackdrop: false,
    keepMounted: false,
} as any;
const defaultSetModalProps: any = () => {
};
const defaultModalCssClass: string = "";
const defaultSetModalCssClass: any = () => {
};

const defaultShowToast: any = () => {
};
const defaultHideToast: any = () => {
};
const defaultToastProps = {
    header: "",
    subHeader: "",
    color: "",
    duration: null as any,
    keyboardClose: false,
    position: "top",
    buttons: [],
    cssClass: "",
    message: "",
    translucent: "",
    mode: null as any
} as any;
const defaultSetToastProps: any = () => {
};

export const PopupContext = createContext({
    showAlert: defaultShowAlert,
    hideAlert: defaultHideAlert,
    alertProps: defaultAlertProps,
    setAlertProps: defaultSetAlertProps,

    showModal: defaultShowModal,
    hideModal: defaultHideModal,
    modalContent: defaultModalContent,
    setModalContent: defaultSetModalContent,
    modalProps: defaultModalProps,
    setModalProps: defaultSetModalProps,
    modalCssClass: defaultModalCssClass,
    setModalCssClass: defaultSetModalCssClass,

    showToast: defaultShowToast,
    hideToast: defaultHideToast,
    toastProps: defaultAlertProps,
    setToastProps: defaultSetToastProps,
});

export const PopupContextProvider: React.FC = ({ children }) => {
    // Alert states
    const [alertProps, setAlertProps] = useState(defaultAlertProps);
    const [alertOpen, setAlertOpen] = useState(false);

    // Modal states
    const [modalProps, setModalProps] = useState(defaultModalProps);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(<div></div>);
    const [modalCssClass, setModalCssClass] = useState(defaultModalCssClass);

    // Toast states
    const [toastProps, setToastProps] = useState(defaultToastProps);
    const [toastOpen, setToastOpen] = useState(false);

    // Alert functions
    const showAlert = () => {
        setAlertOpen(true);
    }
    const hideAlert = () => {
        setAlertOpen(false);
    }

    // Modal functions
    const showModal = () => {
        setModalOpen(true);
    }
    const hideModal = () => {
        setModalOpen(false);
    }

    // Toast functions
    const showToast = () => {
        setToastOpen(true);
    }
    const hideToast = () => {
        setToastOpen(false);
    }

    const provider = {
        showAlert,
        hideAlert,
        alertProps,
        setAlertProps,

        showModal,
        hideModal,
        modalContent,
        setModalContent,
        modalProps,
        setModalProps,
        modalCssClass,
        setModalCssClass,

        showToast,
        hideToast,
        toastProps,
        setToastProps
    }

    return (
        <PopupContext.Provider value={provider}>
            <IonAlert
                isOpen={alertOpen}
                onDidDismiss={() => setAlertOpen(false)}
                {...alertProps}
            />
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                className={`mix-modal ${modalCssClass}`}
                {...modalProps}
            >
                <Fade in={modalOpen}>
                    <IonContent className="modal-content">
                        <div className="btn-close" onClick={() => {
                            hideModal();
                            setModalCssClass("");
                        }}>
                            <IonIcon src={CloseIcon} />
                        </div>
                        {modalContent}
                    </IonContent>
                </Fade>
            </Modal>
            <IonToast
                isOpen={toastOpen}
                onDidDismiss={() => setToastOpen(false)}
                {...toastProps}
            />
            {children}
        </PopupContext.Provider>
    );
}
