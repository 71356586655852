import React from 'react';

interface Props {
    align?: string
    className?: string
}

const TableCell: React.FC<Props> = ({ children, align = "left", className = "" }) => {
    return (
        <td className={"table__cell" + ` ${className}`} style={{ textAlign: align as any }}>
            {children ? children : <div className="none">n/a</div>}
        </td>
    );
}

export default TableCell;
