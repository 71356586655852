import { IonIcon } from '@ionic/react';
import React, { useContext, useState, useEffect, useMemo } from 'react';
import { User } from '../../../interfaces/IUser';
import Heading from '../../general/Heading';
import Table from '../../table/Table';
import TableBody from '../../table/TableBody';
import TableCell from '../../table/TableCell';
import TableHead from '../../table/TableHead';
import TableHeaderCell from '../../table/TableHeaderCell';
import TableRow from '../../table/TableRow';
import { PopupContext } from '../../../reducers/PopupContext';
import ManageTicketForm from './ManageTicketForm';
import algoliasearch from 'algoliasearch';
import { InstantSearch, SearchBox, Configure, connectHits, connectPagination } from 'react-instantsearch-dom';

import EditIcon from '../../../assets/icons/ic-admin-btn-edit.svg';
import PassResetIcon from '../../../assets/icons/ic-admin-btn-pass-reset.svg';
import DeleteIcon from '../../../assets/icons/ic-close.svg';
import NextIcon from '../../../assets/icons/ic-arrow-next.svg';
import PrevIcon from '../../../assets/icons/ic-arrow-prev.svg';
import { AdminService } from '../../../services/admin/admin.service';
import DisplayNIP from './DisplayNIP';
import AddTicket from './AddTicket';
import { ENVIRONMENT } from '../../../configs';
import { LocalizationContext } from '../../../reducers/LocalizationContext';
import TranslatedString from '../../general/TranslatedString';
import CopyToClipboard from '../../general/CopyToClipboard';
import { AppContext } from '../../../reducers/AppContext';

interface Props {
    eventId: string,
    setLoading: (arg: boolean) => void
}

const Hits: React.FC<any> = ({ hits, editTicket, deleteTicket, resetPass }) => {
    console.log('hits: ', hits);

    return useMemo(() => (
        <TableBody>
            {
                hits.map((hit: any, index: number) => (
                    <Hit hit={hit} index={index} key={index} editTicket={editTicket} deleteTicket={deleteTicket} resetPass={resetPass} />
                ))
            }
        </TableBody>
    ), [hits]);
};

const Hit: React.FC<{ hit: any, index: number, editTicket: (u: User) => void, deleteTicket: (u: User) => void, resetPass: (u: User) => void }> = ({ hit, index, editTicket, deleteTicket, resetPass }) => {
    const { eventId, eventProps } = useContext(AppContext);
    const { dictionary } = useContext(LocalizationContext);
    const color = eventProps?.eventColor;
    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        if (hit && hit.objectID) {
            return AdminService.getUser(eventId, hit.objectID, (user) => setUser(user));
        }
    }, [hit]);

    return <TableRow key={`ticket_row_${index}`}>
        <TableCell>
            <CopyToClipboard title={user ? user.lastName : ""} writeText={user ? user.lastName : ""}>
                {user?.lastName}
            </CopyToClipboard>
        </TableCell>
        <TableCell>
            <CopyToClipboard title={user ? user.firstName : ""} writeText={user ? user.firstName : ""}>
                {user?.firstName}
            </CopyToClipboard>
        </TableCell>
        <TableCell>
            <CopyToClipboard title={user ? user.company : ""} writeText={user ? user.company : ""}>
                {(user && user.company ? user.company.length > 16 ? user.company.substr(0, 14) + "..." : user.company : "")}
            </CopyToClipboard>
        </TableCell>
        {/* <TableCell>
            {
                user && user.title &&
                <CopyToClipboard title={user.title} writeText={user.title}>
                    {user?.title.length > 24 ? user.title.substr(0, 21) + "..." : user.title}
                </CopyToClipboard>
            }
        </TableCell> */}
        <TableCell>
            {
                user && user.phone &&
                <CopyToClipboard title={user.phone} writeText={user.phone}>
                    {user.phone}
                </CopyToClipboard>
            }
        </TableCell>
        <TableCell>
            {
                user &&
                <CopyToClipboard title={user.email} writeText={user.email}>
                    {user.email?.length > 16 ? user.email.substr(0, 14) + "..." : user.email}
                </CopyToClipboard>
            }
        </TableCell>
        {/* <TableCell>
            {
                user?.admin &&
                dictionary["privileges_admin"]?.concat(" ")
            }
            {
                user?.presenter &&
                dictionary["privileges_presenter"]?.concat(" ")
            }
            {
                user?.exhibitor &&
                dictionary["privileges_exhibitor"]?.concat(" ")
            }
            {
                user?.standard &&
                dictionary["privileges_standard"]
            }
        </TableCell> */}
        <TableCell align="center">
            {
                user &&
                <IonIcon
                    className="btn-edit"
                    src={EditIcon}
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={() => editTicket(user)}
                />
            }
        </TableCell>
        <TableCell align="center">
            {
                user &&
                <IonIcon
                    style={color ? { color: color } : {}}
                    className="btn-pass-reset"
                    src={PassResetIcon}
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={() => resetPass(user)}
                />
            }
        </TableCell>
        <TableCell align="center">
            {
                user &&
                <IonIcon
                    className="btn-delete"
                    src={DeleteIcon}
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={() => deleteTicket(user)}
                />
            }
        </TableCell>
    </TableRow>
}

const Pagination: React.FC<any> = ({
    currentRefinement,
    nbPages,
    refine
}) => {
    const color = useContext(AppContext).eventProps?.eventColor;
    const maxPages: number = 5;

    return <div className="admin__table-pagination">
        <div className={`btn prev${currentRefinement === 1 ? " disabled" : ""}`}
            style={(color && currentRefinement !== 1) ? { color: color } : {}}
            onMouseDown={(e) => e.preventDefault()}
            onClick={() => {
                if (currentRefinement > 1) {
                    refine(currentRefinement - 1);
                }
            }}
        >
            <IonIcon src={PrevIcon} />
            <div className="text">
                <TranslatedString tid="general_prev" />
            </div>
        </div>
        <div className="pages">
            {new Array(nbPages).fill(null).map((_, index) => {
                const page = index + 1;
                let minimum = currentRefinement - 2;
                let maximum = currentRefinement + 2;
                while (minimum < 1) {
                    ++minimum;
                    ++maximum;
                }
                while (nbPages > maxPages && maximum > nbPages) {
                    --minimum;
                    --maximum;
                }
                if (page >= minimum && page <= maximum) {
                    return <div className="btn page"
                        style={(color && currentRefinement === page) ? {
                            borderColor: color,
                            color: color
                        } : {}}
                        key={`pagination_page_btn_${index}`}
                        onClick={() => {
                            refine(page);
                        }}
                    >
                        {page}
                    </div>
                }
            })}
        </div>
        <div className={`btn next${currentRefinement === nbPages ? " disabled" : ""}`}
            style={(color && currentRefinement !== nbPages) ? { color: color } : {}}
            onClick={() => {
                if (currentRefinement < nbPages) {
                    refine(currentRefinement + 1);
                }
            }}
            onMouseDown={(e) => e.preventDefault()}
        >
            <div className="text">
                <TranslatedString tid="general_next" />
            </div>
            <IonIcon src={NextIcon} />
        </div>
    </div>
};

const CustomHits = React.memo(connectHits(Hits));
const CustomPagination = connectPagination(Pagination);
const searchClient = algoliasearch('OYRUYP0M2P', '1738256914c44966ddc28ae8c46097ba');

const TicketsTable: React.FC<Props> = ({ eventId, setLoading }) => {
    const { setModalContent, setModalCssClass, showModal, setAlertProps, showAlert, setToastProps } = useContext(PopupContext);
    const { dictionary } = useContext(LocalizationContext);
    const searchPath = `${ENVIRONMENT}_${eventId}_Users`;

    const editTicket = (user: User) => {
        setModalCssClass("add-ticket");
        setModalContent(
            <ManageTicketForm user={user} />
        );
        showModal();
    }

    const resetPass = (user: User) => {
        setAlertProps({
            header: dictionary["admin-ticket_reset-password-heading"],
            subHeader: user?.firstName + " " + user?.lastName,
            message: dictionary["admin-ticket_reset-password-message"],
            buttons: [
                {
                    text: dictionary["general_cancel"],
                    role: 'cancel'
                },
                {
                    text: dictionary["general_reset"],
                    cssClass: 'danger',
                    handler: () => {
                        setLoading(true);
                        AdminService.resetTicket(user?.id, eventId).then((res) => {
                            setLoading(false);
                            setModalCssClass("add-ticket-success");
                            setModalContent(<DisplayNIP res={res} />);
                            showModal();
                        });
                    }
                }
            ]
        });
        showAlert();
    }

    const deleteTicket = (user: User) => {
        setAlertProps({
            header: dictionary["general_delete-item"],
            subHeader: user?.firstName + " " + user?.lastName,
            message: dictionary["admin-ticket_delete-prompt"],
            buttons: [
                {
                    text: dictionary["general_cancel"],
                    role: 'cancel'
                },
                {
                    text: dictionary["general_delete"],
                    cssClass: 'danger',
                    handler: () => {
                        setLoading(true);
                        const name = user?.firstName + ' ' + user?.lastName;
                        AdminService.deleteTicket(user?.id, eventId).then(() => {
                            setLoading(false);
                            setToastProps({
                                isOpen: true,
                                message: `${dictionary["admin-ticket_deleted"]} - ${name}`,
                                duration: 3000,
                                position: "bottom"
                            });

                        });
                    }
                }
            ]
        });
        showAlert();
    }

    const memoHits = useMemo(() => (
        <CustomHits editTicket={editTicket} deleteTicket={deleteTicket} resetPass={resetPass} />
    ), []);

    return (
        <div className="admin__ticket-table">
            <InstantSearch searchClient={searchClient} indexName={searchPath}>
                <Configure
                    hitsPerPage={10}
                />
                <div className="header">
                    <Heading>
                        {dictionary['general_admin-page']}: <TranslatedString tid="admin-ticket_heading" />
                    </Heading>
                    <AddTicket />
                    <SearchBox translations={{
                        placeholder: dictionary["general_search-placeholder"]
                    }} />
                </div>
                <div className="content">
                    <Table className="ticket-table">
                        <TableHead>
                            <TableRow>
                                <TableHeaderCell>
                                    <TranslatedString tid="admin-ticket_last-name" />
                                </TableHeaderCell>
                                <TableHeaderCell>
                                    <TranslatedString tid="admin-ticket_first-name" />
                                </TableHeaderCell>
                                <TableHeaderCell>
                                    <TranslatedString tid="admin-ticket_company" />
                                </TableHeaderCell>
                                {/* <TableHeaderCell>
                                    <TranslatedString tid="admin-ticket_title" />
                                </TableHeaderCell> */}
                                <TableHeaderCell>
                                    <TranslatedString tid="general_mobile" />
                                </TableHeaderCell>
                                <TableHeaderCell>
                                    <TranslatedString tid="general_email" />
                                </TableHeaderCell>
                                {/* <TableHeaderCell>
                                    <TranslatedString tid="admin-ticket_ticket-level" />
                                </TableHeaderCell> */}
                                <TableHeaderCell align="center">
                                    <TranslatedString tid="general_edit" />
                                </TableHeaderCell>
                                <TableHeaderCell align="center">
                                    <TranslatedString tid="admin-ticket_new-pin" />
                                </TableHeaderCell>
                                <TableHeaderCell align="center">
                                    <TranslatedString tid="general_delete" />
                                </TableHeaderCell>
                            </TableRow>
                        </TableHead>
                        {memoHits}
                    </Table>
                    <CustomPagination />
                </div>
            </InstantSearch>
        </div>
    );
}

export default TicketsTable;
