import { IonIcon, IonLoading } from '@ionic/react';
import React, { useContext } from 'react';
import Heading from '../../general/Heading';
import Table from '../../table/Table';
import TableBody from '../../table/TableBody';
import TableCell from '../../table/TableCell';
import TableHead from '../../table/TableHead';
import TableHeaderCell from '../../table/TableHeaderCell';
import TableRow from '../../table/TableRow';
import EditIcon from '../../../assets/icons/ic-admin-btn-edit.svg';
import DeleteIcon from '../../../assets/icons/ic-close.svg';
import { Exhibitor } from '../../../interfaces/IExhibitor';
import Tooltip from '../../general/Tooltip';
import { PopupContext } from '../../../reducers/PopupContext';
import ManageExhibitorForm from './ManageExhibitorForm';
import AddExhibitor from './AddExhibitor';
import { AppContext } from '../../../reducers/AppContext';
import { AdminService } from '../../../services/admin/admin.service';
import { LocalizationContext } from "../../../reducers/LocalizationContext";

interface Props {
    exhibitors?: Exhibitor[]
}

const ExhibitorsTable: React.FC<Props> = ({ exhibitors }) => {
    const { setLoading, eventId } = useContext(AppContext);
    const { setAlertProps, showAlert, setModalContent, setModalCssClass, showModal, setToastProps } = useContext(PopupContext);
    const { dictionary } = useContext(LocalizationContext);

    const deleteItem = (exhibitor: Exhibitor) => {
        setAlertProps({
            header: dictionary['admin-exhibitor_delete-exhibitor'],
            subHeader: exhibitor?.name,
            message: dictionary['admin-exhibitor_delete-confirmation'],
            buttons: [
                {
                    text: dictionary['general_cancel'],
                    role: 'cancel'
                },
                {
                    text: dictionary['general_delete'],
                    cssClass: 'danger',
                    handler: () => {
                        setLoading(true);
                        if (exhibitor.id) {
                            const name = exhibitor?.name;
                            AdminService.deleteExhibitor(eventId, exhibitor.id).then(() => {
                                setLoading(false);
                                setToastProps({
                                    isOpen: true,
                                    message: `"${name}" ${dictionary['admin-exhibitor_success']}`,
                                    duration: 3000,
                                    position: "bottom"
                                });
                            });
                        } else {
                            setLoading(false);
                            setToastProps({
                                isOpen: true,
                                message: dictionary['general_error'],
                                duration: 1000,
                                position: "bottom"
                            });
                        }
                    }
                }
            ]
        });
        showAlert();
    }

    const editItem = (exhibitor: Exhibitor) => {
        setModalContent(<ManageExhibitorForm exhibitor={exhibitor} />);
        showModal();
    }

    return (
        <div className="admin__exhibitors-table">
            <div className="header">
                <Heading>{dictionary['general_admin-page']}: {dictionary['admin_exhibitors']}</Heading>
                <AddExhibitor />
                {/* <SearchBar /> */}
            </div>
            <div className="content">
                <Table className="exhibitors-table">
                    <TableHead>
                        <TableRow>
                            <TableHeaderCell align="center">{dictionary['admin-exhibitor_logo']}</TableHeaderCell>
                            <TableHeaderCell>{dictionary['admin-exhibitor_company']}</TableHeaderCell>
                            <TableHeaderCell>{dictionary['admin-exhibitor_type']}</TableHeaderCell>
                            {/*<TableHeaderCell>{dictionary['admin-exhibitor_meeting']}</TableHeaderCell>*/}
                            {/*<TableHeaderCell>{dictionary['admin-exhibitor_gallery']}</TableHeaderCell>*/}
                            {/*<TableHeaderCell>{dictionary['admin-exhibitor_offerings']}</TableHeaderCell>*/}
                            {/*<TableHeaderCell>{dictionary['admin-exhibitor_banner']}</TableHeaderCell>*/}
                            {/* <TableHeaderCell>Short Desc</TableHeaderCell> */}
                            {/*<TableHeaderCell align="center">Promo Pic</TableHeaderCell>*/}
                            {/* <TableHeaderCell>{dictionary['admin-exhibitor_promo-text']}</TableHeaderCell> */}
                            {/* <TableHeaderCell align="center">{dictionary['admin-exhibitor_hero-video']}</TableHeaderCell> */}
                            <TableHeaderCell>{dictionary['admin-exhibitor_long-desc']}</TableHeaderCell>
                            <TableHeaderCell align="center">{dictionary['admin-exhibitor_both']}</TableHeaderCell>
                            <TableHeaderCell align="center">{dictionary['general_edit']}</TableHeaderCell>
                            <TableHeaderCell align="center">{dictionary['general_delete']}</TableHeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {/* TODO: Add pagination or lazy load */}
                        {
                            exhibitors ? exhibitors
                                .map((exhibitor, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="center">
                                            {
                                                exhibitor.bannerImg && <img src={exhibitor.bannerImg?.url} className="thumbnail-img" alt={`${exhibitor.name} logo`} />
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip
                                                title={exhibitor?.name}
                                                titleOnClick="Copied to clipboard!"
                                                onClick={() => navigator.clipboard.writeText(exhibitor?.name)}>
                                                {exhibitor?.name}
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell className="exhibitor-type">
                                            {
                                                exhibitor?.exhibitorType ? exhibitor.exhibitorType : "standard"
                                            }
                                        </TableCell>
                                        {/*<TableCell>
                                            MEETINGS
                                        </TableCell>*/}
                                        {/* <TableCell>
                                            {
                                                !!exhibitor.sliderImages && exhibitor?.sliderImages?.length > 0 &&
                                                exhibitor.sliderImages.length + " image(s)"
                                            }
                                        </TableCell>*/}
                                        {/* <TableCell>
                                            OFFERINGS
                                        </TableCell>*/}
                                        {/* <TableCell>
                                            {
                                                exhibitor?.bannerImg &&
                                                <a href={exhibitor.bannerImg.url} target="_blank"
                                                   rel="noreferrer noopener">Link</a>
                                            }
                                        </TableCell>*/}
                                        {/* <TableCell>
                                            {
                                                exhibitor.description &&
                                                    exhibitor?.description?.length > 27 ? exhibitor.description.substr(0, 24) + "..." : exhibitor.description
                                            }
                                        </TableCell> */}
                                        {/*<TableCell align="center">
                                            {
                                                exhibitor?.ctaImg &&
                                                <a href={exhibitor.ctaImg.url} target="_blank"
                                                   rel="noreferrer noopener">Link</a>
                                            }
                                        </TableCell>*/}
                                        {/* <TableCell>
                                            {
                                                exhibitor?.cta &&
                                                    exhibitor.cta.length > 27 ? exhibitor.cta.substr(0, 24) + "..." : exhibitor.cta
                                            }
                                        </TableCell>
                                        <TableCell align="center">
                                            {
                                                exhibitor?.videoLink &&
                                                <a href={exhibitor.videoLink.url} target="_blank"
                                                    rel="noreferrer noopener">Link</a>
                                            }
                                        </TableCell> */}
                                        <TableCell>
                                            {
                                                exhibitor?.description &&
                                                    exhibitor.description.length > 27 ? exhibitor.description.substr(0, 24) + "..." : exhibitor.description
                                            }
                                        </TableCell>
                                        <TableCell align="center">
                                            {exhibitor?.boothId}
                                        </TableCell>
                                        <TableCell align="center">
                                            <IonIcon
                                                className="btn-edit"
                                                src={EditIcon}
                                                onMouseDown={(e) => e.preventDefault()}
                                                onClick={() => editItem(exhibitor)} />
                                        </TableCell>
                                        <TableCell align="center">
                                            <IonIcon
                                                className="btn-delete"
                                                src={DeleteIcon}
                                                onMouseDown={(e) => e.preventDefault()}
                                                onClick={() => deleteItem(exhibitor)} />
                                        </TableCell>
                                    </TableRow>
                                )) : <IonLoading isOpen />
                        }

                    </TableBody>
                </Table>
            </div>
        </div>
    );
}

export default ExhibitorsTable;
