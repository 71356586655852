import React, { useContext, useEffect, useState } from 'react';
import AdminNavigation from '../../components/admin/AdminNavigation';
import ScheduleTable from '../../components/admin/schedule/ScheduleTable';
import LayoutPage from '../../components/templates/LayoutPage';
import UIContent from '../../components/UI/UIContent';
import { ScheduleItem } from '../../interfaces/ISchedule';
import { AppContext } from '../../reducers/AppContext';
import { ScheduleService } from '../../services/schedule/schedule.service';

const ScheduleManagementPage: React.FC = () => {
    const { eventId, user } = useContext(AppContext);
    const [scheduleItems, setScheduleItems] = useState<ScheduleItem[]>();

    useEffect(() => {
        if (user?.admin && eventId) {
            return ScheduleService.getScheduleItems(eventId, setScheduleItems)
        }
    }, [eventId, user]);

    return (
        <LayoutPage columns={3}>
            <UIContent>
                <AdminNavigation />
                <ScheduleTable scheduleItems={scheduleItems} />
            </UIContent>
        </LayoutPage>
    );
}

export default ScheduleManagementPage;
