import { IonIcon, IonInput } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { DeleteChatRequestBody, Message, SendChatRequestBody } from '../../interfaces/IChat';
import { AppContext } from '../../reducers/AppContext';
import { ChatService } from '../../services/chat/chat.service';
import MixButton from '../general/MixButton';
import { hexToRGB, urlify } from '../../functions';
import deleteIcon from '../../assets/icons/ic-close.svg';
import dayjs from 'dayjs';
import TranslatedString from '../general/TranslatedString';
import Heading from '../general/Heading';
import { Emojione } from 'react-emoji-render';
import { DB_COLLECTIONS } from '../../configs/constants';

interface ChatProps {
    data: SendChatRequestBody,
    fileShareenabled?: boolean,
    chatEnabled?: boolean
}

const Chat: React.FC<ChatProps> = ({
    data,
    fileShareenabled = true,
    chatEnabled = true
}) => {
    const { user } = useContext(AppContext);

    const { eventProps } = useContext(AppContext);

    const [message, setMessage] = useState<string>();
    const [messages, setMessages] = useState<Message[]>([]);
    const [files, setFiles] = useState<FileList>(null as any);

    let chat: any = document.querySelector('.messages');

    const sendMessage = () => {
        if (message || files) {
            const msg = ChatService.createChatMessage(message, user.firstName + ' ' + user.lastName, files);
            setMessage('');



            ChatService.sendChatMessage(data, msg);
        }
    }

    const deleteMessage = (messageId: string) => {
        if (!!messageId && messageId !== '') {
            const _data: DeleteChatRequestBody = {
                ...data,
                messageId
            }
            ChatService.deleteMessage(_data)
        }
    }

    const DeleteButton: React.FC<{ message: Message }> = ({ message }) => {
        if (user && user.admin) {
            return <div className="delete">
                <IonIcon onClick={() => {
                    if (message?.id) {
                        deleteMessage(message.id)
                    }
                }} src={deleteIcon} />
            </div>
        }
        return <></>
    }

    const timeDisplay = (message: Message) => {
        if (message.createdAt) {
            const createdAt = dayjs(message.createdAt.toDate());
            if (createdAt.isSame(dayjs(), 'day')) {
                return createdAt.format("HH:mm")
            } else return createdAt.format("MM/DD/YYYY HH:mm")
        }
    }

    useEffect(() => {
        if (chat !== null) {
            chat.scrollTop = chat?.scrollHeight;
        }
    }, [messages]);

    useEffect(() => {
        if (data) {
            let path = "";

            if (data.type === 'general') {
                path = DB_COLLECTIONS.getGeneralChat(data.eventId);
            } else if (data.type === 'exhibitor') {
                path = DB_COLLECTIONS.getExhibitorCommentsCol(data.eventId, data.exhibitorId as string);
            } else if (data.type === "scheduleItem") {
                path = DB_COLLECTIONS.getScheduleItemCommentsCol(data.streamId as string, data.eventId);
            }

            return ChatService.getMessages(path, (data) => setMessages(data))
        }

    }, [data]);

    return (
        <div className="chat-box">
            <div className="messages" style={eventProps?.eventColor ? {
                borderTopColor: hexToRGB(eventProps.eventColor, 0.3),
                borderBottomColor: hexToRGB(eventProps.eventColor, 0.3)
            } : {}}>
                {
                    chatEnabled ?
                        messages.map((message, index) => (
                            <div className="message" key={index}>
                                <div className="name" style={{ color: eventProps?.eventColor }}>
                                    {message.name.length > 32 ? message.name.substr(0, 29) + "..." : message.name}
                                </div>
                                <div className="content">
                                    <div className="text">
                                        {urlify(message.text)}
                                    </div>
                                    <div className="time">
                                        {timeDisplay(message)}
                                    </div>
                                </div>
                                <DeleteButton message={message} />
                            </div>
                        ))
                        : (
                            <div className="chat-disabled">
                                <Heading level={2}>
                                    <TranslatedString tid="general_chat-disabled" />
                                </Heading>
                            </div>
                        )
                }
            </div>
            <div className="sender">
                <IonInput className="ui__input"
                    disabled={!chatEnabled}
                    value={message}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            sendMessage();
                        }
                    }}
                    style={eventProps?.eventColor ? { borderTopColor: hexToRGB(eventProps.eventColor, 0.3) } : {}}
                    onIonChange={e => setMessage(e.detail.value!)}>
                    <MixButton className="send-btn"
                        disabled={!chatEnabled}
                        onClick={sendMessage}>
                        <TranslatedString tid="general_send-btn" />
                    </MixButton>
                </IonInput>
            </div>
        </div>
    );
}

export default Chat;
