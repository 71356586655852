import React, { useContext, useState, useEffect } from 'react';
import { IonLoading } from '@ionic/react';
import { AppContext } from '../../reducers/AppContext';
import { RouteComponentProps, useHistory } from 'react-router';
import { VideoChat } from '../../components/videoChat/videoChat';
import { ExhibitorService } from '../../services/exhibitor/exhibitor.service';
import { Exhibitor, ExhibitorQuestionResponse } from '../../interfaces/IExhibitor';
import TranslatedString from '../../components/general/TranslatedString';
import { LocalizationContext } from '../../reducers/LocalizationContext';
import LayoutPage from '../../components/templates/LayoutPage';
import { DB_COLLECTIONS } from '../../configs/constants';
import UIGroup from '../../components/UI/UIGroup';
import { Meeting } from '../../interfaces/IMeeting';
import { MeetingsService } from '../../services/meetings/meetings.service';
import Heading from '../../components/general/Heading';
import { SendChatRequestBody } from '../../interfaces/IChat';

interface VideoChatProps extends RouteComponentProps<{
    eventId: string;
    exhibitorId: string;
    meetingId: string;
}> { }

const VideoChatPage: React.FC<VideoChatProps> = ({ match }) => {
    const { eventProps, user, eventId, setPageChat } = useContext(AppContext);
    const { dictionary } = useContext(LocalizationContext);

    const history = useHistory();
    const [params, setParams] = useState<{ eventId: string, exhibitorId: string, meetingId: string }>({ eventId: "", exhibitorId: "", meetingId: "" });
    const [exhibitor, setExhibitor] = useState<Exhibitor>();
    const [meeting, setMeeting] = useState<Meeting>();

    useEffect(() => {
        setParams({
            eventId: match.params.eventId,
            exhibitorId: match.params.exhibitorId,
            meetingId: match.params.meetingId
        })
        const data: SendChatRequestBody = {
            type: "appointment",
            exhibitorId: match.params.exhibitorId,
            appointmentId: match.params.exhibitorId,
            eventId
        }

        setPageChat({
            // path: DB_COLLECTIONS.getExhibitorAppointementCommentsCol(match.params.eventId, match.params.exhibitorId, match.params.meetingId),
            data,
            name: dictionary["generalChat_meeting"],
            chatEnabled: true,
        });

        return () => {
            setPageChat(null);
        }
    }, []);

    useEffect(() => {
        if (eventId && exhibitor && exhibitor.id && params.meetingId) {
            MeetingsService.getMeetingForExibitor(eventId, exhibitor.id, params.meetingId).then((o) => setMeeting(o as Meeting));
        }
    }, [eventId, exhibitor, params.meetingId]);

    useEffect(() => {
        if (params.eventId) {
            ExhibitorService.getExhibitor(params.eventId, params.exhibitorId).then((o) => setExhibitor(o as Exhibitor));
        }
    }, [params]);

    const [keys, setKeys] = useState<string[]>([]);
    const [values, setValues] = useState<ExhibitorQuestionResponse[]>([]);

    useEffect(() => {
        if (meeting && meeting.questionResponses) {
            setKeys(Object.keys(meeting.questionResponses));
            setValues(Object.values(meeting.questionResponses));
            console.log('keys, values: ', keys, values);
        }
    }, [meeting]);

    if (!(user && eventProps)) {
        return <IonLoading isOpen />
    }

    const metaComponent = (
        <div className="chat-container">
            {
                exhibitor &&
                <React.Fragment>
                    <UIGroup className="logo">
                        {exhibitor?.bannerImg?.url && <img src={exhibitor.bannerImg.url} alt="" />}
                        <div className="company-link" onClick={() => history.push(`/my/${eventId}/exhibitors/${exhibitor.id}/schedule-meeting`)}>
                            <TranslatedString tid="video-chat_company-portfolio" />
                        </div>
                    </UIGroup>
                    {
                        meeting?.questionResponses &&
                        <UIGroup className="questions">
                            <Heading level={3}>
                                <TranslatedString tid="video-chat_your-responses" />
                            </Heading>
                            {
                                keys?.map((key, index) => (
                                    <div className="question" key={index}>
                                        <span className="key">{key}</span> - <span className="value">{values[index]}</span>
                                    </div>
                                ))
                            }
                        </UIGroup>
                    }
                </React.Fragment>
            }
        </div>
    );

    return (
        <LayoutPage
            pageClass="booth-page"
            contentClass="booth__wrapper"
            columns={2}
            reversed

            header={dictionary["video-chat_meeting-with"] + exhibitor?.name}

            metaComponent={metaComponent}

            back
        >
            <div className="video-call">
                <div className="appointment__stream video">
                    <VideoChat roomName={params.meetingId} />
                </div>
            </div>
        </LayoutPage>
    );
};

export default VideoChatPage;
