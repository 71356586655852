import React, { useContext } from 'react';
import { LocalizationContext } from '../../reducers/LocalizationContext';

interface Props {
    tid: string
}

const TranslatedString: React.FC<Props> = ({ tid }) => {
    const { dictionary } = useContext(LocalizationContext);

    return dictionary[tid] || tid;
}

export default TranslatedString;
