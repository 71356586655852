import React, { useContext } from 'react';
import { AppContext } from '../../reducers/AppContext';
import BackButton from '../general/BackButton';
import EventNavigation from '../general/EventNavigation';
import Heading from '../general/Heading';
import MixText from '../general/MixText';
import MixPage from './MixPage';

interface Props {
    columns?: 1 | 2 | 3
    reversed?: boolean
    pageClass?: string
    contentClass?: string
    containerClass?: string
    gridClass?: string

    header?: string
    subHeader?: string
    description?: string

    metaComponent?: JSX.Element
    actionsComponent?: JSX.Element

    back?: boolean
    noMeta?: boolean
}

const LayoutPage: React.FC<Props> =
    ({
        columns = 1,
        reversed = false,
        pageClass,
        contentClass,
        containerClass,
        gridClass,

        header,
        subHeader,
        description,

        metaComponent,
        actionsComponent,

        back = false,
        noMeta = false,

        children
    }) => {
        const { user } = useContext(AppContext);
        return (
            <MixPage pageClass={pageClass} contentClass={contentClass}>
                {
                    user && <EventNavigation />
                }
                <div className={`template__${columns}-col ${reversed ? "reversed" : ""} ${gridClass ? gridClass : ""}`}>
                    {
                        columns > 1 && !noMeta &&
                        <aside className="sidebar-left page-meta">
                            <div className="content">
                                {header && <Heading level={2} className="header">{header}</Heading>}
                                {subHeader && <Heading level={3} className="sub-header">{subHeader}</Heading>}
                                {description && <MixText className="description">{description}</MixText>}
                                {
                                    metaComponent &&
                                    <div className="component">
                                        {metaComponent}
                                    </div>
                                }
                            </div>
                        </aside>
                    }

                    <main className={`ui__container ${containerClass ? containerClass : ''}`}>
                        {
                            back && <BackButton />
                        }
                        {children}
                    </main>

                    {
                        (columns > 2 || (noMeta && columns === 2)) &&
                        <aside className="sidebar-right page-actions">
                            <div className="component">
                                {
                                    actionsComponent && actionsComponent
                                }
                            </div>
                        </aside>
                    }
                </div>
            </MixPage>
        );
    }

export default LayoutPage;