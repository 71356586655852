import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { Exhibitor } from '../../interfaces/IExhibitor';
import { AppContext } from '../../reducers/AppContext';

interface Props {
    exhibitor: Exhibitor
}

const ExhibitorCard: React.FC<Props> = ({ exhibitor }) => {
    const history = useHistory();
    const { eventId } = useContext(AppContext);

    return (
        <div className="exhibitor-card"
            onClick={() =>
                history.push(`/my/${eventId}/exhibitors/${exhibitor.id}/schedule-meeting`)
            }>
            <img src={exhibitor?.bannerImg?.url} alt="" />
            {exhibitor?.cta && (<div className="cta">
                {exhibitor.cta}
            </div>)}
        </div>
    );
}

export default ExhibitorCard;
