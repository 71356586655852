import React from 'react';

interface Props {
    align?: string
}

const TableHeaderCell: React.FC<Props> = ({ children, align = "left" }) => {
    return (
        <th className="table__header-cell" style={{ textAlign: align as any }}>
            {children}
        </th>
    );
}

export default TableHeaderCell;