import React, { useContext, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { AppContext } from '../../reducers/AppContext';
import { LocalizationContext } from '../../reducers/LocalizationContext';

const AdminNavigation: React.FC = () => {
    const { eventId } = useContext(AppContext);
    const { dictionary } = useContext(LocalizationContext);
    const history = useHistory();
    const location = useLocation();

    const menuItems = useMemo(() => {
        return [
            {
                string: dictionary["admin_event"],
                path: `/admin/${eventId}/general`
            },
            {
                string: dictionary["admin_tickets"],
                path: `/admin/${eventId}/tickets`
            },
            {
                string: dictionary["admin_schedule"],
                path: `/admin/${eventId}/schedule`
            },
            {
                string: dictionary["admin_exhibitors"],
                path: `/admin/${eventId}/exhibitors`
            },
            // {
            //     string: dictionary["admin_sponsors"],
            //     path: "./sponsors"
            // },
            {
                string: dictionary["admin_analytics"],
                path: `/admin/${eventId}/analytics`
            },
        ]
    }, [dictionary]);

    return (
        <div className="admin__navigation">
            {
                menuItems.map((item, index) => (
                    <div className={`item${location.pathname === item.path ? " selected" : ""}`} key={index} onClick={() => history.push(item.path)}>
                        {item.string}
                    </div>
                ))
            }
        </div>
    );
}

export default AdminNavigation;
