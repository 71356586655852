import Video, {LocalVideoTrack} from 'twilio-video';
import {environment} from '../../configs/environment';
import {User} from '../../interfaces/IUser';
import {CLIENT_ID} from "../../configs/constants";

export type VideoChatRoom = Video.Room;

const connectToRoom = (name: string, token: string) => {
    return Video.connect(token, {
        name,
        video: {width: 1920, height: 1080},
        audio: true
    }).then((room: Video.Room) => {
        return {
            room,
            onParticipantConnected: (cb: (par: Video.Participant) => void) => {
                room.on('participantConnected', cb);
                room.participants.forEach(cb);
            },
            onParticipantDisConnected: (cb: (par: Video.Participant) => void) => {
                room.on('participantDisconnected', cb);
            }
        }

    });
}

const connectToVoiceRoom = (name: string, token: string) => {
    return Video.connect(token, {
        name,
        video: false,
        audio: true
    }).then((room: Video.Room) => {
        return {
            room,
            onParticipantConnected: (cb: (par: Video.Participant) => void) => {
                room.on('participantConnected', cb);
                room.participants.forEach(cb);
            },
            onParticipantDisConnected: (cb: (par: Video.Participant) => void) => {
                room.on('participantDisconnected', cb);
            }
        }

    });
}

const getRoomToken = async (user: User, roomName: string) => {
    return await fetch(`${environment.apiUrl}/getVideoChatToken`, {
        method: 'POST',
        body: JSON.stringify({
            username: `${user.firstName} ${user.lastName}`,
            room: roomName,
            clientId: CLIENT_ID
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(res => res.json())
        .then(({token}) => token)
        .catch((err) => "");
}

const disconnectFromRoom = (currentRoom: Video.Room) => {
    currentRoom.localParticipant.audioTracks.forEach((trackPublication) => {
        trackPublication.track.stop();
    })
    currentRoom.localParticipant.videoTracks.forEach((trackPublication) => {
        trackPublication.track.stop();
    });
    currentRoom.disconnect();
}

const muteAudio = (currentRoom: Video.Room) => {
    currentRoom.localParticipant.audioTracks.forEach((trackId, track) => {
        trackId.track.disable();
    })
}

const unMuteAudio = (currentRoom: Video.Room) => {
    currentRoom.localParticipant.audioTracks.forEach((trackId, track) => {
        trackId.track.enable();
    })
}

const cutVideo = (currentRoom: Video.Room) => {
    currentRoom.localParticipant.videoTracks.forEach((trackId, track) => {
        trackId.track.disable();
    })
}

const openVideo = (currentRoom: Video.Room) => {
    currentRoom.localParticipant.videoTracks.forEach((trackId, track) => {
        trackId.track.enable();
    })
}


export const VideoChatService = {
    getRoomToken,
    connectToRoom,
    disconnectFromRoom,
    connectToVoiceRoom,
    muteAudio,
    unMuteAudio,
    cutVideo,
    openVideo
}