import { IonIcon, IonLoading } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { EventProps } from '../../interfaces/IEvent';
import { EventService } from '../../services/event/event.service';

import PhoneIcon from '../../assets/icons/ic-phone.svg';
import EmailIcon from '../../assets/icons/ic-email.svg';
import dayjs from 'dayjs';
import { hexToRGB } from '../../functions';
import LayoutPage from '../../components/templates/LayoutPage';
import { useHistory } from 'react-router';

const AdminFindEventsPage: React.FC = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [events, setEvents] = useState<EventProps[]>();
  const [timestamps, setTimestamps] = useState<string[]>();

  useEffect(() => {
    EventService.getEventsList().then(setEvents)
  }, []);

  useEffect(() => {
    if (events) {
      setTimestamps(Array.from(new Set(events.map(event => event.eventDates[0]))));
    }
  }, [events]);

  return (
    <LayoutPage
      columns={2}
      pageClass="find-events__page"
      contentClass="login__wrapper"
      containerClass="events-container"

      header="Find Events - Admin"
    >
      <IonLoading isOpen={isLoading} />
      <div className="page-content">
        <div className="timestamps">
          {timestamps ? timestamps
            .sort((a, b) => {
              if (a < b) return 1
              if (a > b) return -1
              return 0
            })
            .map((timestamp, index) => (
              <div className="timestamp-group" key={index}>
                <div className="entry">{dayjs(timestamp, 'MM/D/YYYY').format('MMM YY')}</div>
                <div className="events">
                  {events ?
                    events
                      .filter((event: any) => dayjs(event.eventDates[0], 'MM/D/YYYY').month() === dayjs(timestamp, 'MM/D/YYYY').month())
                      .map((event: EventProps, index: number) => (
                        <div className="event"
                          key={index}
                          onClick={() => history.push(`/admin-login/${event?.id}`)}>
                          <div className="placeholder">
                            <img src={event.bannerImg.url} alt="img or btn" />
                          </div>
                          <div className="content">

                            <div className="header">
                              <div className="name">
                                {event.eventName}
                              </div>
                              <div className="description">
                                {event.eventSubname}
                              </div>
                              <div
                                className="bg"
                                style={event?.eventColor ? { backgroundColor: hexToRGB(event.eventColor, 0.3) } : {}} />
                            </div>
                            <div className="content">
                              <div className="meta">
                                <div className="info">
                                  <div className="time">
                                    {dayjs(event.eventDates[0]).format('DD MMM YYYY')}
                                  </div>
                                  <div className="location">
                                    {event?.eventLocation}
                                  </div>
                                </div>
                                <div className="contacts">
                                  <div className="phone">
                                    <IonIcon src={PhoneIcon} />
                                    <div className="text">
                                      <a href={`tel:${event?.eventPhone}`}>
                                        {event?.eventPhone}
                                      </a>
                                    </div>
                                  </div>
                                  <div className="email">
                                    <IonIcon src={EmailIcon} />
                                    <div className="text">
                                      <a href={`mailto:${event?.eventEmail}`}>
                                        {event?.eventEmail}
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="logos"
                                style={event.eventColor ? { borderBottomColor: hexToRGB(event.eventColor, 0.3) } : {}}>

                                {
                                  event?.companyLogo ? (
                                    <div className="presentor">
                                      <div className="text">
                                        Presented by
                                        </div>
                                      <div className="images">
                                        <img src={event.companyLogo} alt={`${event?.companyName} logo`} />
                                      </div>
                                    </div>
                                  ) : null
                                }

                                {
                                  event?.partnerLogos?.length && event?.partnerLogos[0] !== '' ? (
                                    <div className="sponsor">
                                      <div className="text">
                                        Sponsored by
                                        </div>
                                      <div className="images">
                                        {event.partnerLogos.map((logo, index) => (
                                          <img src={logo} alt="" key={index} />
                                        ))}
                                      </div>
                                    </div>
                                  ) : null
                                }

                              </div>
                            </div>

                          </div>
                        </div>

                      )) : null
                  }
                </div>
              </div>
            )) : null
          }
        </div>
      </div>
    </LayoutPage>
  );
}

export default AdminFindEventsPage;
