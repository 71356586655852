import { HexColorPicker } from "react-colorful";
import "react-colorful/dist/index.css";
import React from 'react';
import { Control, Controller, DeepMap, FieldError, ValidationValueMessage } from 'react-hook-form';
import { renderErrorMessage } from "../../functions";
import { Input, InputLabel } from "@material-ui/core";
import UIRow from "../UI/UIRow";

interface Props {
    name: string
    control?: Control<any>
    errors: DeepMap<any, FieldError>
    errorField: FieldError | undefined
    required?: boolean
    defaultValue?: string
    label?: string
    pattern?: RegExp | ValidationValueMessage<RegExp> | undefined
}

const ColorInputController: React.FC<Props> = ({ name, control, errors, errorField, required = false, defaultValue = "", label, pattern }) => {
    return (
        <Controller
            defaultValue={defaultValue}
            render={({ onChange, value }) => (
                <UIRow className="fc__color-picker" aic>
                    <div>
                        <InputLabel className="ui__label">
                            {label}{required ? " (*)" : null}
                            {errorField ? renderErrorMessage(errors, name) : null}
                        </InputLabel>
                        <HexColorPicker color={value} onChange={onChange} />
                    </div>
                    <div className="input">
                        <Input value={value} onChange={onChange} />
                    </div>
                </UIRow>
            )}
            control={control}
            name={name}
            rules={{
                required: required,
                pattern: pattern
            }}
        />

    );
}

export default ColorInputController;
