import { IonIcon } from '@ionic/react';
import React, { useState } from 'react';
import Icon from '../../assets/icons/ic-navigation-arrow_drop_down.svg';
import UIRow from '../UI/UIRow';

interface Props {
    className?: string
    title?: string
}

const MixDropdown: React.FC<Props> = ({ className = "", title, children }) => {
    const [open, setOpen] = useState(false);

    return (
        <div className={`fc__dropdown ${className}`} onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
            <UIRow aic className="name">
                {title}
                <IonIcon src={Icon} />
            </UIRow>
            <div className={`items${open ? ' open' : ''}`}>
                {children}
            </div>
        </div>
    );
}

export default MixDropdown;
