import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { ScheduleItem } from '../../interfaces/ISchedule';
import { User } from '../../interfaces/IUser';
import { AppContext } from '../../reducers/AppContext';
import { UserService } from '../../services/user/user.service';
import MixButton from '../general/MixButton';
import MixText from '../general/MixText';
import TranslatedString from '../general/TranslatedString';

interface Props {
  scheduleItem?: ScheduleItem
}

const StreamMeta: React.FC<Props> = ({ scheduleItem }) => {
  const { eventId } = useContext(AppContext);

  let [presenters, setPresenters] = useState<User[]>();

  useEffect(() => {
    if (scheduleItem?.presenters?.length) {
      Promise.all(scheduleItem.presenters.map((presenter) => UserService.getUser(presenter as string, eventId))).then(((o) => setPresenters(o as User[])));
    }
  }, [scheduleItem]);

  return (
    <div className="meta">
      {presenters &&
        <div className="presenters">
          {
            presenters?.map((presenter, index) => (
              <div className="presenter" key={index}>
                <span className="name">{presenter?.firstName} {presenter?.lastName}</span> - <span className="title">{presenter?.title}, {presenter?.company}</span>
              </div>
            ))
          }
        </div>
      }
      <MixText className="location">
        <TranslatedString tid="watch_location" /> {scheduleItem?.location}
      </MixText>
      { (scheduleItem?.startsAt && dayjs().isBefore(scheduleItem.startsAt)) &&
        (
          <MixText className="hide-on-mobile">
            <TranslatedString tid="watch_starts-at" /> {dayjs(scheduleItem?.startsAt).format('HH:mm, MMM D YYYY')}
          </MixText>
        )
      }
      {scheduleItem?.downloadLink && (
        <div className="download">
          <MixText>
            <TranslatedString tid="watch_presentation-content" />
          </MixText>
          <MixButton onClick={() => window.open(scheduleItem?.downloadLink, '_system')}>
            <TranslatedString tid="general_download-btn" />
          </MixButton>
        </div>
      )}
    </div>
  );
}

export default StreamMeta;
