import React, { useState, useContext, useEffect } from 'react';
import { IonLoading, IonAlert } from '@ionic/react';

import { UserService } from '../../services/user/user.service';
import { AppContext } from '../../reducers/AppContext';
import firebase from '../../configs/firebase';

import LoginCover from '../../components/login/LoginCover';
import EnterNipPrompt from '../../components/login/EnterNipPrompt';
import { LocalizationContext } from '../../reducers/LocalizationContext';
import MixPage from '../../components/templates/MixPage';
import { useParams } from 'react-router';
import { EventService } from '../../services/event/event.service';
import { EventProps } from '../../interfaces/IEvent';

const LoginPage: React.FC = () => {

  const { eventId } = useParams<{ [id: string]: string }>();
  const { setEventId, accessError, setAccessError } = useContext(AppContext);
  const { dictionary } = useContext(LocalizationContext);

  const [eventProps, setEventProps] = useState<EventProps>(null as any);

  const [userNip, setUserNip] = useState("");
  const [codeConfirm, setCodeConfirm] = useState("");
  const [waitingForCode, setWaitingForCode] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState<firebase.auth.ConfirmationResult | null>(null)
  const [loginError, setLoginError] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [recaptcha, setRecaptcha] = useState<firebase.auth.RecaptchaVerifier>();

  useEffect(() => {
    if (eventProps && eventProps.authMethod === "phoneAuth") {
      setRecaptcha(new firebase.auth.RecaptchaVerifier('recaptcha-container', {
        size: 'invisible',
        callback: (response: any) => {
          console.log("Recaptcha réussi!!", response)
        },
        'expired-callback': () => {
          console.log("Repatcha expiré!!!")
        }
      }))
    }
  }, [eventProps]);

  useEffect(() => {
    if (eventId) {
      EventService.getEventProps(eventId).then((val) => setEventProps(val as EventProps));
    }
  }, [eventId]);

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (userNip !== '' && eventId !== '') {
      setIsLoading(true);

      if (waitingForCode) {
        if (codeConfirm !== "" && confirmationResult !== null) {
          confirmationResult.confirm(codeConfirm).then((result) => {
            UserService.phoneLoginUser(eventId).then((_eventCode) => {
              setLoginError(0);
              setEventId(_eventCode);
              setConfirmationResult(null);
              setWaitingForCode(false);
              setIsLoading(false);
            })
          })
            .catch(() => {
              setLoginError(1);
              setIsLoading(false);
            })
        }
      }
      else {
        if (eventProps.authMethod === "phoneAuth" && recaptcha !== undefined) {
          UserService.sendTextMessageAuth(userNip, recaptcha).then((result: firebase.auth.ConfirmationResult) => {
            setConfirmationResult(result);
            setWaitingForCode(true);
          })
            .catch(() => setLoginError(1))
            .finally(() => setIsLoading(false))
        }
        else {
          UserService.loginUser(userNip, eventId, eventProps.authMethod).then((_eventCode) => {
            setLoginError(0);
            setEventId(_eventCode);
          })
            .catch(() => setLoginError(1))
            .finally(() => {
              setIsLoading(false);
            });
        }
      }
    }
  }

  return (
    <MixPage pageClass="login__page" contentClass="login__wrapper">
      <IonLoading isOpen={isLoading} />
      <div className="login-container m-auto">
        <form onSubmit={onSubmit}>
          <div className="ui__row page">
            <LoginCover eventProps={eventProps} />
            <div className="ui__col login__content">
              {eventProps && eventProps.authMethod === "phoneAuth" && <div id="recaptcha-container" />}
              <EnterNipPrompt
                setEventCode={setEventId}
                setEventProps={setEventProps}
                eventProps={eventProps}
                userNip={userNip}
                setUserNip={setUserNip}
                loginError={loginError}
                codeConfirm={codeConfirm}
                setCodeConfirm={setCodeConfirm}
                waitingForCode={waitingForCode}
              />
            </div>
          </div>
        </form>
      </div >
      <IonAlert
        isOpen={accessError}
        onDidDismiss={() => setAccessError(false)}
        header={dictionary["login_event-not-available"]}
        buttons={[
          {
            text: dictionary["general_ok"],
            role: 'cancel',
          }
        ]}
      />
    </MixPage>
  );
};

export default LoginPage;
