import React, {useState, useContext, useEffect} from 'react';
import {IonLoading, useIonViewDidLeave} from '@ionic/react';
import {useHistory, useParams} from "react-router";
import {AppContext} from "../../reducers/AppContext";

import {ScheduleItem, StreamInfo} from '../../interfaces/ISchedule';
import {ScheduleService} from '../../services/schedule/schedule.service';
import MixButton from '../../components/general/MixButton';
import WatchSection from '../../components/watch/VideoPlayer';
import TranslatedString from '../../components/general/TranslatedString';
import LayoutPage from '../../components/templates/LayoutPage';
import StreamMeta from '../../components/watch/ScheduleItemMeta';
import {DB_COLLECTIONS} from '../../configs/constants';
import firebase from "firebase";
import {LocalizationContext} from '../../reducers/LocalizationContext';
import UpcomingPresentations from '../../components/watch/UpcomingPresentations';
import UIContent from '../../components/UI/UIContent';
import dayjs from 'dayjs';
import {PopupContext} from '../../reducers/PopupContext';
import CopyToClipboard from '../../components/general/CopyToClipboard';
import MixText from '../../components/general/MixText';
import {AdminService} from "../../services/admin/admin.service";
import { SendChatRequestBody } from '../../interfaces/IChat';

const WatchPage: React.FC = () => {
    const {user, eventProps, setPageChat, setLoading} = useContext(AppContext);
    const {setModalContent, setModalCssClass, showModal} = useContext(PopupContext);
    const {dictionary} = useContext(LocalizationContext);
    const {eventId, streamId} = useParams<{ [id: string]: string }>();
    const [streamInfo, setStreamKey] = useState<StreamInfo>();

    const [scheduleItem, setScheduleItem] = useState<ScheduleItem>();
    const [upcomingPresentations, setUpcomingPresentations] = useState<ScheduleItem[]>();

    useEffect(() => {
        if (scheduleItem) {
            return ScheduleService.getSchedulesItemsWithUsers(eventId, (res) => {
                const filtered = res.filter((item) => item.startsAt > scheduleItem.startsAt && dayjs(item.startsAt, "MM/D/YYYY HH:mm").isSame(dayjs(scheduleItem.startsAt, "MM/D/YYYY HH:mm"), 'day'));
                setUpcomingPresentations(filtered);
            })
        }
    }, [scheduleItem]);

    useEffect(() => {
        if (scheduleItem && (user?.admin || user?.presenter)) {
            ScheduleService.getStreamKey(eventId, scheduleItem.id!).then((o) => {
                console.log("StreamInfo: ", o);
                setStreamKey(o as StreamInfo);
            })
        }
    }, [scheduleItem, user]);

    useEffect(() => {
        //  firebase.analytics().logEvent(`${eventId}_WATCH_STREAM_${streamId}`);
        // Analytic for views calculation
        firebase.analytics().logEvent('watch_content', {
            content_type: 'ScheduleItem',
            content_id: scheduleItem?.id,
            items: [{user: user.id}]
        });
        const _scheduleItem = ScheduleService.getScheduleItem(eventId, streamId);
        return _scheduleItem.subscribe((data) => {
            setScheduleItem(data)
        })
    }, [streamId]);

    useEffect(() => {
        if (streamId && scheduleItem) {
            const data: SendChatRequestBody = {
                type: "scheduleItem",
                streamId,
                eventId
            }
            setPageChat({
                data: data,
                name: dictionary["generalChat_presenter"],
                chatEnabled: !!scheduleItem.chatEnabled
            });
        }
    }, [streamId, scheduleItem, dictionary]);

    useEffect(() => {
        return () => {
            setPageChat(null);
        }
    }, []);

    useIonViewDidLeave(() => setScheduleItem(null as any))

    if (!(eventProps && user)) {
        return (
            <p>
                <TranslatedString tid="general_loading"/>...
            </p>
        )
    }

    if (!scheduleItem) {
        return <IonLoading isOpen/>
    }

    const showStreamInfo = () => {
        setModalCssClass("show-stream-key");
        setModalContent(
            <React.Fragment>
                <div className="wrapper">
                    <div className="ui__row">
                        <MixText>URL:</MixText>
                        <CopyToClipboard title={dictionary['general_copy-to-clipboard']}
                                         writeText="rtmp://mixabl-live.com:5222/app">
                            <MixText centered>rtmp://mixabl-live.com:5222/app</MixText>
                        </CopyToClipboard>
                    </div>
                    <div className="ui__row ion-margin-top">
                        <MixText>Stream key:</MixText>
                        <CopyToClipboard title={dictionary['general_copy-to-clipboard']}
                                         writeText={streamInfo ? streamInfo.streamKey : ""}>
                            <MixText centered>{streamInfo?.streamKey}</MixText>
                        </CopyToClipboard>
                    </div>
                </div>
            </React.Fragment>
        );
        showModal();
    }

    const metaComponent = <StreamMeta scheduleItem={scheduleItem}/>

    return (
        <LayoutPage
            columns={2}
            reversed

            header={scheduleItem?.name.substr(0, 100) + `${scheduleItem.name.length > 100 ? '...' : ''}`}
            subHeader={scheduleItem?.startsAt}

            metaComponent={metaComponent}
            pageClass="watch__wrapper"

            back
        >
            <UIContent>
                <div className="watch__actions">
                    {
                        (scheduleItem.streamStatus == 'COMPLETED' && user?.admin) &&
                        (
                            <MixButton color="transparent" className="turn-to-vod"
                                       onClick={() => {
                                           setLoading(true);
                                           if (typeof scheduleItem.id == 'string') {
                                               AdminService.turnToVod(scheduleItem.id, eventId).then(() => setLoading(false));
                                           } else setLoading(false);
                                       }}>
                                <TranslatedString tid="event-schedule_turn_vod"/>
                            </MixButton>
                        )
                    }
                    {
                        (user?.admin && scheduleItem.streamStatus != 'COMPLETED' && scheduleItem.streamStatus != 'VOD') &&
                        (
                            <MixButton color="transparent" className="show-stream-info"
                                       onClick={() => window.location.href = `${scheduleItem?.studioLink}`}>
                                <TranslatedString tid="watch_live-studio"/>
                            </MixButton>
                        )

                    }
                    {
                        ((user.presenter?.includes(streamId) || user?.admin) && scheduleItem.streamStatus != 'COMPLETED' && scheduleItem.streamStatus != 'VOD') &&
                        (
                            <MixButton color="transparent" className="show-stream-info"
                                       onClick={showStreamInfo}>
                                <TranslatedString tid="event-schedule_stream_info"/>
                            </MixButton>
                        )
                    }
                </div>
                <WatchSection scheduleItem={scheduleItem}/>
                <UpcomingPresentations scheduleItems={upcomingPresentations}/>
            </UIContent>
        </LayoutPage>
    );
}

export default WatchPage;
