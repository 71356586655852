import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { mapToFileComposer } from '../../../functions';
import { EventProps } from '../../../interfaces/IEvent';
import { AppContext } from '../../../reducers/AppContext';
import { PopupContext } from '../../../reducers/PopupContext';
import { AdminService } from '../../../services/admin/admin.service';
import { StorageService } from '../../../services/storage/storage.service';
import ControlledFilePicker from '../../FilePicker/ControlledFilePicker.component';
import OldInputController from '../../forms/OldInputController';
import MixButton from '../../general/MixButton';
import { LocalizationContext } from "../../../reducers/LocalizationContext";
import ColorInputController from '../../forms/ColorInputController';
import { Prompt } from 'react-router';
import DatePickerController from '../../forms/DatePickerController';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';
import { StorageFile } from '../../../interfaces/IFile';
import UIGroup from '../../UI/UIGroup';
import InputController from '../../forms/InputController';
import UIRow from '../../UI/UIRow';
import { Chip, Input } from '@material-ui/core';
import Heading from '../../general/Heading';
import TranslatedString from '../../general/TranslatedString';
import MixText from '../../general/MixText';

type Inputs = {
    eventName: string,
    eventSubname: string,
    eventDates: string,
    eventColor: string,
    headerImg: string,
    bannerImg: string,
    eventPDF: StorageFile,
    eventPhone: string,
    eventEmail: string,
}

interface Props {
    eventProps?: EventProps
}

const ManageEventForm: React.FC<Props> = ({ eventProps }) => {
    const { eventId, setLoading } = useContext(AppContext);
    const { setToastProps } = useContext(PopupContext);
    const { dictionary } = useContext(LocalizationContext);

    const initialValues: any = {
        eventName: eventProps?.eventName,
        eventSubname: eventProps?.eventSubname,
        eventDates: dayjs(eventProps?.eventDates[0]).format(),
        eventColor: eventProps?.eventColor,
        headerImg: mapToFileComposer(eventProps?.headerImg),
        bannerImg: mapToFileComposer(eventProps?.bannerImg),
        eventPDF: mapToFileComposer(eventProps?.eventPDF),
        eventPhone: eventProps?.eventPhone,
        eventEmail: eventProps?.eventEmail
    };

    const { handleSubmit, errors, control, formState, setValue } = useForm<Inputs>({
        defaultValues: initialValues
    });

    const [tagInput, setTagInput] = useState("");
    const [tags, setTags] = useState<string[]>([]);

    useEffect(() => {
        if (eventProps?.tags) {
            setTags(eventProps.tags);
        }
    }, []);

    const addTag = (tag: string) => {
        setTags((chips) => chips.concat(tag));
        setTagInput("");
    };

    const removeTag = (tag: string) => {
        setTags((chips) => chips.filter((chip) => chip !== tag));
    };

    const onSubmit = async (data: any) => {
        setLoading(true);
        try {
            const uploadDir = `events/${eventId}`

            const body = {
                eventName: data.eventName,
                eventSubname: data.eventSubname,
                eventDates: [dayjs(data.eventDates).format("MM/D/YYYY")],
                eventColor: /^#[0-9A-F]{6}$/i.test(data.eventColor) ? data.eventColor : null,
                eventPhone: data.eventPhone,
                eventEmail: data.eventEmail,
                tags: tags
            } as EventProps;

            if (data.headerImg && data.headerImg.files && data.headerImg !== "DELETED") {
                body.headerImg = await StorageService.uploadFile(uploadDir, data.headerImg.files.item(0));
            } else if (data?.headerImg?.files !== null) {
                body.headerImg = data.headerImg;
            }

            if (data.bannerImg && data.bannerImg.files && data.bannerImg !== "DELETED") {
                body.bannerImg = await StorageService.uploadFile(uploadDir, data.bannerImg.files.item(0));
            } else if (data?.bannerImg?.files !== null) {
                body.bannerImg = data.bannerImg;
            }

            if (data.eventPDF && data.eventPDF.files && data.eventPDF !== "DELETED") {
                body.eventPDF = await StorageService.uploadFile(uploadDir, data.eventPDF.files.item(0));
            } else if (data?.eventPDF?.files !== null) {
                body.eventPDF = data.eventPDF;
            }

            AdminService.updateEvent(body, eventId)
                .then(() => {
                    setLoading(false);
                    setToastProps({
                        isOpen: true,
                        message: dictionary['admin-event_success-message'],
                        duration: 3000,
                        position: "bottom"
                    });
                })
                .finally(() => window.location.reload());
        } catch (e) {
            setLoading(false);
        }
    }

    return (
        <MuiPickersUtilsProvider utils={DayjsUtils}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <UIGroup>
                    <InputController
                        name="eventName"
                        label={dictionary['admin-event_main-title']}
                        errors={errors}
                        control={control}
                        defaultValue={initialValues.eventName}
                        required />
                    <InputController
                        name="eventSubname"
                        label={dictionary['admin-event_subtitle']}
                        errors={errors}
                        control={control}
                        defaultValue={initialValues.eventSubname}
                        required />
                    <DatePickerController
                        name="eventDates"
                        label={dictionary['admin-event_date']}
                        errors={errors}
                        control={control}
                        defaultValue={initialValues.eventDates}
                        required />
                    <ColorInputController
                        name="eventColor"
                        label={dictionary['admin-event_main-color']}
                        errors={errors}
                        errorField={errors.eventColor}
                        control={control}
                        defaultValue={initialValues.eventColor} />
                    <ControlledFilePicker setValue={setValue}
                        render={(onPick) => <MixButton onClick={onPick}>{dictionary['general_upload']}</MixButton>}
                        name="bannerImg"
                        label={dictionary['admin-event_banner']}
                        accept='image/jpeg, image/png'
                        errors={errors}
                        control={control}
                        defaultValue={initialValues.bannerImg}
                        preview={eventProps?.bannerImg} />
                    <ControlledFilePicker setValue={setValue}
                        render={(onPick) => <MixButton onClick={onPick}>{dictionary['general_upload']}</MixButton>}
                        name="headerImg"
                        label={dictionary['admin-event_header']}
                        accept='image/jpeg, image/png'
                        errors={errors}
                        control={control}
                        defaultValue={initialValues.headerImg}
                        preview={eventProps?.headerImg} />
                    <ControlledFilePicker setValue={setValue}
                        render={(onPick) => <MixButton onClick={onPick}>{dictionary['general_upload']}</MixButton>}
                        name="eventPDF"
                        label={dictionary['admin-event_pdf']}
                        accept='application/pdf'
                        errors={errors}
                        control={control}
                        defaultValue={initialValues.eventPDF}
                        preview={eventProps?.eventPDF} />
                    <InputController
                        type="tel"
                        name="eventPhone"
                        label={dictionary['admin-event_phone']}
                        errors={errors}
                        control={control}
                        defaultValue={initialValues.eventPhone}
                        required />
                    <InputController
                        type="email"
                        name="eventEmail"
                        label={dictionary['admin-event_email']}
                        errors={errors}
                        control={control}
                        defaultValue={initialValues.eventEmail}
                        required />
                    <UIGroup>
                        <Heading level={3}>
                            Tags
                        </Heading>
                        <Input
                            placeholder={"Type here..."}
                            value={tagInput}
                            onChange={(e) => setTagInput(e.currentTarget.value)}
                            onKeyUp={(e) => e.key === 'Enter' && tagInput !== "" ? addTag(tagInput) : null} />
                        <MixText>
                            Press Enter to add new tags
                        </MixText>
                        <UIRow wrap margin>
                            {
                                tags?.length ? tags.map((tag, index) => (
                                    <Chip
                                        key={index}
                                        variant="outlined"
                                        size="small"
                                        label={tag}
                                        onDelete={() => removeTag(tag)}
                                    />
                                )) : <TranslatedString tid="general_nothing" />
                            }
                        </UIRow>
                    </UIGroup>
                    <MixButton submit color="success">
                        {dictionary['general_update']}
                    </MixButton>
                </UIGroup>
                <Prompt
                    when={formState.isDirty}
                    message={dictionary["general_prompt-unsaved-changes"]}
                />
            </form>
        </MuiPickersUtilsProvider>
    );
}

export default ManageEventForm;
