import { IonItem, IonLabel, IonInput, IonIcon } from '@ionic/react';
import React, { useContext } from 'react';
import MixButton from '../../general/MixButton';
import MixText from '../../general/MixText';
import ManageTicketForm from './ManageTicketForm';
import CopyIcon from '../../../assets/icons/ic-copy-to-clipboard.svg';
import { PopupContext } from '../../../reducers/PopupContext';
import TranslatedString from '../../general/TranslatedString';

interface Props {
    res: any
}

const DisplayNIP: React.FC<Props> = ({ res }) => {
    const { setModalContent, setModalCssClass } = useContext(PopupContext);

    return (
        <React.Fragment>
            <MixText>
                <TranslatedString tid="admin-ticket_ticket-nip-created" />
            </MixText>
            <div className="ui__group">
                <IonItem className="ui__item">
                    <IonLabel position="fixed" slot="start" className="ui__label">
                        <TranslatedString tid="general_nip" />:
                    </IonLabel>
                    <IonInput value={res} readonly />
                </IonItem>
                <div className="btn" onClick={(e) => navigator.clipboard.writeText(res)} onMouseDown={(e) => e.preventDefault()}>
                    <IonIcon className="icon-copy" src={CopyIcon} />
                    <div className="text">
                        <TranslatedString tid="general_copy-to-clipboard" />
                    </div>
                </div>
            </div>
            <div className="ion-margin-top">
                <MixButton onClick={() => {
                    setModalContent(<ManageTicketForm />);
                    setModalCssClass("add-ticket");
                }}>
                    <TranslatedString tid="admin-ticket_add-new-ticket" />
                </MixButton>
            </div>
        </React.Fragment>
    );
}

export default DisplayNIP;
