import { IonRouterOutlet } from '@ionic/react'
import React from 'react'
import { Route, Switch } from 'react-router';
import AppTabs from './AppTabs';
import AuthGuard from './guards/AuthGuard';
import AdminFindEventsPage from './pages/login/AdminFindEventsPage';
import AdminLoginPage from './pages/login/AdminLoginPage';
import FindEventsPage from './pages/login/FindEventsPage';
import LoginPage from './pages/login/LoginPage';

export default function Routes() {
    return (
        <IonRouterOutlet>
            <Switch>
                <Route exact path="/login/:eventId" component={LoginPage} />
                <Route exact path="/find-events" component={FindEventsPage} />
                <Route exact path="/admin-login/:eventId" component={AdminLoginPage} />
                <Route exact path="/admin-find-events" component={AdminFindEventsPage} />
                <AuthGuard path="" component={AppTabs} />
            </Switch>
        </IonRouterOutlet>
    )
}