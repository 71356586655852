import React, { useContext } from 'react';
import { hexToRGB } from '../../functions';
import { Exhibitor } from '../../interfaces/IExhibitor';
import { AppContext } from '../../reducers/AppContext';
import BronzeExhibitorCard from './BronzeExhibitorCard';
import ExhibitorCard from './ExhibitorCard';
import GoldExhibitorCard from './GoldExhibitorCard';
import PartnerCard from './PartnerCard';
import SilverExhibitorCard from './SilverExhibitorCard';

interface Props {
    goldExhibitors?: Exhibitor[]
    silverExhibitors?: Exhibitor[]
    bronzeExhibitors?: Exhibitor[]
    otherExhibitors?: Exhibitor[]
    partners?: Exhibitor[]
}

const ExhibitorsGrid: React.FC<Props> = ({ goldExhibitors, silverExhibitors, bronzeExhibitors, partners, otherExhibitors }) => {
    const { eventProps } = useContext(AppContext);

    return (
        <div className="fc__exhibitors-grid">
            <div className="gold">
                {
                    goldExhibitors?.map((exhibitor, index) => (
                        <GoldExhibitorCard
                            key={index}
                            exhibitor={exhibitor} />
                    ))
                }
            </div>
            <div className="silver">
                {
                    silverExhibitors?.map((exhibitor, index) => (
                        <SilverExhibitorCard
                            key={index}
                            exhibitor={exhibitor} />
                    ))
                }
            </div>
            <div className="bronze">
                {
                    bronzeExhibitors?.map((exhibitor, index) => (
                        <BronzeExhibitorCard
                            key={index}
                            exhibitor={exhibitor} />
                    ))
                }
            </div>
            <div className="partners">
                {
                    partners?.map((exhibitor, index) => (
                        <PartnerCard
                            key={index}
                            exhibitor={exhibitor} />
                    ))
                }
            </div>

            <div className="other" style={eventProps?.eventColor ? { borderColor: hexToRGB(eventProps.eventColor, 0.3) } : {}}>
                {
                    otherExhibitors?.map((exhibitor, index) => (
                        <ExhibitorCard
                            key={index}
                            exhibitor={exhibitor} />
                    ))
                }
            </div>
        </div>
    );
}

export default ExhibitorsGrid;
