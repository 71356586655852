import React from 'react';

interface Props {
    className?: string
}

const Table: React.FC<Props> = ({ children, className = "" }) => {
    return (
        <table className={`table__root ${className}`}>
            {children}
        </table>
    );
}

export default Table;
