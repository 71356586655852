import { IonText } from '@ionic/react';
import React from 'react';

interface Props {
  bold?: boolean
  centered?: boolean
  color?: string
  className?: string
  width?: string
}

const MixText: React.FC<Props> = ({ children, bold = false, centered = false, color = "", className = "", width = "auto" }) => {
  return (
    <IonText
      className={`${bold && 'fwb'} ${centered && 'tac'} ${className}`}
      color={color}
      style={{ width: width }}>
      {children}
    </IonText>
  );
}

export default MixText;
