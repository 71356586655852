import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import Heading from '../general/Heading';
import { renderErrorMessage } from '../../functions';

interface Props {
    name: string
    control?: Control<any>
    errors: DeepMap<any, FieldError>
    required?: boolean
    defaultValue?: string
    label?: string
    multiple?: boolean
    variant?: 'filled' | 'outlined' | 'standard' | undefined
}

const SelectController: React.FC<Props> = ({ name, control, errors, required = false, defaultValue, label, multiple = false, children, variant }) => {
    return (
        <FormControl className="fw">
            {
                label?.length! > 45 ? (
                    <Heading level={4} eventColor={false}>{label}</Heading>
                ) : (
                        <InputLabel>
                            {required && "*"} {label}
                        </InputLabel>
                    )
            }
            <Controller
                defaultValue={defaultValue}
                render={({ onChange, value }) => (
                    <Select
                        error={errors[name] ? true : false}
                        value={value}
                        onChange={onChange}
                        placeholder={label}
                        multiple={multiple}
                        variant={variant}>
                        <MenuItem value="" disabled>
                            {label}
                        </MenuItem>
                        {children}
                    </Select>
                )}
                control={control}
                name={name}
                rules={{
                    required: required
                }}
            />
            <FormHelperText>
                {errors[name] ? renderErrorMessage(errors, name) : null}
            </FormHelperText>
        </FormControl>
    );
}

export default SelectController;
