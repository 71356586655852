import { IonSelectOption } from '@ionic/react';
import React, { useContext } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { mapToExhibitorOfferingComposer, mapToFileComposer } from '../../../functions';
import {
    Exhibitor,
    ExhibitorComposer,
    ExhibitorOffering,
    ExhibitorOfferingComposer
} from '../../../interfaces/IExhibitor';
import { AppContext } from '../../../reducers/AppContext';
import { PopupContext } from '../../../reducers/PopupContext';
import { AdminService } from '../../../services/admin/admin.service';
import { StorageService } from '../../../services/storage/storage.service';
import ControlledFilePicker from '../../FilePicker/ControlledFilePicker.component';
import OldInputController from '../../forms/OldInputController';
import OldSelectController from '../../forms/OldSelectController';
import TextareaController from '../../forms/TextareaController';
import Heading from '../../general/Heading';
import MixButton from '../../general/MixButton';
import { LocalizationContext } from "../../../reducers/LocalizationContext";
import TextareaAutosizeController from '../../forms/TextareaAutosizeController';
import { FileComposer, StorageFile } from '../../../interfaces/IFile';
import DisplayExhibitorLink from './DisplayExhibitorLink';

interface Props {
    exhibitor?: Exhibitor
}

const defaultExhibitor: Exhibitor = {
    admins: [],
    availableAppointments: [],
    boothId: "",
    id: "",
    name: "",
    offerings: [],
    bannerImg: null as any,
    color: "",
    cta: "",
    ctaImg: null as any,
    description: "",
    exhibitorType: "",
    sliderImages: [],
    videoLink: null as any,
    meetingInterval: 30
}

const ManageExhibitorForm: React.FC<Props> = ({ exhibitor = defaultExhibitor }) => {
    const { eventId, setLoading } = useContext(AppContext);
    const { setModalContent, setModalCssClass, hideModal, setToastProps } = useContext(PopupContext);
    const { dictionary } = useContext(LocalizationContext);

    const editing = !!exhibitor.id;

    const initialValues: ExhibitorComposer = {
        exhibitorType: exhibitor.exhibitorType ?? "",
        bannerImg: mapToFileComposer(exhibitor.bannerImg),
        ctaImg: mapToFileComposer(exhibitor.ctaImg),
        companyName: exhibitor.name,
        heroVideo: mapToFileComposer(exhibitor.videoLink),
        cta: exhibitor.cta ?? "",
        longDescription: exhibitor.description ?? "",
        galleryItems: exhibitor.sliderImages ? exhibitor.sliderImages.map(mapToFileComposer) : null as any,
        offerings: exhibitor.offerings.map(mapToExhibitorOfferingComposer),
        meetingInterval: exhibitor.meetingInterval ? exhibitor.meetingInterval : 30
    };

    const { handleSubmit, errors, control, reset, setValue } = useForm<ExhibitorComposer>({
        defaultValues: initialValues
    });

    const { fields: offerings, append: appendOffering, remove: removeOffering } = useFieldArray({
        control,
        name: "offerings"
    });

    const createExhibitor = async (data: any) => {
        try {
            const uploadDir = `events/${eventId}/exhibitors`;
            const bannerImgFile = data.bannerImg && data.bannerImg.files ? data.bannerImg.files.item(0) : null;
            const ctaImgFile = data.ctaImg && data.ctaImg.files ? data.ctaImg.files.item(0) : null;
            const videoLinkFile = data.heroVideo && data.heroVideo.files ? data.heroVideo.files.item(0) : null;
            const galleryItems = data.galleryItems && data.galleryItems.files ? data.galleryItems.files : [];
            let offerings: any[] = [];

            const bannerImg = await StorageService.uploadFile(uploadDir, bannerImgFile);
            const ctaImg = ctaImgFile ? await StorageService.uploadFile(uploadDir, ctaImgFile) : null;

            const sliderImages = await Promise.all(
                Array.from(galleryItems).map((file) => StorageService.uploadFile(uploadDir, file as File))
            );
            const videoLink = videoLinkFile ? await StorageService.uploadFile(uploadDir, videoLinkFile) : null;

            if (data.offerings) {
                offerings = await Promise.all(
                    (data.offerings as any[])?.map(async (offering: ExhibitorOfferingComposer) => {
                        const { name, description, file: composer } = offering;
                        let file = null;

                        if (composer && composer.files) {
                            file = await StorageService.uploadFile(uploadDir, composer.files.item(0) as File)
                        }

                        return {
                            name,
                            description,
                            file,
                        } as ExhibitorOffering
                    })
                )
            }

            const body = {
                name: data.companyName,
                description: data.longDescription,
                bannerImg,
                sliderImages,
                videoLink,
                offerings,
                exhibitorType: data.exhibitorType,
                cta: data.cta,
                ctaImg,
                meetingInterval: parseInt(data.meetingInterval)
            };

            AdminService.createExhibitor(body, eventId).then((res) => {
                setLoading(false);
                if (!offerings) {
                    reset();
                }
                setModalCssClass("add-exhibitor-success");
                setModalContent(
                    <DisplayExhibitorLink eventId={eventId} input={res} />
                );
            })
        } catch (e) {
            setLoading(false);
            console.log("the error ", e)
        }
    }

    const updateExhibitor = async (data: any) => {
        try {
            const uploadDir = `events/${eventId}/exhibitors`;

            const body = {
                name: data.companyName,
                description: data.longDescription,
                exhibitorType: data.exhibitorType,
                cta: data.cta,
                admins: exhibitor?.admins,
                meetingInterval: parseInt(data.meetingInterval)
            } as Exhibitor;

            if (data.bannerImg && data.bannerImg.files) {
                body.bannerImg = await StorageService.uploadFile(uploadDir, data.bannerImg.files.item(0));
            }

            if (data.ctaImg && data.ctaImg.files) {
                body.ctaImg = await StorageService.uploadFile(uploadDir, data.ctaImg.files.item(0));
            }

            if (data.galleryItems && data.galleryItems.files && !Array.isArray(data.galleryItems)) {
                body.sliderImages = await Promise.all(
                    Array.from(data.galleryItems.files).map((file) => StorageService.uploadFile(uploadDir, file as File))
                ).then((allFiles) => {
                    const files: StorageFile[] = [];
                    allFiles.forEach((file) => {
                        if (file) {
                            files.push(file);
                        }
                    })
                    return files;
                })
            }

            if (data.heroVideo && data.heroVideo.files) {
                body.videoLink = await StorageService.uploadFile(uploadDir, data.heroVideo.files.item(0));
            }

            if (data.offerings) {
                let _offerings = data.offerings;
                if (_offerings && _offerings.length) {
                    body.offerings = await Promise.all(
                        _offerings
                            .map(async (offering: ExhibitorOfferingComposer) => {
                                const { name, description, file: composer } = offering;
                                let file = null;

                                if (composer && composer.files) {
                                    file = await StorageService.uploadFile(uploadDir, composer.files.item(0) as File);
                                }

                                if (!file) {
                                    file = exhibitor.offerings.find((o) => {
                                        if (o.file?.url === composer?.url) {
                                            return true;
                                        }
                                        return false;
                                    })?.file;
                                }

                                return {
                                    name,
                                    description,
                                    file,
                                } as ExhibitorOffering
                            })
                    )
                }
            }
            else {
                body.offerings = [];
            }

            AdminService.updateExhibitor(body, eventId, exhibitor.id as any).then(() => {
                const name = exhibitor?.name;
                setLoading(false);
                if (!offerings) {
                    reset();
                }
                hideModal();
                setToastProps({
                    isOpen: true,
                    message: `${dictionary["admin-exhibitor_successfully-edited"]} ${name}`,
                    duration: 3000,
                    position: "bottom"
                });
            });
        } catch (e) {
            console.log("the error ", e)
            setLoading(false);
        }

    }

    const onSubmit = (data: any) => {
        try {
            setLoading(true);
            if (editing) {
                updateExhibitor(data)
            } else {
                createExhibitor(data);
            }
        } catch (e) {
            console.log('error: ', e);
            setLoading(false);
        }
    }

    return (
        <React.Fragment>
            <Heading level={2}>
                {exhibitor ? dictionary['admin-exhibitor_add'] : `${dictionary['general_edit']} ${dictionary['admin-exhibitor']}`}
            </Heading>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="ui__group">
                    <Heading level={3}>General</Heading>
                    <OldSelectController name="exhibitorType" label={dictionary['admin-exhibitor_type']} errors={errors}
                        errorField={errors.exhibitorType} control={control}
                        defaultValue={initialValues.exhibitorType} required>
                        <IonSelectOption value="gold">{dictionary['exhibitors_gold-label']}</IonSelectOption>
                        <IonSelectOption value="silver">{dictionary['exhibitors_silver-label']}</IonSelectOption>
                        <IonSelectOption value="bronze">{dictionary['exhibitors_bronze-label']}</IonSelectOption>
                        <IonSelectOption value="partner">{dictionary['exhibitors_partner-label']}</IonSelectOption>
                        <IonSelectOption value="standard">Standard</IonSelectOption>
                    </OldSelectController>
                    <ControlledFilePicker setValue={setValue} accept="image/jpeg, image/png"
                        render={(onPick) => <MixButton
                            onClick={onPick}>{dictionary['general_upload']}</MixButton>}
                        name="bannerImg" label={dictionary['admin-exhibitor_logo']} errors={errors}
                        control={control} defaultValue={initialValues.bannerImg}
                        preview={exhibitor.bannerImg} required />
                    <OldInputController name="companyName" label={dictionary['admin-exhibitor_company']} errors={errors}
                        errorField={errors.companyName} control={control}
                        defaultValue={initialValues.companyName} required />
                    <ControlledFilePicker setValue={setValue} accept="video/*"
                        render={(onPick) => <MixButton
                            onClick={onPick}>{dictionary['general_upload']}</MixButton>}
                        name="heroVideo" label={dictionary['admin-exhibitor_hero-video']}
                        errors={errors} control={control}
                        defaultValue={initialValues.heroVideo} preview={exhibitor.videoLink} />
                    <ControlledFilePicker setValue={setValue} accept="image/jpeg, image/png"
                        render={(onPick) => <MixButton
                            onClick={onPick}>{dictionary['general_upload']}</MixButton>}
                        name="ctaImg" label={dictionary['admin-exhibitor_promo-img']}
                        errors={errors} control={control} defaultValue={initialValues.ctaImg}
                        preview={exhibitor.ctaImg} />
                    <TextareaAutosizeController name="cta" label={dictionary['admin-exhibitor_promo-text']}
                        errors={errors} errorField={errors.cta} control={control}
                        defaultValue={initialValues.cta} />
                    {/* <TextareaController name="shortDescription" label="Short Description" errors={errors} errorField={errors.shortDescription} control={control} defaultValue={initialValues.shortDescription} required /> */}
                    <TextareaAutosizeController name="longDescription" label="Description" errors={errors}
                        errorField={errors.longDescription} control={control}
                        defaultValue={initialValues.longDescription} required />
                </div>

                <div className="ui__group">
                    <Heading level={3}>{dictionary['admin-exhibitor_offerings']}</Heading>

                    {
                        offerings?.map((_, index) => (
                            <React.Fragment key={index}>
                                <OldInputController
                                    name={`offerings[${index}].name`}
                                    label="Offering Title"
                                    errors={errors}
                                    errorField={errors.offerings ? errors.offerings[index]?.name : undefined}
                                    control={control}
                                    defaultValue={initialValues.offerings ? initialValues.offerings[index]?.name : undefined}
                                    required />
                                <TextareaController
                                    name={`offerings[${index}].description`}
                                    label="Offering Description"
                                    errors={errors}
                                    errorField={errors.offerings ? errors.offerings[index]?.description : undefined}
                                    control={control}
                                    defaultValue={initialValues.offerings ? initialValues.offerings[index]?.description : undefined} />
                                <ControlledFilePicker setValue={setValue}
                                    render={(onPick) => <MixButton onClick={onPick}>Upload</MixButton>}
                                    name={`offerings[${index}].file`}
                                    label="Offering File"
                                    errors={errors}
                                    control={control}
                                    preview={exhibitor.offerings[index] ? exhibitor.offerings[index].file : undefined}
                                    defaultValue={initialValues.offerings ? initialValues.offerings[index]?.file : undefined} />
                                <MixButton color="danger" onClick={() => removeOffering(index)}>
                                    Remove offering
                                </MixButton>
                            </React.Fragment>
                        ))
                    }
                    <MixButton color="light"
                        onClick={() => appendOffering({ name: "", description: "", file: "" })}>
                        Add offering
                    </MixButton>
                </div>

                <div className="ui__group">
                    <Heading level={3}>{dictionary['admin-exhibitor_gallery']}</Heading>
                    <ControlledFilePicker setValue={setValue}
                        accept="image/*, video/*"
                        render={(onPick) => <MixButton onClick={onPick}>{dictionary['general_upload']}</MixButton>}
                        name="galleryItems"
                        label={dictionary['admin-exhibitor_gallery']}
                        errors={errors}
                        control={control}
                        defaultValue="galleryItems"
                        multiple
                        preview={exhibitor.sliderImages ? exhibitor.sliderImages[0] : exhibitor.sliderImages} />
                </div>

                <div className="ui__group">
                    <Heading level={3}>{dictionary['admin-exhibitor_meeting']}</Heading>
                    <OldSelectController name="meetingInterval" label={dictionary['admin-exhibitor_meeting-interval']} errors={errors}
                        errorField={errors.exhibitorType} control={control}
                        defaultValue={initialValues.meetingInterval.toString()} required>
                        <IonSelectOption value={15}>15 minutes</IonSelectOption>
                        <IonSelectOption value={30}>30 minutes</IonSelectOption>
                        <IonSelectOption value={45}>45 minutes</IonSelectOption>
                        <IonSelectOption value={60}>60 minutes</IonSelectOption>
                    </OldSelectController>
                </div>

                <div className="ui__group ion-padding-top">
                    <MixButton submit>
                        {editing ? `${dictionary['general_edit']} ${dictionary['admin-exhibitor']}` : dictionary['admin-exhibitor_add']}
                    </MixButton>
                </div>
            </form>
        </React.Fragment>
    );
}

export default ManageExhibitorForm;
