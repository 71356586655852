import { IonLabel } from '@ionic/react';
import { TextareaAutosize } from '@material-ui/core';
import React, { useContext } from 'react';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import { LocalizationContext } from '../../reducers/LocalizationContext';
import { renderErrorMessage } from '../../functions';

interface Props {
    name: string
    control?: Control<any>
    errors: DeepMap<any, FieldError>
    errorField: FieldError | undefined
    required?: boolean
    defaultValue?: string
    label?: string
}

const TextareaAutosizeController: React.FC<Props> = ({ name, control, errors, errorField, required = false, defaultValue = "", label }) => {
    const { dictionary } = useContext(LocalizationContext);

    return (
        <Controller
            defaultValue={defaultValue}
            render={({ onChange, value }) => (
                <>
                    <IonLabel position="stacked" className="ui__label ion-margin-bottom">
                        {label}{required ? " (*)" : null}
                        {errorField ? renderErrorMessage(errors, name) : null}
                    </IonLabel>
                    <TextareaAutosize className="ui__textarea" value={value} onChange={onChange} aria-label={label} rowsMin={3} placeholder={dictionary["general_textarea-placeholder"]} />
                </>
            )}
            control={control}
            name={name}
            rules={{
                required: required
            }}
        />
    );
}

export default TextareaAutosizeController;
