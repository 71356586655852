import { CLIENT_ID, DB_COLLECTIONS } from "../../configs/constants"
import { environment } from "../../configs/environment"
import { Exhibitor } from "../../interfaces/IExhibitor"
import { Meeting } from "../../interfaces/IMeeting"
import { DatabaseService } from "../database/database.service"

const getMeetingForExibitor = (eventId: string, exhibitorId: string, meetingId: string) => {
    return DatabaseService.getDoc<Meeting>(DB_COLLECTIONS.getExhibitorMeetingPath(eventId, exhibitorId, meetingId));
}

// const getMeetigsForExibitor = (eventId: string, userId: string, cb: (data: Meeting[]) => void) => {

//     const unsubs: (() => void)[] = [];

//      DatabaseService.getCol<Exhibitor>(DB_COLLECTIONS.getExhibitorCol(eventId), ref => ref.where("admins", 'array-contains', userId))
//      .then((exhibitors) => {
//         exhibitors.forEach(({ id }) => {

//            const sub = DatabaseService.getColR<Meeting>(DB_COLLECTIONS.getExhibitorMeetingCol(eventId, id as string))
//            .subscribe(cb);

//            unsubs.push(sub)

//         })
//      });


//     return () => {
//         for(let fn of unsubs) {
//             fn();
//         }
//     }
// }
const getMeetingsForExibitor = (eventId: string, exhibitorId: string, cb: (data: Meeting[]) => void) => {
    return DatabaseService.getColR<Meeting>(DB_COLLECTIONS.getExhibitorMeetingCol(eventId, exhibitorId)).subscribe(cb);
}

const getMeetingsForUser = (userId: string, cb: (data: Meeting[]) => void) => {
    return DatabaseService.getColGroupR<Meeting>(
        DB_COLLECTIONS.getAppointmentsCol(),
        ref => ref.orderBy("time").where("userId", '==', userId)
    ).subscribe(cb);
}

const scheduleMeeetingWithExibitor = (eventId: string, exhibitorId: string, userId: string, time: string, questionResponses: any) => {
    const request = {
        method: "POST",
        body: JSON.stringify({
            eventId,
            exhibitorId,
            userId,
            time,
            questionResponses,
            clientId: CLIENT_ID
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    };
    console.log('request: ', request);
    return fetch(environment.apiUrl + "/scheduleNewAppointment", request).then((res) => {
        console.log('res: ', res);
        return res.json();
    }).catch((err) => {
        console.log(err);
        return Promise.reject();
    })
}

export const MeetingsService = {
    getMeetingForExibitor,
    getMeetingsForUser,
    getMeetingsForExibitor,
    scheduleMeeetingWithExibitor
}
