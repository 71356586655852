import React from 'react';
import { Exhibitor } from '../../interfaces/IExhibitor';
import TranslatedString from '../general/TranslatedString';

interface Props {
    exhibitor: Exhibitor

    fullWidth?: boolean
}

const PartnerCard: React.FC<Props> = ({ exhibitor, fullWidth = false }) => {
    return (
        <div className="partner-card" style={fullWidth ? { width: "100%", maxWidth: "100%", padding: 0 } : {}}>
            <div className="label">
                <TranslatedString tid="exhibitors_partner-label" />
            </div>
            <div style={exhibitor?.color ? { borderColor: exhibitor.color } : {}}
                className="content">
                <img src={exhibitor.bannerImg?.url} alt="" />
                {exhibitor?.cta && (<div style={exhibitor?.color ? { color: exhibitor.color } : {}}
                    className="cta">
                    {exhibitor.cta}
                </div>)}
            </div>
        </div>
    );
}

export default PartnerCard;
