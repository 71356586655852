import React, { useContext, useEffect, useState } from 'react';
import AdminNavigation from '../../components/admin/AdminNavigation';
import ExhibitorsTable from '../../components/admin/exhibitors/ExhibitorsTable';
import LayoutPage from '../../components/templates/LayoutPage';
import UIContent from '../../components/UI/UIContent';
import { Exhibitor } from '../../interfaces/IExhibitor';
import { AppContext } from '../../reducers/AppContext';
import { ExhibitorService } from '../../services/exhibitor/exhibitor.service';

const ExhibitorsManagementPage: React.FC = () => {
    const { user, eventId } = useContext(AppContext);
    const [exhibitors, setExhibitors] = useState<Exhibitor[]>();

    useEffect(() => {
        if (user?.admin && eventId) {
            ExhibitorService.getExhibitors(eventId).then(setExhibitors)
        }
    }, [eventId, user]);

    return (
        <LayoutPage columns={3}>
            <UIContent>
                <AdminNavigation />
                <ExhibitorsTable exhibitors={exhibitors} />
            </UIContent>
        </LayoutPage>
    );
}

export default ExhibitorsManagementPage;
