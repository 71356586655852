import { IonContent, IonPage } from '@ionic/react';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../reducers/AppContext';
import EventBanner from '../general/EventBanner';
import MixFooter from '../general/MixFooter';
import MixHeader from '../general/MixHeader';
import GeneralChat from '../generalChat/generalChat';

interface Props {
    contentClass?: string
    pageClass?: string
}

const MixPage: React.FC<Props> = ({ pageClass, contentClass, children }) => {
    const { user } = useContext(AppContext);
    return (
        <IonPage className={pageClass}>
            <IonContent
                fullscreen
                className={contentClass}
            //   scrollEvents
            //   onIonScroll={(e) => setScrollTop(e.detail.scrollTop)}
            >
                {
                    user && <EventBanner />
                }
                <MixHeader />
                <div className="mix-page-content">
                    {children}
                    <GeneralChat />
                </div>
                <MixFooter />
            </IonContent>
        </IonPage>
    );
}

export default MixPage;
