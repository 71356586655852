import React, { useState, useContext, useEffect } from 'react';
import { IonSelect, IonSelectOption } from '@ionic/react';
import { ScheduleItem } from '../../interfaces/ISchedule';
import { useParams } from "react-router";
import { AppContext } from "../../reducers/AppContext";
import { ScheduleService } from '../../services/schedule/schedule.service';
import LayoutPage from '../../components/templates/LayoutPage';
import { LocalizationContext } from '../../reducers/LocalizationContext';
import ScheduleList from '../../components/schedule/ScheduleList';
import Heading from '../../components/general/Heading';
import ExhibitorsList from '../../components/exhibitors/ExhibitorsList';
import { ExhibitorService } from '../../services/exhibitor/exhibitor.service';
import { Exhibitor } from '../../interfaces/IExhibitor';
import firebase from 'firebase';
import dayjs from 'dayjs';
import UIContent from '../../components/UI/UIContent';

const EventSchedulePage: React.FC = () => {
    const { user, eventProps, loading, setLoading } = useContext(AppContext);
    const { dictionary } = useContext(LocalizationContext);
    const { eventId } = useParams<{ [id: string]: string }>();

    const [activeDate, setActiveDate] = useState("");
    const [scheduleItems, setScheduleItems] = useState<ScheduleItem[]>([]);

    const [exhibitorList, setExhibitorList] = useState<Exhibitor[]>(null as any);
    const [goldExhibitors, setGoldExhibitors] = useState<Exhibitor[]>();
    const [silverExhibitors, setSilverExhibitors] = useState<Exhibitor[]>();
    const [bronzeExhibitors, setBronzeExhibitors] = useState<Exhibitor[]>();
    const [partners, setPartners] = useState<Exhibitor[]>();
    const [otherExhibitors, setOtherExhibitors] = useState<Exhibitor[]>();

    const metaComponent = (
        <UIContent>
            {
                (eventProps?.eventDates && eventProps?.eventDates?.length > 1) ?
                    (
                        <IonSelect
                            value={activeDate}
                            onIonChange={(e) => setActiveDate(e.detail.value!)}
                            interface="popover"
                            className="ui__input">
                            {
                                eventProps?.eventDates?.map((date, index) => (
                                    <IonSelectOption key={index}>
                                        {date}
                                    </IonSelectOption>
                                ))
                            }
                        </IonSelect>
                    ) : (
                        <Heading level={3} eventColor={false}>{dayjs(activeDate, 'MM/DD/YYYY').format('DD/MM/YYYY')}</Heading>
                    )
            }
        </UIContent>
    );

    const actionsComponent = (
        <UIContent>
            <ExhibitorsList
                narrow
                goldExhibitors={goldExhibitors}
                silverExhibitors={silverExhibitors}
                bronzeExhibitors={bronzeExhibitors}
                partners={partners}
                otherExhibitors={otherExhibitors} />
        </UIContent>
    );

    useEffect(() => {
        if (eventId) {
            ExhibitorService.getExhibitors(eventId).then(setExhibitorList)
        }
    }, [eventId]);

    useEffect(() => {
        if (exhibitorList) {
            setGoldExhibitors(exhibitorList?.filter(exhibitor => exhibitor.exhibitorType === 'gold').sort((a, b) => a.name < b.name ? -1 : 1));
            setSilverExhibitors(exhibitorList?.filter(exhibitor => exhibitor.exhibitorType === 'silver').sort((a, b) => a.name < b.name ? -1 : 1));
            setBronzeExhibitors(exhibitorList?.filter(exhibitor => exhibitor.exhibitorType === 'bronze').sort((a, b) => a.name < b.name ? -1 : 1));
            setPartners(exhibitorList?.filter(exhibitor => exhibitor.exhibitorType === 'partner').sort((a, b) => a.name < b.name ? -1 : 1));
            setOtherExhibitors(exhibitorList?.filter(exhibitor => exhibitor.exhibitorType !== 'partner' && exhibitor.exhibitorType !== 'bronze' && exhibitor.exhibitorType !== 'silver' && exhibitor.exhibitorType !== 'gold').sort((a, b) => a.name < b.name ? -1 : 1));
        }
    }, [exhibitorList]);

    useEffect(() => {
        if (user && eventProps) {
            return ScheduleService.getSchedulesItemsWithUsers(eventId, setScheduleItems)
        }
        firebase.analytics().logEvent(`${eventId}_SCHEDULE_VISIT`);
    }, [user, eventProps, eventId]);

    useEffect(() => {
        if (eventProps && loading) {
            setLoading(false);
        }
    }, [eventProps, loading]);

    useEffect(() => {
        if (eventProps) {
            setActiveDate(eventProps.eventDates[0])
        }
    }, [eventProps]);

    return (
        <LayoutPage
            columns={2}
            contentClass="schedule__wrapper"
            containerClass="schedule__container"
            gridClass="schedule__grid"

            header={dictionary["event-schedule_conference-schedule"]}
            metaComponent={metaComponent}

            actionsComponent={actionsComponent}
            noMeta
        >
            {
                eventProps ? (
                    <ScheduleList
                        activeDate={activeDate}
                        items={scheduleItems}
                        eventId={eventId} />
                ) : null
            }
        </LayoutPage>
    );
};

export default EventSchedulePage;
