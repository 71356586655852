export function getDataUrl(file: File) {
    return new Promise<string>((res, rej) => {

        if (file) {

            var reader = new FileReader();

            reader.onload = (e) => {
                res(e.target?.result as string);
            }

            reader.onerror = (e) => {
                rej(e);
            }

            reader.readAsDataURL(file);

        }
    })

}