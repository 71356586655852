import React, { useContext } from 'react';
import AdminNavigation from '../../components/admin/AdminNavigation';
import ManageEventForm from '../../components/admin/event/ManageEventForm';
import Heading from '../../components/general/Heading';
import LayoutPage from '../../components/templates/LayoutPage';
import UIContent from '../../components/UI/UIContent';
import { AppContext } from '../../reducers/AppContext';
import { LocalizationContext } from "../../reducers/LocalizationContext";

const EventSettingsPage: React.FC = () => {
    const { eventProps } = useContext(AppContext);
    const { dictionary } = useContext(LocalizationContext);

    return (
        <LayoutPage columns={3}>
            <UIContent>
                <AdminNavigation />
                <Heading>{dictionary['general_admin-page']}: {dictionary['admin_event']}</Heading>
                {eventProps !== null && <ManageEventForm eventProps={eventProps} />}
            </UIContent>
        </LayoutPage>
    );
}

export default EventSettingsPage;
