import { IonIcon } from '@ionic/react';
import React from 'react';
import MixButton from './MixButton';
import TranslatedString from './TranslatedString';
import BackIcon from '../../assets/icons/ic-arrow-back.svg';
import { useHistory } from 'react-router';

interface Props {
    onClick?: (...args: any) => void
}

const BackButton: React.FC<Props> = ({ onClick }) => {
    const history = useHistory();

    return (
        <MixButton className="ui__button back-btn"
            color="transparent"
            onClick={onClick ? onClick : history.goBack}>
            <IonIcon src={BackIcon} />
            <TranslatedString tid="general_back-btn" />
        </MixButton>
    );
}

export default BackButton;
