import {IonButton, IonIcon} from '@ionic/react';
import React, {useContext, useMemo, useState} from 'react';

import MixablLogo from '../../assets/logos/logo-mixabl.svg';
import ProfileIcon from '../../assets/icons/ic-profile.svg';
import {AppContext} from '../../reducers/AppContext';
import {useHistory} from 'react-router';
import {UserService} from '../../services/user/user.service';
import TranslatedString from './TranslatedString';
import {LocalizationContext} from "../../reducers/LocalizationContext";

const MixHeader: React.FC = () => {
    const history = useHistory();
    const {eventId, user} = useContext(AppContext);
    const {dictionary} = useContext(LocalizationContext);

    const [menu, showMenu] = useState(false);

    const menuItems = useMemo(() => {
        return [
            {
                string: dictionary['general_events'],
                path: "/find-events"
            }
        ]
    }, []);

    const logout = () => {
        history.replace("/find-events");
        UserService.logOut()
    }

    return (
        <header className="fc__header">
            <div className="ui__container">
                <div className="ui__col logo-col"
                     onClick={() => user ? history.push(`/my/${eventId}/home`) : history.push(`/find-events`)}>
                    <IonIcon className="logo" src={MixablLogo}/>
                </div>
                <div className="ui__col navigation">
                    <div className="menu">
                        {
                            !user &&
                            menuItems.map((item, index) => (
                                <div className="item" key={index} onClick={() => history.push(item.path)}>
                                    {item.string}
                                </div>
                            ))
                        }

                        {
                            user &&
                            <div className={`profile unsellectable ${menu ? 'clicked' : ''}`}>
                                <IonIcon className="btn" src={ProfileIcon} onClick={() => showMenu(!menu)}/>
                                {
                                    menu &&
                                    <div className="menu">
                                        <div className="user-info">
                                            <div className="name">
                                                {user.firstName} {user.lastName}
                                            </div>
                                            <div className="email">
                                                {user.email}
                                            </div>
                                        </div>
                                        <IonButton
                                            size="small"
                                            className="log-out"
                                            color="medium"
                                            onClick={logout}>
                                            <TranslatedString tid="general_logout"/>
                                        </IonButton>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </header>
    );
}

export default MixHeader;