import React, { useState, useContext, useEffect } from 'react';
import { IonLoading, IonAlert } from '@ionic/react';

import { UserService } from '../../services/user/user.service';
import { AppContext } from '../../reducers/AppContext';

import LoginCover from '../../components/login/LoginCover';
import { LocalizationContext } from '../../reducers/LocalizationContext';
import MixPage from '../../components/templates/MixPage';
import { useHistory, useParams } from 'react-router';
import EnterAdminPrompt from '../../components/login/EnterAdminPrompt';

const AdminLoginPage: React.FC = () => {
  const history = useHistory();
  const { eventId: paramEventId } = useParams<{ [id: string]: string }>();
  const { eventId, setEventId, eventProps, accessError, setAccessError, setIsAdmin } = useContext(AppContext);
  const { dictionary } = useContext(LocalizationContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (paramEventId) {
      setEventId(paramEventId)
    }
  }, [paramEventId]);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (email !== '' && password !== '' && eventId !== '') {
      setIsLoading(true);
      setIsAdmin(true);
      UserService.loginAdmin(email, password, eventId).then((_eventCode) => {
        setLoginError(0);
        setEventId(_eventCode);
        setIsLoading(false);
        history.replace(`/my/${eventId}/home`);
      })
        .catch(() => {
          setLoginError(1);
          setIsAdmin(false);
          setIsLoading(false);
        });
    }
  }

  return (
    <MixPage pageClass="login__page" contentClass="login__wrapper">
      <IonLoading isOpen={isLoading} />
      <div className="login-container m-auto">
        <form onSubmit={onSubmit}>
          <div className="ui__row page">
            <LoginCover eventProps={eventProps} />
            <div className="ui__col login__content">
              {eventProps && eventProps.authMethod === "phoneAuth" && <div id="recaptcha-container" />}
              <EnterAdminPrompt
                eventProps={eventProps}
                email={email}
                setEmail={setEmail}
                loginError={loginError}
                password={password}
                setPassword={setPassword}
              />
            </div>
          </div>
        </form>
      </div >
      <IonAlert
        isOpen={accessError}
        onDidDismiss={() => setAccessError(false)}
        header={dictionary["login_event-not-available"]}
        buttons={[
          {
            text: dictionary["general_ok"],
            role: 'cancel',
          }
        ]}
      />
    </MixPage >
  );
};

export default AdminLoginPage;
