import { storage } from "../../configs/firebase"
import { StorageFile } from "../../interfaces/IFile";

const ref = (path: string) => {
    return storage.ref(path);
}

const getDownloadUrl = (path: string): Promise<string> => {
    return ref(path).getDownloadURL();
}

const uploadBase64 = async (
    path: string,
    data: string,
    contentType: string
) => {
    await ref(path).putString(data, 'base64', { contentType });
    return getDownloadUrl(path);
}
/*
    Please do not update the api of this function unnecessarily
    the file prop should always be requred. if it is not provided
    it should fail - Toch
*/
const uploadFile = async (
    path: string,
    file: File,
    type?: string
): Promise<StorageFile> => {
    let uploadPath = path + "/" + file.name.replace(/\./, `_${Date.now()}.`);
    await ref(uploadPath).put(file);
    const downloadUrl = await getDownloadUrl(uploadPath);

    return {
        url: downloadUrl,
        path: uploadPath,
        type: type ? type : file.type
    }

}

export const StorageService = {
    uploadBase64,
    getDownloadUrl,
    uploadFile
}
