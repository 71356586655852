import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ENVIRONMENT } from './configs';

const oldlog = window.console.log.bind(window.console);
const oldWarn = window.console.warn.bind(window.console);

window.console.log = ENVIRONMENT === 'production' ? () => null : oldlog
window.console.warn = ENVIRONMENT === 'production' ? () => null : oldWarn

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


console.log("the env ", ENVIRONMENT)

window.onunload = async (e: Event) => {
    /*
    if (ENVIRONMENT === 'production')
        e.returnValue = false;
    await UserService.activateSession('available');*/
}