import { DatabaseService } from "../database/database.service"
import { DB_COLLECTIONS } from "../../configs/constants"
import { ScheduleItem, StreamInfo } from "../../interfaces/ISchedule"
import { UserService } from "../user/user.service"
import { User } from "../../interfaces/IUser"

const getScheduleItem = (eventId: string, scheduleItemId: string) => {
    return DatabaseService.getDocR<ScheduleItem>(DB_COLLECTIONS.getScheduleItemPath(eventId, scheduleItemId))
}

const getScheduleItems = (eventId: string, cb: (items: ScheduleItem[]) => void) => {
    return DatabaseService.getColR<ScheduleItem>(DB_COLLECTIONS.getScheduleItemCol(eventId)).subscribe(cb);
}

const getSchedulesItemsWithUsers = (eventId: string, cb: (items: ScheduleItem[]) => void) => {
    return getScheduleItems(eventId, async (items) => {
        const data = await Promise.all(items.map(async (item) => {
            const presenters = await Promise.all(item.presenters?.map((presenter) => {
                if (typeof presenter === "string") {
                    return UserService.getUser(presenter as string, eventId) as Promise<User>
                }
                return presenter;
            }))
            return { ...item, presenters }
        }))

        cb(data);
    })
}

const getPresenterDisplayName = (presenter: User | string) => {
    if (typeof presenter === 'string') {
        return presenter;
    }

    if (!presenter?.lastName) {
        return;
    }

    const {
        lastName,
        firstName,
        title,
        company
    } = presenter;

    return `${lastName && lastName} ${firstName && firstName} - ${title.substr(0, 25) && title.substr(0, 25)} ${title.length > 25 ? '[...]' : ''}, ${company && company}`
}

const getStreamKey = (eventId: string, scheduleItemId: string) => {
    return DatabaseService.getDoc<StreamInfo>(DB_COLLECTIONS.getScheduleItemStreamInfoPath(eventId, scheduleItemId))
}

export const ScheduleService = {
    getScheduleItem,
    getScheduleItems,
    getSchedulesItemsWithUsers,
    getPresenterDisplayName,
    getStreamKey
}
