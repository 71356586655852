import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/firestore';
import {ENVIRONMENT} from '.';

const firebaseConfigProd = {
    apiKey: "AIzaSyB9AZ4JhCd8nqxYcw8EKFNW2MADhR62heY",
    authDomain: "mixabl.firebaseapp.com",
    databaseURL: "https://mixabl.firebaseio.com",
    projectId: "mixabl",
    storageBucket: "mixabl.appspot.com",
    messagingSenderId: "639711426862",
    appId: "1:639711426862:web:56102cce3ac1780052e2bf",
    measurementId: "G-5JVDSYKL79"
};

const firebaseConfigSandbox = {
    apiKey: "AIzaSyBXjob5lN4Gwdue44JfJYi-X8hCncCI58s",
    authDomain: "mixabl-sanbox-east1.firebaseapp.com",
    databaseURL: "https://mixabl-sanbox-east1.firebaseio.com",
    projectId: "mixabl-sanbox-east1",
    storageBucket: "mixabl-sanbox-east1.appspot.com",
    messagingSenderId: "887531539195",
    appId: "1:887531539195:web:ef28c318b9e400988fbe5e",
    measurementId: "G-EVH7RC16VE"
};

const firebaseConfigMarketing = {
    apiKey: "AIzaSyAaxStHh4bXFHjrb9dqWzrJGqw3lOF-1bA",
    authDomain: "mixabl-marketing.firebaseapp.com",
    databaseURL: "https://mixabl-marketing.firebaseio.com",
    projectId: "mixabl-marketing",
    storageBucket: "mixabl-marketing.appspot.com",
    messagingSenderId: "984308192266",
    appId: "1:984308192266:web:c9db7aba0e0925c27cfbf3",
    measurementId: "G-41PSGK059H"
};

function getFirebaseConfig() {
    switch (ENVIRONMENT) {
        case "production":
            return firebaseConfigProd;
        case "marketing":
            return firebaseConfigMarketing;
        default:
            return firebaseConfigSandbox;
    }
}


firebase.initializeApp(getFirebaseConfig());
firebase.analytics();

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
export default firebase;
