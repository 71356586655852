import React, { createContext, useState, useEffect, useContext, useRef } from 'react';
import { useHistory } from 'react-router';
import { EventProps } from '../interfaces/IEvent';
import { User } from '../interfaces/IUser';
import { Meeting, ShowNotification } from '../interfaces/IMeeting';
import { EventService } from '../services/event/event.service';
import { UserService } from '../services/user/user.service';
import { MeetingsService } from '../services/meetings/meetings.service';
import dayjs from 'dayjs';
import { IonLoading } from '@ionic/react';
import { PopupContext } from './PopupContext';
import { LocalizationContext } from './LocalizationContext';
import { DatabaseService } from '../services/database/database.service';
import { AuthContext } from './AuthContext';
import { SendChatRequestBody } from '../interfaces/IChat';
const { version } = require("../../package.json");

const Sound = require('react-sound').default;
const newMeetingSound = require('../assets/sounds/newMeeting.mp3');


const defaultUser: User = null as any;
const defaultEventProps: EventProps = null as any
const defaultSetEventProps: any = () => { }

const defaultEventId: string = '';
const defaultSetEventId: any = () => { }
const appVersion = version;
const defaultMeeting: Meeting = null as any;
const defaultExhibitorMeetings: Meeting[] = [];
const defaultUserMeetings: Meeting[] = [];

const defaultChatCollapsed = false;
const defaultSetChatCollapsed: any = () => { };

const defaultLoading: boolean = false;
const defaultSetLoading: any = () => { };

const defaultAccessError: boolean = false
const defaultSetAccessError: any = () => { }

const defaultAnalyticsPrompt: boolean = false
const defaultSetAnalyticsPrompt: any = () => { }
const defaultSetDoubleAuthWarning: any = () => { }
const defaultDoubleAuthWarning: boolean = false;
const defaultPageChat: {
    data: SendChatRequestBody,
    name: string,
    chatEnabled: boolean
} = null as any;
const defaultSetPageChat: any = () => { };

const defaultSetIsAdmin: any = () => { };

let firstPullExhibitorMeetings = true;
let firstPullUserMeetings = true;

export const AppContext = createContext({
    user: defaultUser,

    eventProps: defaultEventProps,
    setEventProps: defaultSetEventProps,
    eventId: defaultEventId,
    setEventId: defaultSetEventId,

    accessError: defaultAccessError,
    setAccessError: defaultSetAccessError,

    loading: defaultLoading,
    setLoading: defaultSetLoading,

    appVersion,

    nextAppointment: defaultMeeting,
    showNotif: ShowNotification.NOSHOW,
    modifyShowNotif: (newNotif: ShowNotification) => { },
    exhibitorMeetings: defaultExhibitorMeetings,
    userMeetings: defaultUserMeetings,
    // updateAppointments: () => { },

    chatCollapsed: defaultChatCollapsed,
    setChatCollapsed: defaultSetChatCollapsed,

    analyticsPrompt: defaultAnalyticsPrompt,
    setAnalyticsPrompt: defaultSetAnalyticsPrompt,

    doubleAuthWarning: defaultDoubleAuthWarning,
    setDoubleAuthWarning: defaultSetDoubleAuthWarning,
    setIsAdmin: defaultSetIsAdmin,

    pageChat: defaultPageChat,
    setPageChat: defaultSetPageChat
});

export const AppContextProvider: React.FC = ({ children }) => {
    const { setAlertProps, showAlert } = useContext(PopupContext);
    const { dictionary } = useContext(LocalizationContext);

    const authUser = useContext(AuthContext);

    const [user, setUser] = useState<User>(defaultUser);
    const [eventId, setEventId] = useState(defaultEventId);
    const [accessError, setAccessError] = useState(defaultAccessError);
    const [loading, setLoading] = useState(defaultLoading);
    const [exhibitorMeetings, setExhibitorMeetings] = useState<Meeting[]>(defaultExhibitorMeetings);
    const [userMeetings, setUserMeetings] = useState<Meeting[]>(defaultUserMeetings);
    const [nextAppointment, setNextAppointment] = useState<Meeting>(defaultMeeting);
    const [showNotif, setShowNotif] = useState<ShowNotification>(ShowNotification.NOSHOW);
    const [chatCollapsed, setChatCollapsed] = useState<boolean>(false);
    const [analyticsPrompt, setAnalyticsPrompt] = useState(defaultAnalyticsPrompt);
    const [doubleAuthWarning, setDoubleAuthWarning] = useState<boolean>(defaultDoubleAuthWarning);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [eventProps, setEventProps] = useState<EventProps>(defaultEventProps);
    const [pageChat, setPageChat] = useState<{
        data: SendChatRequestBody,
        name: string,
        chatEnabled: boolean
    }>(defaultPageChat);
    const [audioTracks, setAudioTracks] = useState<any[]>([]);
    const [soundStatus, setSoundStatus] = useState<any>(Sound.status.STOPPED);
    const [soundType, setSoundType] = useState<any>(newMeetingSound);
    const audioRef = useRef<any>();

    let history = useHistory();

    const showAccessAlert = () => {
        setAlertProps({
            header: dictionary["access-restricted_header"],
            subHeader: dictionary["access-restricted_subHeader"],
            buttons: [
                {
                    text: dictionary["general_ok"],
                    role: 'cancel',
                    cssClass: 'danger',
                    handler: () => setAccessError(false)
                }
            ]
        });
        showAlert();
    }

    useEffect(() => {
        const audioTrack = audioTracks[0];
        if (audioTrack) {
            audioTrack.attach(audioRef.current);
            return () => {
                audioTrack.detach();
            };
        }
    }, [audioTracks]);

    // useEffect(() => {
    //     return auth.onAuthStateChanged((authUser) => {
    //         console.log('user in onAuthStateChanged: ', authUser);

    //         if (authUser) {
    //             setAuthUser(authUser)
    //         }
    //         else {
    //             setAuthUser(null as any);
    //             setUser(null as any);
    //             setIsAdmin(false);
    //         }
    //     })
    // }, []);

    useEffect(() => {
        if (authUser) {
            EventService.getEventId().then((eventId) => {
                if (eventId) {
                    setEventId(eventId);
                } else { }
            });
        } else {
            setEventId("");
        }
    }, [authUser]);

    useEffect(() => {
        const loggedInNav = () => {
            const { pathname } = history.location
            const path = `/my/${eventId}/home`;
            if (pathname !== path && (pathname.indexOf(eventId) < 0 || pathname.indexOf("login") >= 0)) {
                // console.log("navigating to ", path)
                history.replace(`/my/${eventId}/home`);
            }
        }
        if (authUser && eventId) {
            if (isAdmin) {
                UserService.getAdmin(authUser.uid, eventId).then((user) => {
                    if (user) {
                        setUser(user as User);
                        loggedInNav()

                    }
                    else {
                        UserService.logOut().then(() => {
                            history.push(`/admin-login/${eventId}`);
                            setAccessError(true);
                        })
                    }
                })
            }
            else {
                UserService.getUser(authUser.uid, eventId).then((user) => {
                    if (user) {
                        setUser(user as User);

                        loggedInNav()
                    }
                    else {
                        UserService.logOut().then(() => {
                            history.push(`/login/${eventId}`);
                            setAccessError(true);
                        })
                    }
                });
            }
        } else {
            setUser(null as any);
        }
    }, [eventId, authUser]);

    useEffect(() => {
        if (user && eventId && eventProps) {
            if (user.admin) {
                return;
            } else {
                eventProps?.closedTo?.forEach(userType => {
                    if (userType === "presenter" && user?.presenter?.length && user.presenter !== null) {
                        UserService.logOut().then(() => {
                            history.replace(`/login/${eventId}`);
                            setAccessError(true);
                            showAccessAlert();
                        });
                    }
                    if (userType === "standard" && !user?.presenter?.length && !user?.exhibitor?.length) {
                        UserService.logOut().then(() => {
                            history.replace(`/login/${eventId}`);
                            setAccessError(true);
                            showAccessAlert();
                        });
                    }
                })
            }
        }
    }, [user, eventId, eventProps]);

    useEffect(() => {
        if (user && user.id && eventId) {
            return MeetingsService.getMeetingsForUser(user.id, (meetings) => {
                setUserMeetings((oldMeetings) => {
                    if (!firstPullUserMeetings) {
                        if (oldMeetings.length < meetings.length) {
                            setShowNotif(ShowNotification.NEWMEETING);
                            setSoundType(newMeetingSound);
                            setSoundStatus(Sound.status.PLAYING);
                        }
                    } else {
                        firstPullUserMeetings = false;
                    }
                    return meetings;
                });
            })
        }
    }, [user, eventId])

    useEffect(() => {
        if (user && user.id && user.exhibitor) {
            return MeetingsService.getMeetingsForExibitor(eventId, user.exhibitor, (meetings) => {
                setExhibitorMeetings((oldMeetings) => {
                    if (!firstPullExhibitorMeetings) {
                        if (oldMeetings.length < meetings.length) {
                            setShowNotif(ShowNotification.NEWMEETING);
                            setSoundType(newMeetingSound);
                            setSoundStatus(Sound.status.PLAYING);
                        }
                    } else {
                        firstPullExhibitorMeetings = false;
                    }
                    return meetings;
                });
            })
        }
    }, [user, eventId])

    useEffect(() => {
        if (eventId) {
            EventService.getEventProps(eventId).then((res) => {
                setEventProps(res as EventProps);
            });
        } else {
            setEventProps(defaultEventProps);
        }
    }, [eventId]);

    useEffect(() => {
        if (userMeetings.length + exhibitorMeetings.length > 0) {
            const interval = setInterval(() => {
                const now: dayjs.Dayjs = dayjs();
                const nextMeeting = findNextMeeting([...userMeetings, ...exhibitorMeetings]);
                if (nextMeeting) {
                    setNextAppointment(nextMeeting);
                    const meetingTime: dayjs.Dayjs = dayjs(nextMeeting.time, "DDMMYYYY-HHmm");
                    if (meetingTime.isBefore(now)) {
                        if (now.diff(meetingTime, 'minute') >= 5) {
                            setShowNotif(ShowNotification.NOSHOW);
                        }
                    } else {
                        if (meetingTime.diff(now, 'minute') <= 14) {
                            if (meetingTime.diff(now, 'minute') <= 1) {
                                setShowNotif((notif) => notif !== ShowNotification.SEENLASTMINUTE ? ShowNotification.SHOWLASTMINUTE : notif);
                            } else {
                                setShowNotif((notif) => notif !== ShowNotification.SEENNOTIF ? ShowNotification.SHOW : notif);
                            }
                        }
                        // else {
                        //     setShowNotif(ShowNotification.NOSHOW);
                        // }
                    }
                }
                else {
                    // setShowNotif(ShowNotification.NOSHOW);
                    setNextAppointment(defaultMeeting);
                    clearInterval(interval);
                }
            }, 1000);

            return () => {
                clearInterval(interval);
            }
        }
    }, [userMeetings, exhibitorMeetings])

    // const updateAppointments = () => {

    //     const subs: (() => void)[] = [];

    //     if (user && user.id && eventId) {
    //         let next: Meeting = defaultMeeting;


    //         let s1 = MeetingsService.getMeetingsForUser(user.id, (meetings) => {

    //             setUserMeetings(meetings);
    //             const nextMeeting: Meeting = findNextMeeting(meetings);
    //             if (nextMeeting) {
    //                 next = nextMeeting;
    //             } else {
    //                 next = defaultMeeting;
    //                 setShowNotif(ShowNotification.NOSHOW);
    //             }

    //         })

    //         subs.push(s1);

    //         let s2 = MeetingsService.getMeetigsForExibitor(eventId, user.id, (meetings) => {

    //             setExhibitorMeetings(meetings);
    //             const nextMeeting: Meeting = findNextMeeting(meetings);
    //             if (next) {
    //                 if (nextMeeting && dayjs(next.time, 'DDMMYYYY-HHmm').isBefore(dayjs(nextMeeting.time, 'DDMMYYYY-HHmm'))) {
    //                     setNextAppointment(nextMeeting);
    //                 } else {
    //                     setNextAppointment(next);
    //                 }
    //             } else {
    //                 setNextAppointment(nextMeeting);
    //             }

    //         })

    //         subs.push(s2);
    //     }

    //     return () => {
    //         for (let fn of subs) {
    //             fn();
    //         }
    //     }
    // }

    const findNextMeeting = (meetings: Meeting[]): Meeting => {
        const now: dayjs.Dayjs = dayjs().subtract(5, 'minute');
        let nextMeeting: Meeting = defaultMeeting;
        meetings.forEach((meeting) => {
            const time: dayjs.Dayjs = dayjs(meeting.time, "DDMMYYYY-HHmm");
            if (now.isBefore(time) && !nextMeeting) {
                nextMeeting = meeting;
            } else {
                if (time.isAfter(now) && time.isBefore(dayjs(nextMeeting.time, 'DDMMYYYY-HHmm'))) {
                    nextMeeting = meeting;
                }
            }
        })
        return nextMeeting;
    }

    const modifyShowNotif = (newNotif: ShowNotification) => {
        setShowNotif(newNotif);
    }

    useEffect(() => {
        if (user && eventId) {
            if (!user?.enrolledInAnalytics) {
                setAnalyticsPrompt(true);
            } else if (user?.enrolledInAnalytics === 1) { }
            else if (user?.enrolledInAnalytics === 2) { }
        }
    }, [user, eventId]);

    useEffect(() => {
        if (user && eventId) {
            return DatabaseService.getDocR<User>(`clients/${eventId}/Users/${user.id}`)
                .subscribe((userData) => {
                    // const userData = result.data() as User;
                    UserService.validSession(authUser, userData).then((sessionStatus) => {
                        if (sessionStatus === 'invalid') {
                            setDoubleAuthWarning(true);
                            history.replace(`/my/${eventId}/home`);
                        } else {
                            setDoubleAuthWarning(false);
                        }
                    })
                })

        }
    }, [user, eventId])

    return <AppContext.Provider value={{
        appVersion,
        user,
        eventId,
        setEventId,
        eventProps,
        setEventProps,
        userMeetings,
        exhibitorMeetings,
        nextAppointment,
        showNotif,
        chatCollapsed,
        setChatCollapsed,
        accessError,
        setAccessError,
        loading,
        setLoading,
        analyticsPrompt,
        setAnalyticsPrompt,
        modifyShowNotif,
        doubleAuthWarning,
        setDoubleAuthWarning,
        setIsAdmin,
        pageChat,
        setPageChat
    }}>
        <IonLoading isOpen={loading} />
        {children}
        <Sound
            url={soundType}
            playStatus={soundStatus}
            onFinishedPlaying={() => {
                setSoundStatus(Sound.status.STOPPED)
            }}
        />
    </AppContext.Provider>
}
