import React from 'react';
import {Player} from 'video-react';
import HLSSource from './HlSSource';


export const VideoPlayer = (settings: any) => {
    return (
        <>
            {
                settings.sources ?
                    <Player
                        autoPlay={true}
                        isActive={(state: any) => {
                            console.log(state)
                        }}
                    >
                        <HLSSource
                            isVideoChild
                            src={settings.sources[0].src}
                        />
                    </Player>
                    :
                    null
            }
        </>
    );
};
